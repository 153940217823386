import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export class LogLevel {
  None = 0;
  Info = 1;
  Verbose = 2;
  Warn = 3;
  Error = 4;

  constructor() {}
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  logLevel: LogLevel = new LogLevel();
  constructor() {}
  info(category: any, msg?: any): void {
    if (environment.production) {
      return;
    } else {
      this.logWith(this.logLevel.Info, category, msg);
    }
  }

  log(category: any, msg?: any): void {
    if (environment.production) {
      return;
    } else {
      this.logWith(this.logLevel.Info, category, msg);
    }
  }

  warn(category: any, msg?: any): void {
    this.logWith(this.logLevel.Warn, category, msg);
  }

  error(category: any, msg?: any): void {
    this.logWith(this.logLevel.Error, category, msg);
  }

  private logWith(level: any, category: any, msg?: any): void {
    if (level <= this.logLevel.Error) {
      if (category && msg) {
        switch (level) {
          case this.logLevel.None:
            return console.log(category, msg);
          case this.logLevel.Info:
            return console.info('%c' + category, 'padding: 1px 2px; color: #FFFFFF; background: #00998C', msg);
          case this.logLevel.Warn:
            return console.warn('%c' + category, 'padding: 1px 2px; color: #FFFFFF; background: #FF8C00', msg);
          case this.logLevel.Error:
            return console.error('%c' + category, 'padding: 1px 2px; color: #FFFFFF; background: #DC143C', msg);
          default:
            console.debug(msg);
        }
      } else {
        switch (level) {
          case this.logLevel.None:
            return console.log(category);
          case this.logLevel.Info:
            return console.info('%c' + 'Info', 'padding: 1px 2px; color: #FFFFFF; background: #00998C', category);
          case this.logLevel.Warn:
            return console.warn('%c' + 'Warn', 'padding: 1px 2px; color: #FFFFFF; background: #FF8C00', category);
          case this.logLevel.Error:
            return console.error('%c' + 'Error', 'padding: 1px 2px; color: #FFFFFF; background: #DC143C', category);
          default:
            console.debug(msg);
        }
      }
    }
  }
}
