import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, Observable, take, throwError } from 'rxjs';
import {
  Company, CompanyChargeDto,
  CreateCompanyNoteDto,
  CreateCompanyWalletTransactionDto,
  UpdateCompanyNoteDto,
} from '@app/pages/authenticated/pages/users/users.model';
import { BillingTypes, BillingTypesVerbose, ErrorMessages } from '@app/shared/constants';
import { CourierModifierDTO, DynamicPricingDTO } from '@app/shared/services/providers.service';
import { WalletTransactionQueryDto } from '../../pages/authenticated/pages/users/settings/components/wallet-transactions/wallet-transactions.component';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  constructor(private http: HttpClient) {}

  public getBillingType(company: Company): BillingTypes {
    if (company.parentCompany && company.parentCompany.id) {
      return BillingTypes.THIRDPARTY_ACCOUNT;
    }
    switch (company.billingType) {
      case BillingTypes.MACHOOL_ACCOUNT:
        return BillingTypes.MACHOOL_ACCOUNT;
      default:
        return BillingTypes.CREDIT_CARD;
    }
  }

  public getBillingTypeVerbose(company: Company): BillingTypesVerbose {
    const billingType: BillingTypes = this.getBillingType(company);
    switch (billingType) {
      case BillingTypes.MACHOOL_ACCOUNT:
        return BillingTypesVerbose.MACHOOL_ACCOUNT;
      case BillingTypes.THIRDPARTY_ACCOUNT:
        return BillingTypesVerbose.THIRDPARTY_ACCOUNT;
      default:
        return BillingTypesVerbose.CREDIT_CARD;
    }
  }

  public get(uuid: string, withExtraInfo?: boolean): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/companies/${uuid}`, {
        params: {
          with_extra_info: !!withExtraInfo as any,
        },
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res: any) => {
          return res.body;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public update(uuid: string, req: any): Observable<any> {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/companies/${uuid}`, req).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public createNote(uuid: string, createCompanyNoteDto: CreateCompanyNoteDto): Observable<any> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/companies/${uuid}/notes`, createCompanyNoteDto).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public updateNote(uuid: string, updateCompanyNoteDto: UpdateCompanyNoteDto): Observable<any> {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/companies/${uuid}/notes`, updateCompanyNoteDto).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public deleteNote(uuid: string, deleteNotesDto: any): Observable<any> {
    return this.http.delete(`${environment.APP_SERVICES_URL}/admin/companies/${uuid}/notes`, deleteNotesDto).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getSalesReps(): Observable<any> {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/companies/sales_reps`).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  // COURIER MODIFIERS

  public getCourierModifiers(companyID: number): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/company_courier_modifiers`, {
        params: { company_id: companyID },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public createCourierModifier(companyID: number, createCourierModifierDto: CourierModifierDTO): Observable<any> {
    return this.http
      .post(`${environment.APP_SERVICES_URL}/admin/company_courier_modifiers`, createCourierModifierDto, {
        params: { company_id: companyID },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public updateCourierModifier(id: number, updateCourierModifierDto: CourierModifierDTO): Observable<any> {
    return this.http
      .put(`${environment.APP_SERVICES_URL}/admin/company_courier_modifiers/${id}`, updateCourierModifierDto)
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public deleteCourierModifier(id: number): Observable<any> {
    return this.http.delete(`${environment.APP_SERVICES_URL}/admin/company_courier_modifiers/${id}`).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getWalletTransactions(companyId: string, params: WalletTransactionQueryDto): Observable<any> {
    // Convert Dto to httpParams since typeScript cannot automatically map the DTO fields to the required format for HTTP parameters
    const httpParams = new HttpParams({ fromObject: { ...params } });
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/company/${companyId}/wallet_transactions`, { params: httpParams }).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getCompanyCharges(companyId: string): Observable<CompanyChargeDto[]> {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/company_charges/${companyId}`).pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public createCompanyCharge(companyId: string, body: any): Observable<CompanyChargeDto[]> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/company_charges/${companyId}`, body).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public createWalletTransaction(
    companyId: string,
    createCompanyWalletTransactionDto: CreateCompanyWalletTransactionDto
  ): Observable<any> {
    return this.http
      .post(
        `${environment.APP_SERVICES_URL}/admin/company/${companyId}/wallet_transactions`,
        createCompanyWalletTransactionDto
      )
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  // DYNAMIC PRICING

  public getDynamicPricing(companyID: number): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/company_dynamic_pricing`, {
        params: { company_id: companyID },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public createDynamicPricing(companyID: number, createDynamicPricingDto: DynamicPricingDTO): Observable<any> {
    return this.http
      .post(`${environment.APP_SERVICES_URL}/admin/company_dynamic_pricing`, createDynamicPricingDto, {
        params: { company_id: companyID },
      })
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public updateDynamicPricing(id: number, updateDynamicPricingDto: DynamicPricingDTO): Observable<any> {
    return this.http
      .put(`${environment.APP_SERVICES_URL}/admin/company_dynamic_pricing/${id}`, updateDynamicPricingDto)
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
      );
  }

  public deleteDynamicPricing(id: number): Observable<any> {
    return this.http.delete(`${environment.APP_SERVICES_URL}/admin/company_dynamic_pricing/${id}`).pipe(
      map((res: any) => res),
      catchError((error: HttpErrorResponse) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }
}
