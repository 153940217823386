<ng-container *ngIf='!loading, else isLoading'>
  <form [formGroup]="filterForm">
    <mat-card [ngClass]="{'mb--sm': (companies || []).length == 0 }">
      <mat-card-content class='pb--none'>
        <div class='row'>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Company name</mat-label>
              <input matInput formControlName="companyName" name="companyName" value="">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--8 align-right mt--xs'>
            <button mat-button color='primary' type='button' class='mr--md' (click)='reset()' [disabled]="filterForm.invalid || loading">Clear all</button>
            <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="filterForm.invalid || loading">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

  <div class='column--12 column-md--6 align-left'>
    <button mat-flat-button color='primary' type='submit' class='mr--sm my--md' [disabled]="!canGenerateInvoicesForCustomers || loading || (selection.selected || []).length === 0" (click)="openGenerateInvoiceAllModal(selection)">Generate Invoice</button>
  </div>
  <mat-card class='mat-elevation-z0 mb--sm'>
    <mat-card-content class='pt--none'>
      <div class='row'>
        <div class='column--12'>
          <table mat-table [dataSource]="companiesDataSet" class="mt--sm mat-elevation-z0" matSort [matSortActive]="orderByValue" [matSortDirection]="sortAscending" (matSortChange)="setOrder($event)">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style='width: 5%'>
                  <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null"
                                [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by company" style='width: 20%'> COMPANY ({{(companies || []).length || 0}})</th>
                <td mat-cell *matCellDef="let row" class='text--sm'> {{ row.name }}
                  <span class="mat-icon-style" [matTooltip]="row.billingInvoicePaymentInfo.tooltip" matTooltipPosition="above"><mat-icon class="{{ row.billingInvoicePaymentInfo.icon }} mat-icon-sm text-base mt-20 pt-10 text-{{ row.billingInvoicePaymentInfo.color }}"></mat-icon></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="billingAmount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by balance" style='width: 15%'> BALANCE </th>
                <td mat-cell *matCellDef="let row" class='text--sm' [ngClass]="getBillingAmountClass(row)"> {{ row.billingAmount | currency }} </td>
              </ng-container>
              <ng-container matColumnDef="limit">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'> LIMIT </th>
                <td mat-cell *matCellDef="let row" class='text--sm'> {{ row.billingLimit | currency }} </td>
              </ng-container>
              <ng-container matColumnDef="due">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'> DUE </th>
                <td mat-cell *matCellDef="let row" class='text--sm'> {{ row.billingNetDays }} Days </td>
              </ng-container>
              <ng-container matColumnDef="lastInvoice">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'> LAST INVOICE </th>
                <td mat-cell *matCellDef="let row" class='text--sm'> {{ row.lastInvoice }} </td>
              </ng-container>
              <ng-container matColumnDef="lastPayment">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'> LAST PAYMENT </th>
                <td mat-cell *matCellDef="let row" class='text--sm'> {{ row.lastPayment }}  </td>
              </ng-container>
              <!-- Action Column -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class='align-center'></th>
                <td class='text--sm align-right' mat-cell *matCellDef="let row" class='text--sm'>
                  <ng-container *ngIf="row.name, else processing">
                    <button mat-icon-button [matMenuTriggerFor]="invoiceMenu" aria-label="Invoice actions" color='primary'>
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                    <mat-menu #invoiceMenu="matMenu">
                      <!-- Preview Button -->
                      <button mat-menu-item (click)='createSingleInvoice(row)' [disabled]="!canGenerateInvoicesForCustomers">
                        <span>Create new invoice</span>
                      </button>
                      <!-- Add Payment Button -->
                      <button mat-menu-item (click)='emailLatestInvoice(row)' [disabled]="!canEditAndEmailInvoices || !row?.hasInvoices">
                        <span>Email latest invoice</span>
                      </button>
                      <!-- Add Payment Button -->
                      <button mat-menu-item (click)='viewInvoices(row)' [disabled]="(!canViewCustomerBalances && !canEditAndEmailInvoices) || !row?.hasInvoices">
                        <span>View invoices</span>
                      </button>
                      <!-- Delete Button -->
                      <button mat-menu-item class='border-top' (click)="goToCustomerSettings(row)" [disabled]="!canViewCustomerBalances">
                        <span>Go to Customer Settings</span>
                      </button>
                    </mat-menu>
                  </ng-container>
                  <ng-template #processing>
                    <app-loading [isInline]="true"></app-loading>
                  </ng-template>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let row; columns: columnsToDisplay;">
              </tr>
            </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
