<ng-container>
    <div class='row'>
      <div class='column--3 align-left mt--sm'>
        <img src='{{CDN_URL}}/shared/images/logos/machool-logo.svg' class='machool-logo-small pl--lg' />
        <div class='h5 text--gray-dark mb--lg pl--lg'>Download your {{ documentType | uppercase }} invoice</div>
        <div class='h6 text--gray-dark mb--none pl--lg'>File name: {{ invoiceNumber }}.{{ documentType }}</div>
        <div class='column--6 mt--sm text--sm pl--lg'>
          <button mat-flat-button color='primary' type='button' class='mt--none' (click)="validateTokenAndDownloadDocument(documentType, invoiceNumber, token)">Download {{ documentType | uppercase }}</button>
        </div>
      </div>
      <div class='column--9 align-right'>
        <img src="{{CDN_URL}}/admin/images/invoice-page_bg.svg" class='machool-download-invoice-bg'/>
        <app-loading [isItems]="true" class="machool-items-loading"></app-loading>
      </div>
    </div>
</ng-container>
