<div class="surcharges-header align-center p--md">
  Assign Other
</div>
<div mat-dialog-content class="align-center mt--sm">
  <form [formGroup]='form' (ngSubmit)='onSubmit()'>
    <div class='container'>
      <div class='row'>
        <div class='column--12 px--md'>
          <mat-form-field class="full-width">
            <mat-label>Charge type</mat-label>
            <mat-select
              formControlName="chargeType"
              name='chargeType'
              (selectionChange)='selectFromChargeTypesDropdown($event)'
            >
              <mat-option *ngFor='let chargeType of chargeTypesDropdown' value="{{ chargeType.id }}">{{ chargeType.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class='row px--md' *ngIf="showCustomCodeAndNameInputs">
        <div class='column--4 align-left'>
          <mat-label>Code</mat-label>
          <mat-form-field class="full-width">
            <input matInput type='text' formControlName="customCode" [value]="form.get('customCode').value"/>
          </mat-form-field>
        </div>
        <div class='column--7 align-left'>
          <mat-label>Name</mat-label>
          <mat-form-field class="full-width">
            <input matInput type='text' formControlName="customName" [value]="form.get('customName').value"/>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class='row' mat-dialog-actions>
      <div class='column--3'><button mat-flat-button mat-dialog-close color='accent'>Cancel</button></div>
      <div class='column--9 align-right'>
        <button
          mat-flat-button
          color='primary'
          type="submit"
          mat-dialog-close="true" cdkFocusInitial
          [disabled]="form.invalid"
        >Save</button>
      </div>
    </div>
  </form>
</div>