<ng-container *ngIf='!loading, else isLoading'>
  <div class='row'>
    <div class='column--12 mb--lg'>
      <ng-container *ngIf='invoice'>
        <h2 class='mb--xxs mt--none semi-bold'>Courier: {{ invoice.providerName }}</h2>
        <h3 class='mb--sm mt--none no-bold'>
          Invoice: {{invoice.file_name}}<br/>
          Date: {{invoice.created_at | timeToString : DATE_FORMAT : defaultTimezone}} <br />
          Total: {{ invoice.invoice_amount | currency }}
        </h3>
      </ng-container>
      <ng-template #noInvoice>
        <h2 class='mb--sm'>Shipments for Invoice: {{invoiceName}}</h2>
      </ng-template>
      <a [routerLink]="['/finance/']" class='text--primary-green'>< Back to courier invoices</a>
    </div>
  </div>
  <div class='row row-start'>
    <div class='column--12'>
      <shipments-list [shipments]='shipments' [hideProvider]='true' [totalShipments]='totalShipments' [user]='user'></shipments-list>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
