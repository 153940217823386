import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnsurePageQueryGuard implements CanActivate {
  constructor(private router: Router) {}

  /**
   * Guard to ensure the 'page' query parameter is always present.
   * If the 'page' query parameter is missing, the user is redirected to the same route with 'page=1'.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const page = route.queryParamMap.get('page');

    if (!page) {
      return this.router.createUrlTree([state.url], {
        queryParams: { page: '1' },
        queryParamsHandling: 'merge',
        preserveFragment: true,
      });
    }

    return true;
  }
}
