<div class='container--full-width max--height bg--primary-bg'>
  <div clas='row' style='padding-top: 50px;'>
    <div class='column--10 column-md--6 column-lg--3 mx--auto'>
      <mat-card>
        <mat-card-content>
          <div class='align-center'><img class='signin-logo' alt="Machool Logo" width="165" src="{{CDN_URL}}/shared/images/logos/machool-logo.svg"></div>
          <firebase-ui (signInSuccessWithAuthResult)="successLoginCallback($event)"
                       (signInFailure)="errorLoginCallback($event)"></firebase-ui>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
