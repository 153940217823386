<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>EDI Invoice</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>

<div class='row' *ngIf="ediOnlyTypeEnabled">
  <div class='column--12'>
    <h5>Shipment {{ shipment.orderId }}</h5>
  </div>
  <div class='column--12'>
    <pre>{{ ediInvoiceStringified }}</pre>
  </div>
</div>

<div class='row' *ngIf="unknownTrackingTypeEnabled">
  <div class='column--12' *ngIf="unknownTrackingDataStringified">
    <p>Captured data:</p>
    <pre>{{ unknownTrackingDataStringified }}</pre>
  </div>
  <div class='column--12'>
    <p>Edi Invoice:</p>
    <pre>{{ ediInvoiceStringified }}</pre>
  </div>
</div>


<div class='row mt--md'>
  <div class='column--12 align-center'>
    <button mat-flat-button (click)='dismissDrawer()'>Close</button>
  </div>
</div>
