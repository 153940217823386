import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { EDI_INVOICE_MODAL_TYPE } from '@app/shared/constants';

@Component({
  selector: 'app-edi-invoice',
  templateUrl: './edi-invoice.component.html',
})
export class EdiInvoiceComponent implements OnInit {
  public type: any; //EDI_INVOICE_MODAL_TYPE = EDI_INVOICE_MODAL_TYPE.EDI_INVOICE_ONLY;
  public shipment: any;
  public receiptInfo: any;
  public ediInvoiceStringified: string;
  public unknownTrackingDataStringified: string;

  public ediOnlyTypeEnabled: boolean;
  public unknownTrackingTypeEnabled: boolean;

  public receiptDetails: Array<
    Array<{
      label: string;
      value: string;
    }>
  >;

  constructor(
    public drawerRef: MtxDrawerRef<EdiInvoiceComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { shipment: any; type?: any } // EDI_INVOICE_MODAL_TYPE
  ) {}

  ngOnInit() {
    if (this.data && this.data.shipment) {
      this.shipment = this.data.shipment;
      this.type = this.data.type ? this.data.type : this.type;

      this.ediOnlyTypeEnabled = this.type === EDI_INVOICE_MODAL_TYPE.EDI_INVOICE_ONLY;
      this.unknownTrackingTypeEnabled = this.type === EDI_INVOICE_MODAL_TYPE.UNKNOWN_TRACKING;
      const ediInvoiceParsed =
        typeof this.shipment.ediInvoice === 'string' ? JSON.parse(this.shipment.ediInvoice) : this.shipment.ediInvoice;

      const dateParsed =
        typeof this.shipment.data === 'string' ? JSON.parse(this.shipment.data) : this.shipment.data;

      if (this.unknownTrackingTypeEnabled && this.shipment.data) {
        this.unknownTrackingDataStringified = JSON.stringify(dateParsed, undefined, 2);
      }
      this.ediInvoiceStringified = JSON.stringify(ediInvoiceParsed, undefined, 2);
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }
}
