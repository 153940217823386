import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AdminUser } from '@app/shared/services/user.service';
import { DefaultTimezone } from '@app/shared/constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { InvoicesService } from '@app/shared/services/invoices.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { PageEvent } from '@angular/material/paginator';
import { HelpersService } from '@app/shared/services/helpers.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-machool-invoices',
  templateUrl: './machool-invoices.component.html',
})
export class MachoolInvoicesComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() user: AdminUser;

  public loading: boolean = true;
  public defaultTimezone = DefaultTimezone;
  public searchForm: FormGroup | undefined;
  public currentPage: number = 0;
  public itemsPerPage: number = 100;
  public itemsPerPageOptions: number[] = [100, 150, 200];
  public paginatedInvoices: any[] = [];
  public invoices: any[] = [];
  public invoicesCount: number = 0;
  public displayedColumns: string[] = ['invoiceNumber', 'companyName', 'status', 'dateRange', 'amount', 'createdAt'];
  public invoicesDataSet: any;
  public dateRange = {
    start: DateTime.now().setZone(this.defaultTimezone).minus({ days: 7 }).toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };
  public range = new FormGroup({
    start: new FormControl<Date | null>(this.dateRange.start, [Validators.required]),
    end: new FormControl<Date | null>(this.dateRange.end, [Validators.required]),
  });

  private currentSearchParams: any;

  constructor(
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private router: Router,
    private invoiceService: InvoicesService,
    private billingInvoicesService: BillingInvoicesService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.reset();
  }

  public getShipmentsByInvoice(invoice): void {
    this.sessionStorageService.store('selectedFinanceTab', 'app-machool-invoices');
    this.sessionStorageService.store('financeTabMASearch', this.currentSearchParams);
    this.router.navigate(['finance', 'machool-invoice', invoice.invoiceNumber]);
  }

  public sortData(sort: Sort) {
    const data = this.paginatedInvoices.slice();
    if (!sort.active || sort.direction === '') {
      this.invoicesDataSet = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.invoicesDataSet = data.sort((a, b) => this.helpersService.compare(a[sort.active], b[sort.active], isAsc));
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedInvoices = this.invoices.slice(start, end);
    this.invoicesDataSet = this.paginatedInvoices;
  }

  public reset() {
    this.createForm();
    this.range.patchValue({
      start: this.dateRange.start,
      end: this.dateRange.end,
    });
    this.search();
  }

  public search() {
    this.loading = true;
    this.currentPage = 0;
    this.invoicesCount = 0;
    this.invoices = [];
    this.paginatedInvoices = [];

    const query = { invoice_number: '' };
    let invoiceNumber = '';
    const storedSearchParams = this.sessionStorageService.retrieve('financeTabMASearch');
    if (storedSearchParams) {
      invoiceNumber = (storedSearchParams.invoice_number || '').trim();
      if ((!invoiceNumber || invoiceNumber !== '') && storedSearchParams.startDate && storedSearchParams.endDate) {
        this.range.patchValue({
          start: storedSearchParams.startDate,
          end: storedSearchParams.endDate,
        });
      }
      this.sessionStorageService.clear('financeTabMASearch');
      this.searchForm.patchValue({
        invoiceNumber: invoiceNumber,
      });
    } else {
      const searchValues = (this.searchForm || {}).value;
      invoiceNumber = searchValues?.invoiceNumber ? searchValues?.invoiceNumber.trim() : '';
    }

    if (invoiceNumber.length > 0) {
      _.extend(query, {
        invoice_number: invoiceNumber ? invoiceNumber.trim() : '',
      });
    } else {
      delete query.invoice_number;
      const startDate = DateTime.fromJSDate(this.range.value.start);
      const endDate = DateTime.fromJSDate(this.range.value.end);

      _.extend(query, {
        start_date: startDate.startOf('day').toUTC().toISO({ includeOffset: false }),
        end_date: endDate.endOf('day').toUTC().toISO({ includeOffset: false }),
      });
    }

    this.currentSearchParams = {
      invoice_number: query.invoice_number,
      startDate: this.range.value.start,
      endDate: this.range.value.end,
    };

    this.invoiceService.getInvoicesByStatus(query).subscribe(
      (res) => {
        this.logger.log('Invoices by status GET invoices', res);
        this.invoicesCount = res.invoicesCount;
        this.invoices = res.invoices;
        this.invoices.map((invoice: any) => {
          invoice.className = this.billingInvoicesService.convertStatusToClass(invoice);
          invoice.companyName = invoice?.company?.name ? invoice.company.name : 'N/A';
          invoice.csvStatementAvailable = this.invoiceService.isInvoiceSummaryPath(invoice.invoiceUrl);
          invoice.pdfStatementAvailable = !invoice.company?.settings?.noBillingStatement;
          invoice.billingInvoicePaymentInfo = invoice.company?.billingInvoicePaymentInfo;
        });

        const end = this.itemsPerPage * (this.currentPage + 1);
        const start = end - this.itemsPerPage;
        this.paginatedInvoices = this.invoices.slice(start, end);

        this.invoicesDataSet = new MatTableDataSource(this.paginatedInvoices);
        this.invoicesDataSet.sort = this.sort;
        this.loading = false;
      },
      (error) => {
        this.logger.log('Invoices - GET invoices error', error);
        this.invoicesCount = 0;
        this.invoices = [];
      }
    );
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      invoiceNumber: ['', []],
      startDate: [this.dateRange.start, [Validators.required]],
      endDate: [this.dateRange.end, [Validators.required]],
    });
  }
}
