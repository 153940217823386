import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';


@Injectable({ providedIn: 'root' })
export class InsuranceService {

  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) { }

  public getCertificate(params): any {
    return this.http.get(`${environment.INTEGRATION_SERVICES_URL}/admin/insurance/get-certificate`, { params }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }
}
