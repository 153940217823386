<mat-card>
  <mat-card-content class='card p--md'>
    <ng-container *ngIf="!loadingCard, else loading">
      <div class='row'>
        <div class='column--8'>
          <div class='pt--sm'>
            <i class='icon' [ngClass]='iconClass'></i>
            <span class='card-title h6 ml--xs'[ngClass]="titleClass">{{ cardTitle }}</span>
          </div>
        </div>
        <div class='column--4 align-right'>
          <button *ngIf='canDownloadData' mat-icon-button color='primary' class='mat-small-icon' (click)="downloadData.emit(true)" [disabled]='loadingData'>
            <i class='icon-download text--base'></i>
          </button>
          <button mat-icon-button color='primary' class='mat-small-icon' (click)="refresh.emit(true)">
            <i class='icon-refresh text--base'></i>
          </button>
        </div>
      </div>
      <ng-container *ngIf="!loadingCardFailed, else loadingFailed">
        <div class='card-text mt--sm'>
          <div class='h2'>{{ isCurrency ? (totalStat | currency) : totalStat }}</div>
        </div>
        <div *ngIf="subStatsTemplate" class='card-text text--sm'>
          <ng-container *ngTemplateOutlet="subStatsTemplate"></ng-container>
        </div>
      </ng-container>
      <ng-template #loadingFailed>
        <div class='card-text h1 mt--sm mb--none align-center'>
          <i class='icon-warn text--warn'></i>
          <div class='card-text text-center'>Ship! Something went wrong</div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loading>
      <div class='align-center mt--sm'>
        <app-loading></app-loading>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>
