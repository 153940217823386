import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent implements OnInit {
  @Input() isInline: boolean = false;
  @Input() inButton: boolean = false;
  @Input() isItems: boolean = false;

  public extraClass: string = 'mx--auto';
  public loadingSettings: {
    width: number;
    height: number;
    path: string;
  };
  public CDN_URL: string = environment.CDN_URL;

  constructor() {}

  ngOnInit() {
    if (this.isInline) {
      this.extraClass = 'inline-loader ml--sm';
      this.loadingSettings = {
        width: 60,
        height: 20,
        path: `${this.CDN_URL}/shared/animations/loading-inline.json`,
      };
    } else if (this.inButton) {
      this.extraClass = 'button-loader inline-loader';
      this.loadingSettings = {
        width: 20,
        height: 20,
        path: `${this.CDN_URL}/shared/animations/loading-buttons.json`,
      };
    } else if (this.isItems) {
      this.extraClass = 'button-loader inline-loader';
      this.loadingSettings = {
        width: 778,
        height: 767,
        path: `${this.CDN_URL}/shared/animations/loading-items.json`,
      };
    } else {
      this.loadingSettings = {
        width: 100,
        height: 100,
        path: `${this.CDN_URL}/shared/animations/loading-main.json`,
      };
    }
  }
}
