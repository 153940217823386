import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { AdminUser } from '@app/shared/services/user.service';
import { ShipmentDetailsComponent } from '@app/pages/authenticated/components/shipment-details/shipment-details.component';
import { HelpersService } from '@app/shared/services/helpers.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Animations, BillingTypes, BillingTypesVerbose, DateFormats, PaymentTypes } from '@app/shared/constants';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'shipments-list',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './shipments-list.component.html',
  imports: [ComponentsModule, CommonModule, SharedModule, ShipmentDetailsComponent],
  animations: [Animations.ExpandAnimation],
})
export class ShipmentsListComponent implements OnInit {
  @Input() shipments: any;
  @Input() user: AdminUser;
  @Input() hideProvider: boolean = false;
  @Input() showCourierInvoice: boolean = false;
  @Input() useCustomPagination: boolean = false;
  @Input() totalShipments: number;
  @Input() currentPage: number = 0;
  @Output() pageChangedEvent: EventEmitter<PageEvent> = new EventEmitter();
  @Output() sortChangedEvent: EventEmitter<Sort> = new EventEmitter();

  public loading: boolean = true;
  public paginatedShipments: any = [];
  public displayedColumns: string[] = [
    'select',
    'tracking',
    'provider_type',
    'status',
    'invoice',
    'hasCourierInvoice',
    'cost',
    'revenue',
    'marginAmount',
    'marginPercent',
    'createdAt',
  ];
  public CDN_URL: string = environment.CDN_URL;
  public DATE_FORMAT: string = DateFormats.DATE_YEAR;
  public expandedElement = null;
  public sortedData: any = [];
  public itemsPerPage: number = 25;
  public itemsPerPageOptions: number[] = [25, 50, 100];

  constructor(private helpersService: HelpersService) {}

  ngOnInit() {
    this.loading = true;
    if (!this.showCourierInvoice) {
      this.displayedColumns.splice(5, 1);
    }
    if (this.hideProvider) {
      this.displayedColumns.splice(2, 1);
    }
    if (this.shipments) {
      this.shipments.forEach((shipment) => {
        shipment.statusVerbose = this.helpersService.convertLegacyShipmentStatusEnumToString(shipment.status);
        shipment.trackingStatusVerbose = shipment.trackingStatus
          ? this.helpersService.convertLegacyShipmentStatusEnumToString(shipment.trackingStatus)
          : 'Not set';
        if (shipment.paymentType === BillingTypes.MACHOOL_ACCOUNT) {
          shipment.invoices = shipment.adminShipmentInvoices.map((invoice) => invoice.invoiceNumber).join(', ');
        } else if (shipment.paymentType === BillingTypes.CREDIT_CARD) {
          shipment.invoices = BillingTypesVerbose.CREDIT_CARD;
        } else if (shipment.paymentType === BillingTypes.MACHOOL_WALLET) {
          shipment.invoices = BillingTypesVerbose.MACHOOL_WALLET;
        } else if (shipment.paymentType === BillingTypes.THIRDPARTY_ACCOUNT) {
          shipment.invoices = BillingTypesVerbose.THIRDPARTY_ACCOUNT;
        }
        shipment.providerName = shipment.isMultiLeg
          ? this.helpersService.convertShipmentProviderToString(shipment.shipmentLegs[0].provider)
          : this.helpersService.convertShipmentProviderToString(shipment.provider);
        const totalCustomerTaxAmount = shipment.adminChargeDetails.customerCharges.reduce(
          (total, { taxes }) => total + taxes.gstAmount + taxes.hstAmount + taxes.qstAmount,
          0
        );
        const totalCustomerAmount = shipment.adminChargeDetails.customerCharges.reduce(
          (total, { totalAmount }) => total + totalAmount,
          0
        );
        shipment.customerTotalAmount = totalCustomerAmount - totalCustomerTaxAmount;
        const totalMATaxAmount = shipment.adminChargeDetails.machoolCharges.reduce(
          (total, { taxes }) => total + taxes.gstAmount + taxes.hstAmount + taxes.qstAmount,
          0
        );
        const totalMAAmount = shipment.adminChargeDetails.machoolCharges.reduce(
          (total, { totalAmount }) => total + totalAmount,
          0
        );
        shipment.machoolTotalAmount = totalMAAmount - totalMATaxAmount;
        shipment.marginAmount = shipment.customerTotalAmount - shipment.machoolTotalAmount;
        shipment.marginPercent =
          Math.round(
            ((shipment.customerTotalAmount - shipment.machoolTotalAmount) / shipment.customerTotalAmount) * 1000
          ) / 10;
        shipment.hasMAInvoice = shipment.invoices?.length > 0;
        shipment.hasCourierInvoice = shipment.ediInvoice ? 'Yes' : 'No';
      });
      if (!this.useCustomPagination) {
        this.pageChanged({
          pageIndex: 0,
          pageSize: this.itemsPerPage,
          length: this.totalShipments,
        });
      } else {
        this.paginatedShipments = this.shipments;
      }
      this.loading = false;
    }
  }

  public sortData(sort: Sort) {
    if (!this.useCustomPagination) {
      const data = this.shipments.slice();
      if (!sort.active || sort.direction === '') {
        this.sortedData = data;
        return;
      }
      const isAsc = sort.direction === 'asc';
      this.sortedData = data.sort((a, b) => this.helpersService.compare(a[sort.active], b[sort.active], isAsc));
      this.shipments = this.sortedData;
    } else {
      this.sortChangedEvent.emit(sort);
    }
    this.pageChanged({
      pageIndex: this.currentPage,
      pageSize: this.itemsPerPage,
      length: this.totalShipments,
    });
  }

  public toggleShipmentDetails(shipmentRow: any) {
    const sameRowClicked = this.expandedElement === shipmentRow;
    this.expandedElement = sameRowClicked ? null : shipmentRow;
  }

  public pageChanged(pageEvent: PageEvent): void {
    if (this.useCustomPagination) {
      this.pageChangedEvent.emit(pageEvent);
    } else {
      this.loading = true;
      this.expandedElement = null;
      this.itemsPerPage = pageEvent.pageSize;
      this.currentPage = pageEvent.pageIndex;
      const end = this.itemsPerPage * (this.currentPage + 1);
      const start = end - this.itemsPerPage;
      this.paginatedShipments = this.shipments.slice(start, end);
      this.loading = false;
    }
  }
}
