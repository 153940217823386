
<ng-container *ngIf='!loading, else isLoading'>
  <ng-container *ngIf='canViewCustomerBalances && canViewCustomerInvoices'>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      dynamicHeight
      animationDuration='0ms'
      [selectedIndex]="selectedTab"
      (selectedIndexChange)='selectedTab = $event'>
      <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
        <ng-template matTabContent>
          <div class='mt--md'>
            <app-customer-balance-invoices
              *ngIf="canViewCustomerBalances && tab.id === 'app-customer-balance-invoices'"
              [user]='user'
              [canViewCustomerBalances]='canViewCustomerBalances'
              [canGenerateInvoicesForCustomers]='canGenerateInvoicesForCustomers'
              [canEditAndEmailInvoices]='canEditAndEmailInvoices'
              [invoicesGenerated]="invoicesGenerated"
              (goToSecondTab)="goToSecondTab($event)"
            ></app-customer-balance-invoices>
            <app-invoices-list
              *ngIf="canViewCustomerInvoices && tab.id === 'app-invoices-list-invoices'"
              [user]='user'
              [canViewCustomerInvoices]='canViewCustomerInvoices'
              [canEditAndEmailInvoices]='canEditAndEmailInvoices'
              [selectedCompanyName]="selectedCompanyName"
              (tabChanged)="tabChanged()"
            ></app-invoices-list>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>