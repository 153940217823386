import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import atLeastOneRequired from '@app/shared/validators/at-least-one-required.validator';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
})
export class FinanceComponent implements OnInit {
  public loading: boolean = true;
  public filterForm: FormGroup | undefined;
  public user: AdminUser;

  public selectedTab: number = 0;
  public tabs = [
    {
      label: 'Courier Invoices',
      id: 'app-courier-invoices',
      scope: [AdminScopes.VIEW_FINANCE],
    },
    {
      label: 'Machool Invoices',
      id: 'app-machool-invoices',
      scope: [AdminScopes.VIEW_FINANCE],
    },
    {
      label: 'Not invoiced by courier',
      id: 'app-not-invoiced-by-courier',
      scope: [AdminScopes.VIEW_FINANCE],
    },
    {
      label: 'Not invoiced to customer',
      id: 'app-not-invoiced-by-machool',
      scope: [AdminScopes.VIEW_FINANCE],
    },
  ];

  constructor(
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_FINANCE])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            // TODO - Setup session vars to change tab
            const storedSelectedTab = this.sessionStorageService.retrieve('selectedFinanceTab');
            if (storedSelectedTab) {
              this.selectedTab = this.tabs.findIndex((tab) => tab.id === storedSelectedTab);
              this.sessionStorageService.clear('selectedFinanceTab');
            }
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }
}
