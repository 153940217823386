import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html'
})
export class ViewDocumentComponent implements OnInit {

  public previewTitle: string = 'View Document';
  public typeFile: string = 'application/pdf';
  public downloadFileName: string;
  public document: any;

  public height: number = window.innerHeight / 1.5;

  constructor(
    public domSanitizer: DomSanitizer,
    public drawerRef: MtxDrawerRef<ViewDocumentComponent>,
    @Inject(MTX_DRAWER_DATA) public data: {
      document: any,
      downloadFileName: string,
      previewTitle: string,
      typeFile:string,
      height: number
    },
  ) {}
  ngOnInit() {
    if (this.data && this.data.document && this.data.downloadFileName) {
      this.downloadFileName = this.data.downloadFileName;
      this.typeFile = this.data.typeFile ? this.data.typeFile : this.typeFile;
      this.previewTitle = this.data.previewTitle ? this.data.previewTitle : this.previewTitle;
      this.height = this.data.height ? this.data.height : this.height;
      this.document = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:${this.typeFile};base64,${this.data.document}`);
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }
}
