import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  DateFormats,
  DeliveryToPickupLocationProviderNames,
  DeliveryToPickupLocationProviders,
  ErrorMessages,
  ShipmentChargeStatus,
  ShippingProviders,
} from '@app/shared/constants';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { formatCurrency } from '@angular/common';
import { InvoiceStatuses } from './billing-invoices.services';

export enum serviceOptionsWithDeclaredValue {
  DECLARED_VALUE = 'declared value',
  COVERAGE = 'coverage',
  INSURED_VALUE = 'insured value',
  ADDITIONAL_INSURANCE = 'additionnal insurance',
}

export interface PickupLocationRO {
  deliveryToPickupProvider: DeliveryToPickupLocationProviders;
  locationId: string;
  locationName: string;
  latitude: number;
  longitude: number;
  isClosestToReference: boolean;
  address: {
    line1: string;
    line2?: string;
    city: string;
    province: string;
    postalCode: string;
    country: string;
  };
  fullAddress: string;
  additionalInfo: {
    email: string;
    openingHours: string;
    phone: string;
    distanceFromReferenceKM: number;
  };
}

@Injectable({ providedIn: 'root' })
export class ShipmentsService {
  public readonly WARNING_LEVEL = 0.9;
  public DATE_YEAR: string = DateFormats.DATE_YEAR;

  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public findByTracking(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/shipments/tracking`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public update(shipmentId: number, params: any): any {
    return this.http
      .put(
        `${environment.APP_SERVICES_URL}/admin/shipments/${shipmentId}`,
        params
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public createReturnShipment(provider: string, params: any): any {
    return this.http
      .post(
        `${environment.INTEGRATION_SERVICES_URL}/admin/shipment/return_shipment/${provider}`,
        params
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public tracking(provider: string, query: any): any {
    const trackingUrl = `${environment.INTEGRATION_SERVICES_URL}/admin/shipment/tracking/${provider}/${query.shipmentId}`;
    return this.http.get(trackingUrl, { params: query }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error || ErrorMessages.UNEXPECTED))
    );
  }

  public getDocuments(params): any {
    params.ext = 'pdf';
    if (params.provider === 'fedex' && params.type === 'manifests') {
      params.ext = 'txt';
    }
    if (params.provider === 'ups' && params.type === 'high-value-reports') {
      params.ext = 'html';
    }
    const documentUrl = `${environment.INTEGRATION_SERVICES_URL}/admin/couriers/documents`;
    return this.http.get(documentUrl, { params }).pipe(
      map((res: any) => res),
      catchError((error: any) =>
        throwError(error.error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public getManifests(params): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/shipment_manifests`, {
        params,
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  // TODO: This logic should move to backend and reuse in app-cli and admin-portal
  public rateByProvider(provider: string, service: string, rates: any) {
    const isCovOptions = (rates.options || []).some((option) =>
      this.shouldShowDeclaredValue(option)
    );
    if (rates && rates.options && rates.options.length > 0 && isCovOptions) {
      rates.options.forEach((options) => {
        if (options.declaredValue) {
          options.name = `${options.name} (value: ${formatCurrency(
            options.declaredValue,
            'en',
            '$',
            'CAD'
          )})`;
        }
      });
    }

    const optionsPrice = this.helperService.sumRates(_.get(rates, 'options'));
    const surchargesPrice = this.helperService.sumRates(
      _.get(rates, 'surcharges')
    );
    const taxesPrice = this.helperService.sumRates(_.get(rates, 'taxes'));
    const taxesBreakdown = _.get(rates, 'taxes', []).filter(
      (tax) => (tax.value || tax.amount) > 0
    );
    const constructedRates: {
      label: string;
      amount: number;
      list?: any;
      discount?: any;
      type?: string;
      infoMessage?: string;
    }[] = [
      {
        label: service,
        amount: _.get(rates, 'base', 0),
      },
      {
        label: 'options',
        amount: optionsPrice,
        list: _.get(rates, 'options', 0),
      },
    ];

    if (
      !_.isEmpty(rates.thirdPartyInsurance) &&
      rates.thirdPartyInsurance.insuranceSelected
    ) {
      constructedRates.push({
        label: `Shipping insurance (value: ${formatCurrency(
          rates.thirdPartyInsurance.declaredValue,
          'en',
          '$',
          'CAD'
        )})`,
        amount: _.get(rates, 'thirdPartyInsurance.baseAmount', 0) || 0,
        type: 'thirdPartyInsurance',
        infoMessage: 'Price includes administrative fee.',
      });
    }

    if (
      !_.isEmpty(rates.deliveryToPickupLocation) &&
      _.get(rates, 'deliveryToPickupLocation.deliveryToPickupLocationSelected')
    ) {
      const selectedPickupLocation: PickupLocationRO =
        _.get(rates, 'deliveryToPickupLocation.selectedPickupLocation') ||
        _.get(rates, 'deliveryToPickupLocation');
      const line1 =
        _.get(selectedPickupLocation, 'address.line1') ||
        _.get(selectedPickupLocation, 'line1') ||
        '';
      const line2 =
        _.get(selectedPickupLocation, 'address.line2') ||
        _.get(selectedPickupLocation, 'line2') ||
        '';
      const city =
        _.get(selectedPickupLocation, 'address.city') ||
        _.get(selectedPickupLocation, 'city') ||
        '';
      const postalCode =
        _.get(selectedPickupLocation, 'address.postalCode') ||
        _.get(selectedPickupLocation, 'postalCode') ||
        '';
      const province =
        _.get(selectedPickupLocation, 'address.province') ||
        _.get(selectedPickupLocation, 'province') ||
        '';
      const providerName =
        this.convertToDeliveryProviderName(
          _.get(selectedPickupLocation, 'provider') ||
            _.get(selectedPickupLocation, 'deliveryToPickupProvider')
        ) || 'local pickup';
      constructedRates.push({
        label: `Deliver to ${providerName} location<br> <strong>${
          _.get(selectedPickupLocation, 'locationName') ||
          city + ', ' + province
        }</strong><br> ${line1} ${line2} ${city} ${postalCode} ${province}`,
        amount: _.get(rates, 'deliveryToPickupLocation.baseAmount', 0) || 0,
        type: 'deliveryToPickupLocation',
      });
    }

    constructedRates.push(
      ...[
        {
          label: 'surcharges',
          amount: surchargesPrice,
          list: _.get(rates, 'surcharges', 0),
        },
        {
          label: 'discount',
          amount: _.get(rates, 'discount', 0) || 0,
        },
        {
          label: 'taxes',
          amount: taxesPrice,
          list: taxesBreakdown.length > 0 ? taxesBreakdown : 0,
        },
        {
          label: 'total',
          amount: _.get(rates, 'due', 0),
          discount: {
            label: 'you saved',
            amount: _.get(rates, 'savings', 0) || 0,
          },
        },
      ]
    );

    return [...constructedRates, ...this.getAdjustmentDetails(rates)];
  }

  public isPrintableReturnLabelAvailable(
    shipment: any,
    service?: any
  ): boolean {
    return (
      (shipment.returnTrackings &&
        shipment.returnTrackings.length > 0 &&
        shipment.provider !== ShippingProviders.UPS) ||
      this.isUpsPrintableReturnLabel(shipment, service)
    );
  }

  public isUpsPrintableReturnLabel(shipment: any, service?: any) {
    if (shipment.provider !== ShippingProviders.UPS) return false;

    // For display of printable return label for old UPS shipments only, this method is a workaround for backward compatibility.
    // This is only to check the old created shipments that a newly added 'returnLabel' param is set to 'false' by default.
    const isLabelOldShipment =
      shipment.returnTrackings &&
      shipment.returnTrackings.length > 0 &&
      !shipment.returnLabel;

    return (
      (isLabelOldShipment || shipment.returnLabel) &&
      !this.isNonPrintableReturnLabelOptionSelected(
        service ? service : shipment.service || shipment.rates
      )
    );
  }

  public buildShipmentDTO(
    sender: any,
    recipient: any,
    parcels: any,
    billingInfo?: any,
    service?: any,
    additionalInfo?: any,
    orderId?: string,
    additionalInstructions?: any
  ) {
    // ensure we have the linearUnits properly set
    parcels.forEach((parcel) => {
      const linearUnits = _.get(parcel, 'linearUnits');
      if (
        _.get(parcel, 'imperial') &&
        (!linearUnits || _.isEmpty(linearUnits) || _.isNull(linearUnits))
      ) {
        _.extend(parcel, {
          linearUnits: parcel.imperial ? 'in' : 'cm',
          weightUnits: parcel.imperial ? 'lb' : 'kg',
        });
      }
    });

    const shipmentDto: any = {
      sender: sender,
      recipient: recipient,
      parcels,
    };

    let thirdPartyBilling: object = {};
    if (billingInfo) {
      if (billingInfo.account) {
        // For DHL/Purolator/FedEx/GLS 3rd billing
        thirdPartyBilling = {
          thirdPartyProviderName: billingInfo.provider,
          thirdPartyAccount: billingInfo.account,
        };
        // For contract-CP 3rd billing
        if (billingInfo.customerID) {
          _.extend(thirdPartyBilling, {
            thirdPartyContractId: billingInfo.account,
          });
          _.extend(thirdPartyBilling, {
            thirdPartyCustomerId: billingInfo.customerID,
          });
        }
        // For UPS 3rd billing
        if (billingInfo.countryCode && billingInfo.postalCode) {
          _.extend(thirdPartyBilling, {
            thirdPartyCountryCode: billingInfo.countryCode,
            thirdPartyPostalCode: billingInfo.postalCode,
          });
        }
        _.extend(shipmentDto, { thirdParty: thirdPartyBilling });
      }

      if (billingInfo.cardId) {
        _.extend(shipmentDto, { cardId: billingInfo.cardId });
      }
    }

    if (service) {
      if (this.providerWithDeliveryInstructionField(service.provider)) {
        const deliveryInstructions: string = additionalInstructions
          ? additionalInstructions
          : '';
        _.extend(shipmentDto, { additionalInstructions: deliveryInstructions });
      }

      if (
        service.provider === ShippingProviders.RIVO &&
        _.get(service, 'fsaInfo.services[0].rateCode')
      ) {
        service.rivoServiceType = service.fsaInfo.services[0].rateCode;
      }

      if (
        service.provider === ShippingProviders.DHL &&
        service.dhlOptionAmount &&
        !_.isEmpty(service.dhlOptionAmount)
      ) {
        // set it to null to do a FinalQuote again without using the cache
        orderId = null;
      }

      // For Rivo Dark Mode shipment only, FSA and last mile provider have been required
      if (service.provider === ShippingProviders.RIVO && service?.fsaInfo) {
        _.extend(service, { fsaInfo: service.fsaInfo });
      }

      _.extend(shipmentDto, {
        provider: service.provider,
        providerName: service.provider,
        returnLabel: service.returnLabel || false,
        service,
      });
    }

    if (additionalInfo) {
      _.extend(shipmentDto, { additionalRequest: additionalInfo });
      if (additionalInfo.cardId) {
        _.extend(shipmentDto, { cardId: additionalInfo.cardId });
      }
      if (additionalInfo.pickupDeliveryAvailabilities) {
        if (
          shipmentDto.service &&
          shipmentDto.service.pickupDeliveryAvailabilities
        ) {
          shipmentDto.service.pickupDeliveryAvailabilities =
            additionalInfo.pickupDeliveryAvailabilities;
        } else if (shipmentDto.service) {
          _.extend(shipmentDto.service, {
            pickupDeliveryAvailabilities:
              additionalInfo.pickupDeliveryAvailabilities,
          });
        }
      }
    }

    if (orderId) {
      _.extend(shipmentDto, { orderId });
    }

    return shipmentDto;
  }

  public convertToDeliveryProviderName(
    provider: DeliveryToPickupLocationProviders
  ): DeliveryToPickupLocationProviderNames {
    switch (provider) {
      case DeliveryToPickupLocationProviders.PUDO:
        return DeliveryToPickupLocationProviderNames.PUDO;
      case DeliveryToPickupLocationProviders.PENGUIN_PICKUP:
        return DeliveryToPickupLocationProviderNames.PENGUIN_PICKUP;
    }
  }

  private providerWithDeliveryInstructionField(provider: string): boolean {
    return [ShippingProviders.UNIUNI].includes(provider as ShippingProviders);
  }

  private isNonPrintableReturnLabelOptionSelected(service: any): boolean {
    return _.some(_.get(service, 'options', []), (option) => {
      // Non-Printable Return label options include:
      // - UPS Print and Mail
      // - UPS Return Service 1-Attempt
      // - UPS Return Service 3-Attempt
      return (
        _.includes(
          ['RE-RS1', 'RE-RS3', 'RE-PNM'],
          _.get(option, 'code') || ''
        ) ||
        _.includes(['RE-RS1', 'RE-RS3', 'RE-PNM'], _.get(option, 'code') || '')
      );
    });
  }

  private getAdjustmentDetails(rates: any) {
    const { initialCharge, adjustmentCharges } = rates;
    const constructedRates = [];
    const isRefunded = initialCharge
      ? initialCharge.status === ShipmentChargeStatus.REFUND_APPROVED
      : false;
    const chargeTypeName = isRefunded ? 'Refunded' : 'Paid';
    const isMachoolWallet = initialCharge?.metadata?.companyWalletTransactionId;

    const formatDate = (date: string) => DateTime.fromISO(date).toFormat(this.DATE_YEAR);
    const createLabel = (prefix: string, date: string, reasons?: string[], suffix?: string) =>
      `<span>${prefix}</span>${date ? ` <span>${formatDate(date)}</span>` : ''}${reasons ? ` <span>(${reasons.join(', ')})</span>` : ''}${suffix ? ` <span>${suffix}</span>` : ''}`;

    const addConstructedRate = (label: any, amount: number, adjustment: any, stripeReceiptUrl?: string, invoiceNumber?: number) => {
      constructedRates.push({
          label,
          amount,
          adjustment,
          adjustmentGroup: true,
          stripeReceiptUrl,
          invoiceNumber
      });
    };

    const generateChargeLabelAndAddRate = (charge: any, adjustment: boolean) => {
      const noPaidStatus = ![InvoiceStatuses.PARTIALLY_PAID, InvoiceStatuses.PAID].includes(charge.invoiceStatus);
      let label;

      if (noPaidStatus) {
        label = createLabel(`Unpaid - Invoice #${charge.invoiceNumber}`, charge.invoiceUpdatedAt);
        addConstructedRate(label, Number(charge.amount), adjustment, null, charge.invoiceNumber);
      } else {
        const paidStatus = charge.invoiceStatus === InvoiceStatuses.PAID ? 'Paid' : 'Partially Paid';
        label = createLabel(`${paidStatus} - Invoice #${charge.invoiceNumber}`, charge.chargedAt, charge?.reasons);
        addConstructedRate(label, Number(charge.amount), adjustment, null, charge.invoiceNumber);
      }
    };

    if (isMachoolWallet) {
      addConstructedRate(createLabel(chargeTypeName, initialCharge.chargedAt, undefined, 'by Machool Wallet'), Number(initialCharge.amount), false, initialCharge?.stripeReceiptUrl);
    }

    if (initialCharge && !isMachoolWallet && !initialCharge?.stripeReceiptUrl) {
      if (initialCharge?.invoiceNumber && initialCharge?.invoiceStatus) {
        generateChargeLabelAndAddRate(initialCharge, false);
      }
    }

    if (initialCharge && adjustmentCharges && adjustmentCharges.length > 0) {
      if (initialCharge.stripeReceiptUrl) {
        addConstructedRate(createLabel(chargeTypeName, initialCharge.chargedAt), Number(initialCharge.amount), false, initialCharge.stripeReceiptUrl);
      }

      for (const adjustmentCharge of adjustmentCharges) {
        if (adjustmentCharge.stripeReceiptUrl) {
          addConstructedRate(createLabel('Paid', adjustmentCharge.chargedAt, adjustmentCharge.reasons), Number(adjustmentCharge.amount), true, adjustmentCharge.stripeReceiptUrl);
        }

        if (adjustmentCharge && !adjustmentCharge.stripeReceiptUrl) {
          if (adjustmentCharge?.invoiceNumber && adjustmentCharge?.invoiceStatus) {
            generateChargeLabelAndAddRate(adjustmentCharge, true);
          }
        }

        if (adjustmentCharge?.metadata) {
          if (_.get(adjustmentCharge, 'metadata[0].companyWalletTransactionId')) {
            addConstructedRate(createLabel('Paid', adjustmentCharge.chargedAt, adjustmentCharge.reasons, 'by Machool Wallet'), Number(adjustmentCharge.amount), true, adjustmentCharge.stripeReceiptUrl);
          }
        }
      }
    } else if (
      initialCharge &&
      !isMachoolWallet &&
      (initialCharge.stripeReceiptUrl || isRefunded)
    ) {
      addConstructedRate(createLabel(chargeTypeName, initialCharge.chargedAt), Number(rates.due), false, initialCharge.stripeReceiptUrl);
    }

    return constructedRates;
  }

  private shouldShowDeclaredValue(option: any) {
    const optionName = option && option?.name?.toLowerCase();
    return [
      serviceOptionsWithDeclaredValue.DECLARED_VALUE,
      serviceOptionsWithDeclaredValue.COVERAGE,
      serviceOptionsWithDeclaredValue.INSURED_VALUE,
      serviceOptionsWithDeclaredValue.ADDITIONAL_INSURANCE,
    ].includes(optionName);
  }
}
