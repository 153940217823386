<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>{{ previewTitle }}</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<ng-container *ngIf='document, else isLoading'>
  <div class='row mt--md'>
    <div class='column--12'>
      <object *ngIf="document" id="preview" [type]="typeFile" [data]="document" width="100%" [height]="height"></object>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--12 align-center'>
      <button mat-flat-button (click)='dismissDrawer()'>Close</button>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
