<div *ngIf="providerSurcharges">
  <div class="surcharges-header align-center p--md">
    {{ providerName }} Surcharges
  </div>
  <div mat-dialog-content class="align-center mt--sm">
    <form [formGroup]='surchargesForm' (ngSubmit)='updateSurcharges()'>
      <div class='row' *ngFor='let surcharge of providerSurcharges'>
        <div class='column--6'>
          <mat-form-field class='full-width'>
            <input matInput readonly [value]="surcharge.name">
          </mat-form-field>
        </div>
        <div class='column--6'>
          <mat-form-field class="full-width">
            <mat-label>Surcharge Value ($)</mat-label>
            <mat-icon matPrefix>attach_money</mat-icon>
            <input  id="{{ surcharge.code }}" matInput type="number" [formControlName]="surcharge.code" max='1000' min='0' step='1'/>
          </mat-form-field>
        </div>
      </div>
      <div class='row' mat-dialog-actions>
        <div class='column--3'><button mat-flat-button mat-dialog-close color='accent'>Cancel</button></div>
        <div class='column--9 align-right'>
          <button
            mat-flat-button
            color='primary'
            type="submit"
            mat-dialog-close="true" cdkFocusInitial
          >Save</button>
        </div>
      </div>
    </form>
  </div>
</div>

