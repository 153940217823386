import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ProvidersService } from '@app/shared/services/providers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-edit-courier-settings',
  templateUrl: './edit-courier-settings.component.html',
})
export class EditCourierSettingsComponent implements OnInit {
  public loading: boolean = true;
  public provider: any;
  public providerSettingsForm: FormGroup;
  public canEditStatus: boolean;

  constructor(
    public drawerRef: MtxDrawerRef<EditCourierSettingsComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { provider: any; canEditStatus: boolean },
    private formBuilder: FormBuilder,
    private providersService: ProvidersService,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.provider = this.data.provider;
    this.canEditStatus = this.data.canEditStatus;
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public updateSettings(): void {
    const providerSettingValues = this.providerSettingsForm.value;

    const markupPercentageValue = providerSettingValues?.markupPercentageValue || null;
    const markupDollarValue = providerSettingValues?.markupDollarValue || null;
    const deliveryDate = providerSettingValues?.deliveryDate || null;
    const metadata = providerSettingValues?.fuelSurchargeMarkupMargin
      ? JSON.stringify({
          fuelCharge: providerSettingValues.fuelSurchargeMarkupMargin,
        })
      : null;
    const enabled = providerSettingValues?.enabled !== undefined ? providerSettingValues.enabled : null;

    const params = {
      provider: this.provider.providerType,
      category: this.provider.category,
      markupPercentageValue,
      markupDollarValue,
      deliveryDate,
      metadata,
      enabled,
    };
    this.providersService.updateGlobalProvider(params).subscribe(
      (res) => {
        this.bannerNotificationsService.success('Global provider settings successfully updated');
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (err) => {
        this.loading = false;
        this.bannerNotificationsService.error('Failed to update the global provider settings', {
          category: 'Updating global provider settings failed',
          message: err,
        });
      }
    );
  }

  public isValid(): boolean {
    const values = this.providerSettingsForm.value;
    return !Object.values(values).every((value) => value === null || value === '') && this.providerSettingsForm.valid;
  }

  private setupForm(): void {
    const providerMarkupMarginPercent = _.has(this.provider, 'markupPercentageValue')
      ? this.provider.markupPercentageValue
      : null;
    const providerMarkupMarginAmount = _.has(this.provider, 'markupDollarValue')
      ? this.provider.markupDollarValue
      : null;
    const providerDeliveryDate = _.has(this.provider, 'deliveryDate') ? this.provider.deliveryDate : null;
    const fuelSurchargeMarkupMargin = _.get(this.provider.metadata, 'fuelCharge', null);
    const providerEnabled = _.has(this.provider, 'enabled') ? this.provider.enabled : null;

    this.providerSettingsForm = this.formBuilder.group({
      markupPercentageValue: [providerMarkupMarginPercent, [Validators.max(100), Validators.min(0)]],
      markupDollarValue: [providerMarkupMarginAmount, [Validators.max(100000), Validators.min(0)]],
      deliveryDate: [providerDeliveryDate, [Validators.max(10), Validators.min(0)]],
      fuelSurchargeMarkupMargin: [fuelSurchargeMarkupMargin, [Validators.max(100), Validators.min(0)]],
      enabled: [providerEnabled],
    });
  }
}
