import { Component, Inject, OnInit } from '@angular/core';
import {
  Company,
  CompanyNote,
  CreateCompanyNoteDto,
  UpdateCompanyNoteDto,
} from '@app/pages/authenticated/pages/users/users.model';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyNoteType, CompanyNoteVerbose } from '@app/shared/constants';
import { CompanyService } from '@app/shared/services/company.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-update-note',
  templateUrl: './update-note.component.html',
})
export class UpdateNoteComponent implements OnInit {
  public isEditing: boolean;
  public loading: boolean = true;
  public companyNoteType: any = CompanyNoteType;
  public company: Company;
  public note: CompanyNote;
  public noteForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<UpdateNoteComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { company: Company; note?: CompanyNote },
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.note = this.data.note;
    this.isEditing = !!this.note;
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public saveNote(): void {
    if (this.isEditing) {
      this.update();
    } else {
      this.create();
    }
  }

  public convertEnumToVerbose(noteType: CompanyNoteType): CompanyNoteVerbose {
    return this.helpersService.convertNoteTypesEnumToString(noteType);
  }

  private update() {
    this.loading = true;
    this.companyService.updateNote(this.company.uuid, this.buildUpdateNoteDto()).subscribe(
      (note) => {
        this.logger.log('Company Notes - UPDATE Note', note);
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (error) => {
        this.logger.error('Error Company Notes - UPDATE Note', error);
        this.bannerNotificationsService.error('Problem saving note');
        this.dismissDrawer();
      }
    );
  }

  private create() {
    this.loading = true;
    this.companyService.createNote(this.company.uuid, this.buildCreateNoteDto()).subscribe(
      (note) => {
        this.logger.log('Customer Success Company Notes - CREATE Note', note);
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (error) => {
        this.logger.error('Error Company Notes - CREATE Note', error);
        this.bannerNotificationsService.error('Problem saving note');
        this.dismissDrawer();
      }
    );
  }

  private buildUpdateNoteDto(): UpdateCompanyNoteDto {
    return {
      id: this.note.id,
      type: this.noteForm.value.type,
      body: this.noteForm.value.body,
    };
  }

  private buildCreateNoteDto(): CreateCompanyNoteDto {
    return {
      type: this.noteForm.value.type,
      body: this.noteForm.value.body,
    };
  }

  private setupForm() {
    this.noteForm = this.formBuilder.group({
      type: [_.get(this.note, 'type', CompanyNoteType.HAPPY), [Validators.required]],
      body: [_.get(this.note, 'body', ''), [Validators.required]],
    });
  }
}
