import { Component, OnInit } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;
  public canViewMachoolReports: boolean = false;
  public canViewCards: boolean = false;
  public canDownloadCardData: boolean = false;
  public canViewTopRevenue: boolean = false;
  public canViewACCReports: boolean = false;

  public selectedTab: number = 0;
  public tabs = [
    {
      label: 'Machool',
      id: 'app-machool-reports',
      scope: [AdminScopes.VIEW_MACHOOL_REPORTS],
    },
    {
      label: 'Air Canada Cargo',
      id: 'app-acc-reports',
      scope: [AdminScopes.VIEW_ACC_REPORTS],
    },
  ];

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {}
  ngOnInit() {
    this.loading = true;
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_REPORTS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canViewMachoolReports = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.VIEW_MACHOOL_REPORTS]
            );
            this.canViewCards = this.userService.hasAuthScope(this.user, [
              AdminScopes.VIEW_REPORTS_CARDS,
            ]);
            this.canViewTopRevenue = this.userService.hasAuthScope(this.user, [
              AdminScopes.VIEW_REPORTS_TOP_REVENUE,
            ]);
            this.canDownloadCardData = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.VIEW_REPORTS_CARD_DATA]
            );
            this.canViewACCReports = this.userService.hasAuthScope(this.user, [
              AdminScopes.VIEW_ACC_REPORTS,
            ]);

            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }
}
