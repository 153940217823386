import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class MailerService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public send(emailType: string, templateData: any, toEmail?: string): any {
    const headers = this.helperService.getAuthHeaders();
    const query = {
      emailType,
      templateData,
      toEmail,
    };

    return this.http
      .post(`${environment.UTILITY_SERVICES_URL}/admin/notification/email`,
        query,
        { headers }
      )
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
