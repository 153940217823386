import { Component } from '@angular/core';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  title = 'admin-portal';
  public CDN_URL: string = environment.CDN_URL;
  constructor(public router: Router, public sessionStorageService: SessionStorageService) {
    this.sessionStorageService.clear();
  }

  public successLoginCallback(successfulLogin: FirebaseUISignInSuccessWithAuthResult) {
    this.router.navigate(['home']);
  }

  public errorLoginCallback(errorLogin: FirebaseUISignInFailure) {
    console.error(errorLogin);
  }
}
