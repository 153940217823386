import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardComponent } from '@app/pages/authenticated/pages/dashboard/dashboard.component';
import { ModifyCardComponent } from '@app/pages/authenticated/pages/dashboard/components/modify-card/modify-card.component';
import { DashboardCardComponent } from '@app/pages/authenticated/pages/dashboard/components/dashboard-card/dashboard-card.component';
import { AddDashboardComponent } from '@app/pages/authenticated/pages/dashboard/components/add-dashboard/add-dashboard.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_DASHBOARD] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          scopes: [AdminScopes.VIEW_DASHBOARD],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    AddDashboardComponent,
    DashboardComponent,
    DashboardCardComponent,
    ModifyCardComponent,
  ],
})
export class DashboardModule {}
