<h1 mat-dialog-title>{{ data && data.title ? data.title : 'Confirmation' }}</h1>
<div mat-dialog-content>
  <div class='mat-dialog-body' [innerHTML]="data && data.content ? data.content : ''"></div>
</div>

<div class='row' mat-dialog-actions>
  <div class='column--3'><button mat-flat-button mat-dialog-close color='accent'>Cancel</button></div>
  <div class='column--9 align-right'>
    <button
      mat-flat-button
      [color]="data && data.btnColor ? data.btnColor : 'primary'"
      [mat-dialog-close]="true" cdkFocusInitial
    >{{ data && data.confirmBtnLabel ? data.confirmBtnLabel : 'Confirm' }}</button>
  </div>
</div>

