<ng-container *ngIf='card, else emptyCard'>
  <mat-card class='p--none h-100 dashboard-card'>
    <img mat-card-image src='{{ card.imageUrl }}' style='width: 100%'>
    <mat-card-content>
      <h4 class='align-center semi-bold'>{{ card.title }}</h4>
      <div [innerHTML]='card.body'></div>
    </mat-card-content>
    <mat-card-footer *ngIf='card.buttonUrl' class='align-center mt--auto mb--md pt--md'>
      <span class='text--primary-green no-underline semi-bold'>{{ card.buttonLabel }}</span>
    </mat-card-footer>
    <div class='card-hover'>
      <button mat-stroked-button type='button' class='btn-card-edit' (click)='this.editCardRequested.emit(card)'>
        <mat-icon class='icon-edit'></mat-icon>
        Edit
      </button>
    </div>
  </mat-card>
</ng-container>
<ng-template #emptyCard>
  <mat-card class='p--none h-100 dashboard-card empty'>
    <mat-card-content class='align-center'>
      <div class='empty-card-content'>
        <div class='text--xs mt--lg'>Currently displayed</div>
        <h4 class='align-center semi-bold mt--none mb--lg'>{{ defaultCard.title }}</h4>
        <div class='mt--md text--sm mb--lg'>
          <strong class='semi-bold'>Select from dropdown above<br>
            or<br>
          </strong>
          <a class='text--primary-green cursor-pointer' (click)='addCardRequested.emit()'>Add new card</a>
        </div>
        <div class='mt--md text--sm mb--xl text--xs semi-bold'>
          Default card will be automatically set<br>if you leave it empty
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
