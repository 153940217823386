import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modals-assign-other-to-charge',
  templateUrl: './modals-assign-other-to-charge.component.html',
})

export class ModalsAssignOtherToChargeComponent implements OnInit {
  public form: FormGroup;
  public showCustomCodeAndNameInputs: boolean = false;
  public result: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalsAssignOtherToChargeComponent>,
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  public readonly chargeTypesDropdown = [
    { id: 'WAIT', text: 'Waiting time' },
    { id: 'OVS', text: 'Oversized package' },
    { id: 'NCV', text: 'NonConveyable' },
    { id: 'OML', text: 'Over max limit' },
    { id: 'SDS', text: 'Second delivery' },
    { id: 'TDS', text: 'Third delivery' },
    { id: 'UDL', text: 'Undeliverable' },
    { id: 'DCL', text: 'Declined Delivery' },
    { id: 'ADR', text: 'Address correction' },
    { id: 'AJS', text: 'Adjusted service' },
    { id: 'SDL', text: 'Shipment delivered' },
    { id: 'SR', text: 'Signature required' },
    { id: 'APC', text: 'Attempted pickup' },
    { id: 'CUSTOM', text: 'Custom code and name' },
  ];

  public onSubmit() {
    const { chargeType, customCode, customName } = this.form.value;

    if (chargeType === 'CUSTOM') {
      this.result = {
        chargeCode: customCode,
        chargeName: customName,
      }
    } else {
      const selectedChargeType = this.chargeTypesDropdown.find((type) => type.id === chargeType);
      this.result = {
        chargeCode: selectedChargeType.id,
        chargeName: selectedChargeType.text,
      }
    }

    this.dialogRef.close(this.result);
  }


  public selectFromChargeTypesDropdown(chargeType: any): void {
    if (chargeType?.value === 'CUSTOM') {
      this.showCustomCodeAndNameInputs = true;
      this.form.get('customCode').setValidators(this.codeValidators.concat(Validators.required));
      this.form.get('customName').setValidators(this.nameValidators.concat(Validators.required));
    } else {
      this.showCustomCodeAndNameInputs = false;
      this.form.get('customCode').setValidators(this.codeValidators);
      this.form.get('customName').setValidators(this.nameValidators);
    }
    this.form.get('customCode').updateValueAndValidity();
    this.form.get('customName').updateValueAndValidity();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      chargeType: ['', [Validators.required]],
      customCode: ['', this.codeValidators],
      customName: ['', this.nameValidators],
    });
  }

  private codeValidators = [
    Validators.maxLength(6),
    Validators.minLength(2),
  ];

  private nameValidators = [
    Validators.maxLength(30),
    Validators.minLength(5),
  ];
}
