
<div class='row row-start'>
  <div class='column--12'>
    <form [formGroup]="searchForm">
      <mat-card class='mb--md'>
        <mat-card-content class='pb--none'>
          <div class='row'>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Courier</mat-label>
                <mat-select formControlName="provider" name='providerItem'>
                  <mat-option *ngFor='let providerItem of providerItems' value="{{ providerItem.id }}">{{ providerItem.text }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Tracking number</mat-label>
                <input matInput formControlName='trackingNumber' name='trackingNumber' value="">
              </mat-form-field>
            </div>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class='column--12 align-right mb--sm'>
              <button mat-button color='primary' type='button' (click)='reset()' class='mr--md' [disabled]="searchForm.invalid || loading">Clear all</button>
              <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>

<ng-container *ngIf='!loading, else isLoading'>
  <div class='row row-start'>
    <div class='column--12'>
      <shipments-list
        [shipments]='shipments'
        [user]='user'
        [totalShipments]='totalShipments'
      ></shipments-list>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
