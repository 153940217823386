<ng-container *ngIf='!loading && company, else isLoading'>
  <div class='row mt--md'>
    <div class='column--12'>
      <div class='row'>
        <div class='column--12 align-right'>
          <button mat-flat-button color='primary' [disabled]="!canEdit" (click)='addCourierModifier()'>Add modifier <mat-icon class='icon-plus'></mat-icon></button>
        </div>
      </div>
      <div class='row mt--md' *ngIf="courierModifiers?.length > 0, else noModifiers">
        <div class='column--12'>
          <mat-card class='mat-elevation-z0'>
            <mat-card-content>
              <table mat-table [dataSource]="courierModifiers" class="mat-elevation-z0">
                <!-- Type Column -->
                <ng-container matColumnDef="provider">
                  <th mat-header-cell *matHeaderCellDef style='width: 50%'>Courier</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.providerName }}
                  </td>
                </ng-container>
                <!-- Markup Column -->
                <ng-container matColumnDef="markups">
                  <th mat-header-cell *matHeaderCellDef>Markup</th>
                  <td mat-cell *matCellDef="let element" class='text--sm'>
                    {{ element.markups }}
                  </td>
                </ng-container>
                <!-- Delivery Date Column -->
                <ng-container matColumnDef="delivery_date">
                  <th mat-header-cell *matHeaderCellDef>Delivery Date</th>
                  <td mat-cell *matCellDef="let element" class='text--sm'>
                    {{ element.deliveryDateDisplay }}
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef style='width: 100px'></th>
                  <td mat-cell *matCellDef="let element" style='vertical-align: middle;'>
                    <!-- Edit Button -->
                    <button mat-icon-button [disabled]="!canEdit" (click)='updateCourierModifier(element)' class='mat-small-icon text-primary'><mat-icon class='icon-edit'></mat-icon></button>
                    <!-- Delete Button -->
                    <button mat-icon-button [disabled]="!canEdit" (click)='deleteCourierModifier(element)' class='mat-small-icon text-danger'><mat-icon class='icon-bin'></mat-icon></button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <ng-template #noModifiers>
        <div class='row mt--md'>
          <div class='column--12 align-center'>
            <h3>No courier modifier found.</h3>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
