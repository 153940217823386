<ng-container *ngIf="!loading && company; else isLoading">
  <div class="row mt--md">
    <div class="column--12">
      <div class="row">
        <div class="column--12 align-right">
          <button
            mat-flat-button
            color="primary"
            [disabled]="!canEdit"
            (click)="addDynamicPricing()"
          >
            Add new <mat-icon class="icon-plus"></mat-icon>
          </button>
        </div>
      </div>
      <div
        class="row mt--md"
        *ngIf="dynamicPricingRules?.length > 0; else noDynamicPricing"
      >
        <div class="column--12">
          <mat-card class="mat-elevation-z0">
            <mat-card-content>
              <table
                mat-table
                [dataSource]="dynamicPricingRules"
                class="mat-elevation-z0"
              >
                <!-- Source Courier Column -->
                <ng-container matColumnDef="source_courier">
                  <th mat-header-cell *matHeaderCellDef style="width: 25%">
                    Source
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.sourceCourierName }}
                  </td>
                </ng-container>
                <!-- Target Courier Column -->
                <ng-container matColumnDef="target_courier">
                  <th mat-header-cell *matHeaderCellDef style="width: 25%">Courier</th>
                  <td mat-cell *matCellDef="let element" class="text--sm">
                    {{ element.targetCourierName }}
                  </td>
                </ng-container>
                <!-- Min Margin Column -->
                <ng-container matColumnDef="min_margin_percentage">
                  <th mat-header-cell *matHeaderCellDef>Min Margin</th>
                  <td mat-cell *matCellDef="let element" class="text--sm">
                    {{ element.minMarginPercentageValue }}%
                  </td>
                </ng-container>
                <!-- Beat By Column -->
                <ng-container matColumnDef="beat_by_percentage">
                  <th mat-header-cell *matHeaderCellDef>Beat By</th>
                  <td mat-cell *matCellDef="let element" class="text--sm">
                    {{ element.beatPriceByPercentageValue }}%
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="width: 100px"
                  ></th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    style="vertical-align: middle"
                  >
                    <!-- Edit Button -->
                    <button
                      mat-icon-button
                      [disabled]="!canEdit"
                      (click)="updateDynamicPricing(element)"
                      class="mat-small-icon text-primary"
                    >
                      <mat-icon class="icon-edit"></mat-icon>
                    </button>
                    <!-- Delete Button -->
                    <button
                      mat-icon-button
                      [disabled]="!canEdit"
                      (click)="deleteDynamicPricing(element)"
                      class="mat-small-icon text-danger"
                    >
                      <mat-icon class="icon-bin"></mat-icon>
                    </button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                ></tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <ng-template #noDynamicPricing>
        <div class="row mt--md">
          <div class="column--12 align-center">
            <h3>No Dynamic Pricing found.</h3>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class="align-center mt--lg">
    <app-loading></app-loading>
  </div>
</ng-template>
