import { Component, Input, OnInit } from '@angular/core';
import { AdminUser } from '@app/shared/services/user.service';
import { FinanceService } from '@app/pages/authenticated/pages/finance/finance.service';
import { DateFormats, DefaultTimezone, SupportedReconProvideItems } from '@app/shared/constants';
import { Sort } from '@angular/material/sort';
import { HelpersService } from '@app/shared/services/helpers.service';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../../../environments/environment';
import { SessionStorageService } from 'ngx-webstorage';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-courier-invoices',
  templateUrl: './courier-invoices.component.html',
})
export class CourierInvoicesComponent implements OnInit {
  @Input() user: AdminUser;

  public loading: boolean = true;
  public CDN_URL: string = environment.CDN_URL;
  public searchForm: FormGroup | undefined;
  public displayedColumns: string[] = ['courier', 'invoice_number', 'invoice_amount', 'file_name', 'created_at'];
  public DATE_FORMAT: string = DateFormats.DATE_SHORT;
  public courierInvoices: any = [];
  public sortedData: any = [];
  public defaultTimezone = DefaultTimezone;
  public providerItems: any = SupportedReconProvideItems;
  public dateRange = {
    start: DateTime.now().setZone(this.defaultTimezone).minus({ month: 1 }).toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };
  public range = new FormGroup({
    start: new FormControl<Date | null>(this.dateRange.start, [Validators.required]),
    end: new FormControl<Date | null>(this.dateRange.end, [Validators.required]),
  });

  private currentSearchParams: any;

  constructor(
    private auditService: FinanceService,
    private helpersService: HelpersService,
    private formBuilder: FormBuilder,
    private router: Router,
    private bannerNotificationsService: BannerNotificationsService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    if (this.user) {
      this.providerItems.sort((a, b) => a.text.localeCompare(b.text));
      this.providerItems.unshift({ id: 'all', value: 'all', text: 'All' });
      this.reset();
    }
  }

  public reset() {
    this.loading = true;
    this.courierInvoices = [];
    this.range.patchValue({
      start: this.dateRange.start,
      end: this.dateRange.end,
    });
    this.createForm();
    this.getCourierInvoicesAudit();
  }

  public search() {
    this.getCourierInvoicesAudit();
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      provider: ['all'],
      invoiceNumber: [],
      startDate: [this.dateRange.start, [Validators.required]],
      endDate: [this.dateRange.end, [Validators.required]],
    });
  }

  public sortData(sort: Sort) {
    const data = this.courierInvoices.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedData = data.sort((a, b) => this.helpersService.compare(a[sort.active], b[sort.active], isAsc));
  }

  public getShipmentsByInvoiceFileName(invoice): void {
    this.sessionStorageService.store('selectedFinanceTab', 'app-courier-invoices');
    this.sessionStorageService.store('financeTabCourierSearch', this.currentSearchParams);
    const invoiceDate = DateTime.fromISO(invoice.created_at).toFormat(DateFormats.DATE_ISO);
    const invoiceName = (invoice.file_name || '').split('.')[0] + `_${invoiceDate}`;
    this.router.navigate(['finance', 'courier-invoice', invoiceName]);
  }

  private getCourierInvoicesAudit() {
    this.loading = true;
    this.courierInvoices = [];
    const storedSearchParams = this.sessionStorageService.retrieve('financeTabCourierSearch');
    let invoiceNumber: string = '';
    let provider: string;
    if (storedSearchParams) {
      this.sessionStorageService.clear('financeTabCourierSearch');

      invoiceNumber = storedSearchParams.invoiceNumber?.trim();
      provider = (storedSearchParams.provider || 'all').trim();
      if (
        (!invoiceNumber || invoiceNumber.length === 0) &&
        storedSearchParams.startDate &&
        storedSearchParams.endDate
      ) {
        this.range.patchValue({
          start: storedSearchParams.startDate,
          end: storedSearchParams.endDate,
        });
      }
      this.searchForm.patchValue({
        provider,
        invoiceNumber,
      });
      this.searchForm.patchValue({
        provider: provider,
        invoiceNumber: invoiceNumber,
      });
    } else {
      const searchValues = this.searchForm?.value;
      invoiceNumber = searchValues?.invoiceNumber || '';
      provider = searchValues?.provider;
    }

    const startDate = DateTime.fromJSDate(this.range.value.start)
      .setZone(this.defaultTimezone)
      .startOf('day')
      .toISO({ includeOffset: false });
    const endDate = DateTime.fromJSDate(this.range.value.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toISO({ includeOffset: false });
    const searchParams = {
      startDate,
      endDate,
      invoiceNumber,
      provider,
    };
    if (!invoiceNumber || invoiceNumber.length === 0) {
      delete searchParams.invoiceNumber;
    } else {
      delete searchParams.startDate;
      delete searchParams.endDate;
    }

    this.currentSearchParams = {
      invoiceNumber,
      provider,
      startDate: this.range.value.start,
      endDate: this.range.value.end,
    };

    this.auditService.getInvoices(searchParams).subscribe(
      (invoices) => {
        invoices.forEach((invoice) => {
          invoice.providerName = this.helpersService.convertSupportedReconProvidersEnumToString(invoice.courier);
        });
        this.courierInvoices = invoices;
        this.sortedData = this.courierInvoices.slice();
        this.loading = false;
      },
      (err: Error) => {
        this.bannerNotificationsService.error(err.message, {
          category: 'GET courier invoices data error',
          message: err,
        });
        this.loading = false;
      }
    );
  }
}
