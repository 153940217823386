import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalProvidersCategory, ProvidersService } from '@app/shared/services/providers.service';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { LoggerService } from '@app/shared/services/logger.service';
import { PanelWidths, ShippingProviderNames, ShippingProviders } from '@app/shared/constants';
import * as _ from 'lodash';
import { HelpersService } from '@app/shared/services/helpers.service';
import { AlertSeverity } from '@app/shared/components/alert-banners/alert-banners.component';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { UpdateNoteComponent } from '@app/pages/authenticated/pages/couriers/components/update-note/update-note.component';
import { EditCourierSettingsComponent } from '@app/pages/authenticated/pages/couriers/components/edit-courier-settings/edit-courier-settings.component';
import { EditChildCourierSettingsComponent } from './components/edit-child-courier-settings/edit-child-courier-settings.component';
import { ModalEditCourierSurchargesComponent } from './components/modal-edit-courier-surcharges/modal-edit-courier-surcharges.component';
import { environment } from '../../../../../environments/environment';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss'],
})
export class CouriersPageComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;

  public loading: boolean = true;
  public user: AdminUser;
  public canEdit: boolean = false;
  public canEditStatus: boolean = false;

  public providers: any[];
  public freightProviders: any[];
  public providerDataset: any;
  public freightProviderDataset: any;
  public globalProviders: any;
  public purolatorAddressCorrectionForm: FormGroup;
  public purolatorAddressCorrectionEnabled: boolean;
  public notifications: any = [];
  public displayedColumns: string[] = [
    'provider',
    'country',
    'markupString',
    'deliveryDateString',
    'note',
    'statusString',
  ];
  public displayedParentColumns: string[] = [...this.displayedColumns, 'action'];
  public deletingCourierCache: boolean = false;
  public orderByValue: string = 'provider';
  public sortAscending: SortDirection = 'desc';
  public CDN_URL: string = environment.CDN_URL;

  public selectedTab: number = 0;
  public tabs = [
    {
      label: 'Couriers',
      id: 'couriers-list',
    },
    {
      label: 'Freight',
      id: 'freight-list',
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private providersService: ProvidersService,
    private userService: UserService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private bannerNotificationsService: BannerNotificationsService,
    private router: Router
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_COURIERS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_COURIERS]);
            this.canEditStatus = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_STATUS]);
            this.getGlobalProviders();
            this.createForm();
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  public setOrder(order: Sort): void {
    this.sortAscending = order.active === this.orderByValue ? (order.direction === 'asc' ? 'asc' : 'desc') : 'asc';

    this.orderByValue = order.active;
    this.providerDataset.sort = this.sort;
  }

  public onAddressCorrectChange(): void {
    if (this.canEdit) {
      const checkboxValue = this.purolatorAddressCorrectionForm.controls['purolatorAddressCorrection'].value;
      const message = !checkboxValue
        ? 'Are you sure you want to <strong>disable</strong> global address correction for all couriers? This change may cause a lot of shipping address issues and Thomas will be mad at you &#128545;'
        : 'Are you sure you want to <strong>enable</strong> global address correction for all couriers? Please make sure Purolator works as usual otherwise Thomas will be mad at you &#128545;';

      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        width: '600px',
        data: {
          title: `Confirmation`,
          content: message,
          confirmBtnLabel: 'Confirm',
          btnColor: 'primary',
        },
      });
      dialogRef.afterClosed().subscribe((disableConfirmed) => {
        if (disableConfirmed) {
          const purolatorProvider = this.providers.find(
            (provider) => provider.provider === ShippingProviderNames.PUROLATOR
          );
          this.purolatorAddressCorrectionEnabled = checkboxValue;
          const params = {
            provider: ShippingProviders.PUROLATOR,
            enabled: purolatorProvider.enabled,
            category: GlobalProvidersCategory.SHIPPING,
            metadata: JSON.stringify({
              purolatorAddressCorrectionEnabled: checkboxValue,
            }),
          };

          this.providersService.updateGlobalProvider(params).subscribe(
            (res) => {
              this.bannerNotificationsService.success('Global provider address correction successfully updated');
              this.loading = false;
            },
            (err) => {
              this.loading = false;
              this.bannerNotificationsService.error('Failed to update the global provider address correction', {
                category: 'Failed to update the global provider address correction',
                message: err,
              });
            }
          );
        } else {
          this.purolatorAddressCorrectionEnabled = !this.purolatorAddressCorrectionEnabled;
        }
      });
    }
  }

  public editCourierNote(provider: any): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(UpdateNoteComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { provider },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.getGlobalProviders();
        }
      });
    }
  }

  public editCourierSurcharges(provider: any): void {
    if (this.canEdit) {
      const dialogRef = this.dialog.open(ModalEditCourierSurchargesComponent, {
        width: '600px',
        data: {
          provider,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const params = {
            provider: provider.providerType,
            category: GlobalProvidersCategory.SHIPPING,
            surcharges: JSON.stringify(result),
          };

          this.providersService.updateGlobalProvider(params).subscribe(
            (res) => {
              this.bannerNotificationsService.success('Provider Surcharges amount successfully updated');
              this.loading = false;
              this.getGlobalProviders();
            },
            (err) => {
              this.loading = false;
              const errorMessage = 'Failed to Update Provider Surcharges amount';
              this.bannerNotificationsService.error(errorMessage, {
                category: errorMessage,
                message: err,
              });
            }
          );
        } else {
          return;
        }
      });
    }
  }

  public editCourierSettings(provider: any): void {
    if (this.canEdit) {
      let drawerRef;
      if (provider.childProviders?.length > 0) {
        drawerRef = this.drawer.open(EditChildCourierSettingsComponent, {
          width: PanelWidths.desktopFull,
          disableClose: true,
          closeOnNavigation: false,
          data: { provider, canEditStatus: this.canEditStatus },
        });
      } else {
        drawerRef = this.drawer.open(EditCourierSettingsComponent, {
          width: PanelWidths.desktopFull,
          disableClose: true,
          closeOnNavigation: false,
          data: { provider, canEditStatus: this.canEditStatus },
        });
      }
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.getGlobalProviders();
        }
      });
    }
  }

  public deleteCacheKey(): void {
    this.deletingCourierCache = true;
    this.providersService.deleteCacheKey().subscribe(
      (res: any) => {
        this.logger.log('Courier Cache deleted successfully');
        this.bannerNotificationsService.success('Courier Cache deleted successfully');
        this.deletingCourierCache = false;
      },
      (err) => {
        this.bannerNotificationsService.error('There was a problem deleting courier Cache', {
          category: 'Failed to delete global courier cache',
          message: err,
        });
        this.deletingCourierCache = false;
      }
    );
  }

  private createForm(): void {
    this.purolatorAddressCorrectionForm = this.formBuilder.group({
      purolatorAddressCorrection: [
        {
          value: this.purolatorAddressCorrectionEnabled,
          disabled: !this.canEdit,
        },
      ],
    });
    this.purolatorAddressCorrectionForm.updateValueAndValidity();
  }

  private getMarkupValue(provider: any): string {
    if (provider && provider.markupDollarValue) {
      return `$${provider.markupDollarValue}`;
    }
    if (provider && provider.markupPercentageValue) {
      return `${provider.markupPercentageValue}%`;
    }
    return '';
  }

  private getDeliveryDateValue(provider: any): string {
    if (provider && provider.deliveryDate) {
      return `+${provider.deliveryDate}`;
    } else {
      return provider.deliveryDate;
    }
  }

  private getBadgeClass(provider: any): string {
    return provider.enabled ? 'delivered' : 'cancelRejected';
  }

  private getStatusText(provider: any): string {
    return provider.enabled ? 'Enabled' : 'Disabled';
  }

  private getGlobalProviders() {
    this.loading = true;
    const providersList = this.providersService.getProvidersList().map((provider) => {
      return {
        provider: provider.name,
      };
    });

    this.providers = [];
    const query = { category: 'S', showAll: true };
    this.providersService.getGlobalProviders(query).subscribe(
      (res: any) => {
        this.loading = false;
        this.logger.log('Get All Shipping global providers', res);
        this.providers = res.globalProviders;
        this.providers.forEach((provider) => {
          if (provider.provider === ShippingProviders.PUROLATOR) {
            this.purolatorAddressCorrectionEnabled = _.get(
              provider,
              'metadata.purolatorAddressCorrectionEnabled',
              true
            );
          }
          provider.providerType = provider.provider;
          provider.country = provider.country;
          provider.markupString = this.getMarkupValue(provider);
          provider.deliveryDateString = this.getDeliveryDateValue(provider);
          provider.statusBadgeClass = this.getBadgeClass(provider);
          provider.statusString = this.getStatusText(provider);
          provider.childProviders.forEach((childProvider) => {
            childProvider.providerType = childProvider.provider;
            childProvider.markupString = this.getMarkupValue(childProvider);
            childProvider.deliveryDateString = this.getDeliveryDateValue(childProvider);
            childProvider.statusBadgeClass = this.getBadgeClass(childProvider);
            childProvider.statusString = this.getStatusText(childProvider);
            childProvider.provider = this.helpersService.convertShipmentProviderToString(childProvider.provider);
          });
          provider.childProviders.sort((a, b) => (a.provider > b.provider ? 1 : b.provider > a.provider ? -1 : 0));
          provider.provider = this.helpersService.convertShipmentProviderToString(provider.provider);
        });
        this.providers.sort((a, b) => (a.provider > b.provider ? 1 : b.provider > a.provider ? -1 : 0));

        const newShippingProvider: any[] = _.differenceBy(providersList, this.providers, 'provider');
        this.providerDataset = new MatTableDataSource(this.providers);

        if (
          newShippingProvider.length > 0 &&
          _.get(newShippingProvider, '[0].provider') !== ShippingProviderNames.RIVO
        ) {
          this.notifications = [];
          newShippingProvider.forEach((shippingProvider) =>
            this.notifications.push({
              severity: AlertSeverity.WARNING,
              message: `${shippingProvider.provider} shipping provider does not exist in our global provider table, please add it manually.`,
            })
          );
        }

        this.setOrder({
          active: this.orderByValue,
          direction: this.sortAscending,
        });
        this.getGlobalFreightProviders();
      },
      (err) => {
        this.loading = false;
        this.logger.log('Failed to get all shipping global providers', err);
      }
    );
  }

  private getGlobalFreightProviders() {
    const freightProvidersList = this.providersService.getFreightProvidersList().map((provider) => {
      return {
        provider: provider.name,
      };
    });

    this.freightProviders = [];
    const query = { category: 'F', showAll: true };
    this.providersService.getGlobalProviders(query).subscribe((res) => {
      this.logger.log('Get All Shipping global providers', res);
      this.freightProviders = res.globalProviders;
      this.freightProviders.forEach((provider) => {
        provider.providerType = provider.provider;
        provider.markupString = this.getMarkupValue(provider);
        provider.deliveryDateString = this.getDeliveryDateValue(provider);
        provider.statusBadgeClass = this.getBadgeClass(provider);
        provider.statusString = this.getStatusText(provider);
        provider.provider = this.helpersService.convertShipmentProviderToString(provider.provider);
      });
      this.freightProviders.sort((a, b) => (a.provider > b.provider ? 1 : b.provider > a.provider ? -1 : 0));

      const newShippingProvider: any[] = _.differenceBy(freightProvidersList, this.freightProviders, 'provider');
      this.freightProviderDataset = new MatTableDataSource(this.freightProviders);

      if (newShippingProvider.length > 0) {
        this.notifications = [];
        newShippingProvider.forEach((shippingProvider) =>
          this.notifications.push({
            severity: AlertSeverity.WARNING,
            message: `${shippingProvider.provider} shipping provider does not exist in our global provider table, please add it manually.`,
          })
        );
      }
    });
  }
}
