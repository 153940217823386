import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheKeys, ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class CacheService {
  constructor(
    private http: HttpClient
  ) {}

  public getCache(key: string): any {
    const defaultValue = 10000
    return this.http
      .get(`${environment.UTILITY_SERVICES_URL}/admin/caching/${key}`)
      .pipe(
        map((res: any) => {
          if (key === CacheKeys.INVOICES_LAST_INVOICE_NUMBER && (res === undefined || res === null)) {
            return defaultValue
          }
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
