import { commonEnv } from './environment.common';

const env: Partial<typeof commonEnv> = {
  staging: true,
  APP_SERVICES_URL: 'https://api.staging2.machool.com/REST-app-services/api/v1',
  ADMIN_PORTAL_URL: 'https://admin.staging2.machool.com',
  INTEGRATION_SERVICES_URL: 'https://api.staging2.machool.com/REST-integration-services/api/v1',
  UTILITY_SERVICES_URL: 'https://api.staging2.machool.com/REST-utility-services/api/v1',
  TIMESERIES_SERVICES_URL: 'https://api.staging2.machool.com/REST-timeseries-services/api/v1',
  firebaseTenantID: 'staging2-admin-panel-biaj7',
};

export const environment = Object.assign(commonEnv, env);
