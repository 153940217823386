import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ErrorMessages, PaymentTypes, PaymentTypesVerbose } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';

export interface Payment {
  id: number;
  paymentDate: string;
  paymentType: PaymentTypes;
  paymentTypeVerbose: PaymentTypesVerbose;
  amount: number;
  description: string;
  formattedDate?: string;
  companyId?: number;
}

export interface CreatePaymentDTO {
  paymentDate: Date;
  paymentType: PaymentTypes;
  amount: number;
  description: string;
  companyId: number;
}

export interface UpdatePaymentDTO {
  paymentDate: Date;
  paymentType: PaymentTypes;
  amount: number;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class PaymentsService {

  public readonly WARNING_LEVEL = 0.9;

  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {
  }

  public getByCompany(params: any): Observable<Payment[]> {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/payments`, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED)));
  }

  public update(id: number, updatePaymentDTO: UpdatePaymentDTO): Observable<Payment> {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/payments/${id}`, updatePaymentDTO, { observe: 'response' }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED)));
  }

  public create(createPaymentDTO: CreatePaymentDTO): Observable<Payment> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/payments`, createPaymentDTO, { observe: 'response' }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED
      ))    );
  }

  public delete(id: number): any {
    return this.http.request('delete', `${environment.APP_SERVICES_URL}/payments/${id}`, { observe: 'response' }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED)));
  }

  public csrShipmentRefund(dto: any): Observable<Payment> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/payments/csrShipmentRefund`, dto, { observe: 'response' }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED
      )));
  }

  public csrCompanyRefund(dto: any): Observable<Payment> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/payments/csrCompanyRefund`, dto, { observe: 'response' }).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED
      )));
  }
}
