import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { CustomerBalanceInvoicesComponent } from '@app/pages/authenticated/pages/invoices/components/customer-balance-invoices/customer-balance-invoices.component';
import { InvoicesComponent } from '@app/pages/authenticated/pages/invoices/invoices.component'
import { GenerateInvoiceComponent } from './components/generate-invoice/generate-invoice.component';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { CreatePaymentComponent } from './components/create-payment/create-payment.component';
import { InvoicePaymentsListComponent } from './components/invoice-payments-list/invoice-payments-list.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { InvoiceMetaComponent } from './components/invoice-meta/invoice-meta.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_INVOICES] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: InvoicesComponent,
        data: {
          scopes: [AdminScopes.VIEW_INVOICES],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    InvoicesComponent,
    CustomerBalanceInvoicesComponent,
    InvoicesListComponent,
    GenerateInvoiceComponent,
    CreateInvoiceComponent,
    CreatePaymentComponent,
    InvoicePaymentsListComponent,
    InvoiceMetaComponent
  ],
})
export class InvoicesModule {}
