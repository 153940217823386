import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timeToString'
})
export class TimeToStringPipe implements PipeTransform {
  transform(timestamp: DateTime | string | number, toFormatString: string, timezone?: string): string {
    if (!timestamp) {
      return '-';
    }
    let luxonTimestamp: DateTime;
    if (_.isObject(timestamp)) {
      luxonTimestamp = timestamp;
    } else {
      if (timezone) {
        luxonTimestamp = (typeof timestamp === 'string') ? DateTime.fromISO(timestamp, { zone: timezone }) : DateTime.fromMillis(timestamp, { zone: timezone });
      } else {
        luxonTimestamp = (typeof timestamp === 'string') ? DateTime.fromISO(timestamp) : DateTime.fromMillis(timestamp);
      }
    }
    return luxonTimestamp.toFormat(toFormatString);
  }
}
