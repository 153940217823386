<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Tracking information</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<div *ngIf='!loading, else isLoading'>
  <ng-container *ngIf='trackingData, else noTrackingData'>
    <div class='container'>
      <div class='row'>
        <div class='column--4'>Shipment status:</div>
        <div class='column--8 align-right text--xs'>
          <a
            *ngIf='courierUrl && !doNotShowCourierUrl'
            [href]='courierUrl'
            mat-button
            color='primary'
            target='_blank'
            class='text--sm p--none m--none'
            aria-label='Track with courier'
          >
            Track with the courier's website
            <i class='icon-ex-link'></i>
          </a>
        </div>
      </div>
      <div class='row row--valign-center'>
        <div class='column--12'>
          <h2 class='py--none my--none' [ngClass]="trackingData.status !== -1 ? 'text-danger' : ''">
            <mat-icon class='text--primary-green'>check_circle_outline</mat-icon>
            <span class='text--md pl--xs'>{{currentStatus}}</span>
          </h2>
        </div>
      </div>
      <div class='row'>
        <div class='column--12'>
          <div>
            <span *ngIf="trackingData && (trackingData.delivery || trackingData.expected) && (trackingData.status !== 3)">Estimated</span>
            <span *ngIf="trackingData && (trackingData.delivery || trackingData.expected) && (trackingData.status === 3)">
              Delivered
            </span>
            <span *ngIf="trackingData && (trackingData.delivery || trackingData.expected)">on {{ trackingData.delivery || trackingData.expected }}</span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="trackingData.events && trackingData.events.length > 0">

        <mat-card class="mt--sm">
          <table
            mat-table
            class="table-striped tracking-progress"
            style="border-radius: 10px; overflow: hidden"
            [dataSource]="trackingData.events"
          >
            <ng-container matColumnDef="date">
              <td mat-cell *matCellDef="let element">
                <div class="text-tracking align-right">{{ element.date }}</div>
                <div class="text-tracking align-right text--sm text--gray-optional">{{ element.time }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="progress">
              <td mat-cell *matCellDef="let element" style="position: relative">
                <div [class]="element.circleClass"></div>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <td mat-cell *matCellDef="let element">
                <div class="text-tracking">{{ element.description }}</div>
                <div class="text-tracking text--sm text--gray-optional">{{ element.location }}</div>
              </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedTrackingColumns"></tr>
          </table>
        </mat-card>
      </ng-container>

    </div>
  </ng-container>
  <ng-template #noTrackingData>
    <div class='container'>
      <div class='row'>
        <div class='column--12'>No tracking information available</div>
      </div>
    </div>
  </ng-template>
  <div class='row mt--md'>
    <div class='column--12 align-center'>
      <button mat-flat-button (click)='dismissDrawer()'>Close</button>
    </div>
  </div>
</div>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
