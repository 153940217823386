<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Note (internal)</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<form [formGroup]="form">
  <div class='row'>
    <div class='column--12'>
      <mat-form-field appearance="outline" class='w-100'>
        <mat-label>Internal Comment</mat-label>
        <textarea matInput formControlName='note' rows='4'  [autofocus]="true"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-checkbox formControlName="doNotBillAdjustments">
        <span class='no-bold'>Do not bill recon adjustments</span>
      </mat-checkbox>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()'>Save</button>
  </div>
</div>
