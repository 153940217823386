<div class='row row-start'>
  <div class='column--12'>
    <form [formGroup]="searchForm">
      <mat-card class='mb--md'>
        <mat-card-content class='pb--none'>
          <div class='row'>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Courier</mat-label>
                <mat-select formControlName="provider" name='providerItem'>
                  <mat-option *ngFor='let providerItem of providerItems' value="{{ providerItem.id }}">{{ providerItem.text }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Invoice number</mat-label>
                <input matInput formControlName='invoiceNumber' name='invoiceNumber' value="">
              </mat-form-field>
            </div>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class='column--12 align-right mb--sm'>
              <button mat-button color='primary' type='button' (click)='reset()' class='mr--md' [disabled]="searchForm.invalid || loading">Clear all</button>
              <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>

<ng-container *ngIf='!loading, else isLoading'>
    <mat-card>
      <mat-card-content>
        <table
          *ngIf='sortedData.length > 0, else noInvoices'
          mat-table
          matSort
          matSortActive="created_at"
          matSortDirection="desc"
          (matSortChange)="sortData($event)"
          [dataSource]="sortedData"
        >
          <ng-container matColumnDef="courier">
            <th mat-header-cell width="20%" *matHeaderCellDef mat-sort-header>Courier</th>
            <td mat-cell *matCellDef="let element">{{ element.providerName }}</td>
          </ng-container>
          <ng-container matColumnDef="invoice_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf='element.invoice_amount'>{{ element.invoice_amount | currency }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoice_number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
            <td mat-cell *matCellDef="let element">
              {{ element.invoice_number }}
            </td>
          </ng-container>
          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>File</th>
            <td mat-cell *matCellDef="let element">
              <button
                *ngIf='element.file_name'
                mat-button
                color='primary'
                class='mdc-small-button text--base align-left'
                (click)="getShipmentsByInvoiceFileName(element)"
              >{{ element.file_name }}</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Recon Date</th>
            <td mat-cell *matCellDef="let element">{{ element.created_at | timeToString : DATE_FORMAT : defaultTimezone }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
        </table>
        <ng-template #noInvoices>
          <div class='row justify-content-center mt--lg'>
            <div class='column--12 column-md--4 align-center'>
              <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' />
              <p>No invoices found</p>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
