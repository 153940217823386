import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { Router } from '@angular/router';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { PanelWidths } from '@app/shared/constants';
import { UpdateDynamicPricingComponent } from '@app/pages/authenticated/pages/users/settings/components/dynamic-pricing/update-dynamic-pricing/update-dynamic-pricing.component';
import { DynamicPricingDTO } from '@app/shared/services/providers.service';
import * as _ from 'lodash';
import { HelpersService } from '@app/shared/services/helpers.service';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-dynamic-pricing',
  templateUrl: './dynamic-pricing.component.html',
})
export class DynamicPricingComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading: boolean = false;
  public canEdit: boolean = false;
  public dynamicPricingRules: DynamicPricingDTO[] = [];
  public displayedColumns: string[] = [
    'source_courier',
    'target_courier',
    'min_margin_percentage',
    'beat_by_percentage',
    'actions',
  ];

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private router: Router,
    private dialog: MatDialog,
    private bannerNotificationsService: BannerNotificationsService,
    private drawer: MtxDrawer
  ) {}

  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_DYNAMIC_PRICING])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_DYNAMIC_PRICING]);

      this.companyService.getDynamicPricing(parseInt(this.company.id)).subscribe(
        (dynamicPricingRules) => {
          this.logger.log('Configure Dynamic Pricing - Get Dynamic Pricing List', dynamicPricingRules);
          this.dynamicPricingRules = dynamicPricingRules.map((dynamicPricing) => {
            dynamicPricing.sourceCourierName = this.helpersService.convertShipmentProviderToString(
              dynamicPricing.sourceCourier
            );

            dynamicPricing.targetCourierName = this.helpersService.convertShipmentProviderToString(
              dynamicPricing.targetCourier
            );

            return dynamicPricing;
          });
          this.loading = false;
        },
        (err) => {
          this.dynamicPricingRules = [];
          this.bannerNotificationsService.error(
            `There was a problem getting the Dynamic Pricing list for this company. ${_.get(err, 'error.message', '')}`
          );
          this.logger.error('Configure Dynamic Pricing - Get Dynamic Pricing list', err);
          this.loading = false;
        }
      );
    } else {
      this.router.navigate(['users']);
    }
  }

  public addDynamicPricing(): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(UpdateDynamicPricingComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: this.company, currentDynamicPricingRules: this.dynamicPricingRules },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.refreshCompany.emit();
          this.bannerNotificationsService.success('Dynamic Pricing was saved');
        }
      });
    }
  }

  public updateDynamicPricing(dynamicPricing: DynamicPricingDTO): void {
    if (this.canEdit) {
      const drawerRef = this.drawer.open(UpdateDynamicPricingComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: this.company, dynamicPricing, currentDynamicPricingRules: this.dynamicPricingRules },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.refreshCompany.emit();
          this.bannerNotificationsService.success('Dynamic Pricing was saved');
        }
      });
    }
  }

  public deleteDynamicPricing(dynamicPricing: DynamicPricingDTO): void {
    if (this.canEdit) {
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: 'Delete dynamic pricing',
          content: 'Are you sure you want to delete this dynamic pricing?',
          confirmBtnLabel: 'DELETE',
          btnColor: 'warn',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.companyService.deleteDynamicPricing(dynamicPricing.id).subscribe(() => {
            this.refreshCompany.emit();
            this.bannerNotificationsService.success('Dynamic Pricing was deleted');
          });
        }
      });
    }
  }
}
