<ng-container *ngIf='!loading && company, else isLoading'>
  <div class='row mt--md'>
    <div class='column--12'>
      <div class='row'>
        <div class='column--12 align-right'>
          <button mat-flat-button color='primary' [disabled]="!canEdit" (click)='addNote()'>Add note <mat-icon class='icon-plus'></mat-icon></button>
        </div>
      </div>
      <div class='row mt--md' *ngIf="companyNotes?.length > 0, else noNotes">
        <div class='column--12'>
          <mat-card class='mat-elevation-z0'>
            <mat-card-content>
              <table mat-table [dataSource]="paginatedCompanyNotes" class="mat-elevation-z0">
                <!-- Users Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef style='width: 150px'>Date</th>
                  <td mat-cell *matCellDef="let element" class='text--sm'>
                    {{ element.createdAt | timeToString : DATE_YEAR }}
                  </td>
                </ng-container>
                <!-- Type Column -->
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef style='width: 30px'>Type</th>
                  <td mat-cell *matCellDef="let element">
                    <mat-icon [ngClass]="getNoteTypeClass(element.type)"></mat-icon>
                  </td>
                </ng-container>
                <!-- Note Column -->
                <ng-container matColumnDef="note">
                  <th mat-header-cell *matHeaderCellDef>Note</th>
                  <td mat-cell *matCellDef="let element" class='text--sm'>
                    {{ element.body }}
                  </td>
                </ng-container>
                <!-- Actions Column -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef style='width: 100px'></th>
                  <td mat-cell *matCellDef="let element" style='vertical-align: middle;'>
                    <!-- Edit Button -->
                    <button mat-icon-button [disabled]="!canEdit" (click)='updateNote(element)' class='mat-small-icon'><mat-icon class='icon-edit'></mat-icon></button>
                    <!-- Delete Button -->
                    <button mat-icon-button [disabled]="!canEdit" (click)='deleteNote(element)' class='mat-small-icon'><mat-icon class='icon-bin'></mat-icon></button>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              <mat-paginator
                (page)="pageChanged($event)"
                [length]='companyNotes.length'
                [pageSize]="itemsPerPage"
                [pageIndex]='currentPage'
                [disabled]='companyNotes.length <= itemsPerPage'
                [hidePageSize]='true'>
              </mat-paginator>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <ng-template #noNotes>
        <div class='row mt--md'>
          <div class='column--12 align-center'>
            <h3>No company notes found.</h3>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
