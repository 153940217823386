<h1>Create Invoice</h1>
<ng-container *ngIf='!loading && company, else isLoading'>
  <form [formGroup]="invoiceForm">
    <div class='row'>
      <div class='column--8'>
        <mat-form-field class="full-width">
          <mat-label>Invoice Date</mat-label>
          <input matInput formControlName="customInvoiceCreatedAtDate" [matDatepicker]="customInvoiceCreatedAtDatePicker">
          <mat-datepicker-toggle matIconSuffix [for]="customInvoiceCreatedAtDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #customInvoiceCreatedAtDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--8'>
        <mat-form-field class="full-width">
          <mat-label>Invoice number</mat-label>
          <input matInput type="text" formControlName="invoiceNumber">
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--8'>
        <mat-form-field class="full-width">
          <mat-label>Due Date</mat-label>
          <input matInput formControlName="dueDate" [matDatepicker]="dueDatePicker">
          <mat-datepicker-toggle matIconSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--8'>
        <mat-form-field class="full-width">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class='row mb--md'>
      <div class='column--8'>
        <mat-checkbox formControlName='applyCreditCardFee'>Apply 3% credit card fee</mat-checkbox>
      </div>
    </div>
    <div class='row'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='create()' [disabled]='invoiceForm.invalid || range.invalid'>Save</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
