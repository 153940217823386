import { Component, Inject, OnInit } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ShipmentsService } from '@app/shared/services/shipments.service';
import { UserService } from '@app/shared/services/user.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { DateFormats, ShipmentStatus, ShippingProviders, } from '@app/shared/constants';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
})
export class TrackingComponent implements OnInit {
  public shipment: any;
  public company: Company;
  public loading: boolean = true;
  public errors: Array<any> = [];
  public trackingData: any;
  public trackingNumber: any;

  public courierUrl: any;
  public doNotShowCourierUrl: boolean = false;
  public lastLocation: any;
  public currentStatus: ShipmentStatus;
  public displayedTrackingColumns: string[] = [
    'date',
    'progress',
    'description',
  ];

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private shipmentsService: ShipmentsService,
    private userService: UserService,
    public drawerRef: MtxDrawerRef<TrackingComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { company: Company; trackingNumber: any; shipment: any }
  ) {}
  ngOnInit() {
    if (
      this.data &&
      this.data.shipment &&
      this.data.trackingNumber &&
      this.data.company
    ) {
      // Do not show external courier URL for AC-Spring shipment
      this.doNotShowCourierUrl = (this.data.trackingNumber || '').includes('MASP') && this.data.shipment.provider === ShippingProviders.RIVO;
      this.trackingNumber = this.data.trackingNumber;
      this.shipment = this.data.shipment;
      this.company = this.data.company;
      this.getTracking();
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  private getTracking() {
    this.loading = true;
    const query = {
      id: this.trackingNumber,
      shipmentId: this.shipment.id,
      companyId: this.company.id,
    };

    this.shipmentsService.tracking(this.shipment.provider, query).subscribe(
      (res: any) => {
        this.logger.log('GET tracking', res);
        this.courierUrl = _.get(res, 'courierUrl', undefined);

        if (res.errors.length > 0) {
          this.errors = res.errors;
        } else if (res.data[0]) {
          this.trackingData = _.cloneDeep(res.data[0]);
          this.currentStatus = this.getStatus();

          // Store the last location to prevent changing its value when sorting events
          if (
            [
              // TODO: Legacy provider. Remove once old shipment data archived (4266)
              ShippingProviders.COURANT_PLUS,

              ShippingProviders.NATIONEX,
              ShippingProviders.EVA
            ].includes(this.shipment.provider as ShippingProviders)
          ) {
            this.lastLocation = _.get(this.trackingData, 'location');
          } else {
            this.lastLocation = _.get(
              this.trackingData,
              'events[0].location',
              null
            );
          }

          if (_.get(this.trackingData, 'noConvert')) {
            if (this.trackingData.expected)
              this.trackingData.expected = this.formatTimestampUTC(
                this.trackingData.expected
              );
            if (this.trackingData.delivery)
              this.trackingData.delivery = this.formatTimestampUTC(
                this.trackingData.delivery
              );

            _.forEach(this.trackingData.events, (event, index: number) => {
              event.date = event.timestamp
                ? this.formatTimestampUTC(
                    event.timestamp,
                    DateFormats.DATE_YEAR_SML
                  )
                : 'N/A';
              event.time = event.timestamp
                ? this.formatTimestampUTC(event.timestamp, DateFormats.TIME)
                : 'N/A';
              event.circleClass = this.getCircleClass(index);
            });
          } else {
            if (this.trackingData.expected)
              this.trackingData.expected = this.formatTimestamp(
                this.trackingData.expected
              );
            if (this.trackingData.delivery)
              this.trackingData.delivery = this.formatTimestamp(
                this.trackingData.delivery
              );

            _.forEach(this.trackingData.events, (event, index: number) => {
              // For multileg shipment tracking, some provider events shouldn't be converted, while others should
              if (event.noConvert) {
                event.date = event.timestamp
                  ? this.formatTimestampUTC(
                      event.timestamp,
                      DateFormats.DATE_YEAR_SML
                    )
                  : 'N/A';
                event.time = event.timestamp
                  ? this.formatTimestampUTC(event.timestamp, DateFormats.TIME)
                  : 'N/A';
              } else {
                event.date = event.timestamp
                  ? this.formatTimestamp(
                      event.timestamp,
                      DateFormats.DATE_YEAR_SML
                    )
                  : 'N/A';
                event.time = event.timestamp
                  ? this.formatTimestamp(event.timestamp, DateFormats.TIME)
                  : 'N/A';
              }
              event.circleClass = this.getCircleClass(index);
            });
          }
        }
        this.loading = false;
      },
      (err: any) => {
        this.logger.error('GET shipments error', err);
        if (_.get(err, ['error', 'errors']) && err.error.errors.length > 0) {
          this.errors = err.error.errors;
        }
        this.loading = false;
      }
    );
  }

  private getCircleClass(index: number): string {
    const eventLength = this.trackingData.events.length;
    const classes: string[] = ['progress-circle'];
    // use 'latest' class even if only one element in tracking
    if (index === 0) {
      classes.push('latest');
    } else if (index + 1 === eventLength) {
      classes.push('first');
    }
    return classes.join(' ');
  }

  private getStatus(): ShipmentStatus {
    let status;

    switch (this.trackingData.status) {
      case 4:
        status = ShipmentStatus.PARTIALLY_DELIVERED;
        break;

      case 3:
        status = ShipmentStatus.DELIVERED;
        break;

      case 2:
        status = ShipmentStatus.LOCALBRANCH;
        break;

      case 1:
        status = ShipmentStatus.INTRANSIT;
        break;

      case -1:
        status = ShipmentStatus.TRACK_ERROR;
        break;

      default:
        status = ShipmentStatus.SHIPMENT_INFO_RECEIVED;
        break;
    }
    return status;
  }

  private formatTimestamp(timestamp, format = DateFormats.DELIVERY_DATE_TIME) {
    const dateTimeObj = this.getValidDateTime(timestamp, false);
    if (
      dateTimeObj.hour === 0 &&
      dateTimeObj.minute === 0 &&
      dateTimeObj.second === 0
    ) {
      return dateTimeObj.toFormat(DateFormats.DATE);
    } else {
      return dateTimeObj.toFormat(format);
    }
  }

  private formatTimestampUTC(
    timestamp,
    format = DateFormats.DELIVERY_DATE_TIME
  ) {
    const dateTimeObj = this.getValidDateTime(timestamp);
    if (
      dateTimeObj.hour === 0 &&
      dateTimeObj.minute === 0 &&
      dateTimeObj.second === 0
    ) {
      return dateTimeObj.toFormat(DateFormats.DATE);
    } else {
      return dateTimeObj.toFormat(format);
    }
  }

  private getValidDateTime(timestamp, isUtc = true): DateTime {
    const opts = isUtc ? {} : { zone: 'local' };
    return this.helpersService.getValidDateTime(timestamp, opts);
  }
}
