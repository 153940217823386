import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { InvoiceDocumentsComponent } from './invoice-documents.component';

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    SharedModule,
  ],
  declarations: [
    InvoiceDocumentsComponent,
  ]
})
export class InvoiceDocumentsModule { }
