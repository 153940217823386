<ng-lottie
  #ngLottieAnimation,
  containerClass="{{ cssClasses }}"
  width="{{width}}px"
  height="{{height}}px"
  [options]="animationSettings"
  (animationCreated)="animationCreated($event)"
  (mouseover)="playAnimation()"
  (mouseleave)="stopAnimation()"
></ng-lottie>
