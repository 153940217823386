import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-svg-animation',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [LottieComponent],
  templateUrl: './svg-animation.component.html',
  styleUrls: ['./svg-animation.component.scss'],
})
export class SvgAnimationComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() cssClasses: string;
  @Input() path: string;
  @Input() transparent: boolean = true;
  @Input() autoplay: boolean = true;
  @Input() loop: boolean = true;
  @Input() playOnHover: boolean = false;

  @Output() animationPlaying: EventEmitter<boolean> = new EventEmitter();

  public animationSettings: AnimationOptions = { renderer: 'svg' };

  public lottieAnimation: AnimationItem;

  constructor() {}

  ngOnInit() {
    this.animationSettings = {
      ...this.animationSettings,
      loop: this.loop,
      autoplay: this.playOnHover ? false : this.autoplay,
      path: this.path,
    };
    if (this.transparent) {
      this.animationSettings = {
        ...this.animationSettings,
        rendererSettings: {
          hideOnTransparent: false,
        },
      };
    }
  }

  public animationCreated(animationItem: any) {
    this.lottieAnimation = animationItem;
  }

  public playAnimation() {
    if (this.playOnHover && this.lottieAnimation) {
      this.lottieAnimation.play();
      this.animationPlaying.emit(true);
    }
  }
  public stopAnimation() {
    if (this.playOnHover && this.lottieAnimation) {
      this.lottieAnimation.stop();
      this.animationPlaying.emit(false);
    }
  }
}
