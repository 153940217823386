<ng-container *ngIf='!loading, else isLoading'>
  <div class='row mt--lg'>
    <div class='column--12 column-lg--8 column-xxl--5'>
      <mat-form-field class='w-100' *ngIf="supportedAirports.length > 1">
        <mat-label>Select airport</mat-label>
        <mat-select name='airportCode' [(value)]='selectedAirport' (selectionChange)='getManifests()'>
          <mat-option *ngFor='let airport of supportedAirports' value="{{ airport.code }}">{{ airport.code }} ({{airport.name}})</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class='container ml--none mt--sm' *ngIf='selectedAirport'>
    <ng-container *ngIf='!loadingManifests, else isLoadingManifests'>
      <ng-container *ngIf='manifests && manifests.length > 0, else noManifests'>
        <mat-card class='mat-elevation-z0'>
          <mat-card-content class='pt--none'>
            <div class='row'>
              <div class='column--12'>
                <div class='responsive-table'>
                  <table mat-table [dataSource]="manifests" class="mat-elevation-z0">
                    <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef style='width: 15%'>Date</th>
                      <td mat-cell class='text--sm' *matCellDef="let element" notranslate>
                        {{ element.createdAt | timeToString : DATE_FORMAT : 'America/Toronto' }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef style='width: 20%'>ID</th>
                      <td mat-cell class='text--sm' *matCellDef="let element" notranslate>
                        {{ element.manifestNumber }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="airport">
                      <th mat-header-cell *matHeaderCellDef style='width: 15%'>Airport Code</th>
                      <td mat-cell class='text--sm' *matCellDef="let element" notranslate>
                        {{ this.selectedAirport }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="shipments">
                      <th mat-header-cell *matHeaderCellDef style='width: 15%'># of shipments</th>
                      <td mat-cell *matCellDef="let element" notranslate>
                        {{ element.totalShipments }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell class='text--sm' class='align-right' *matCellDef="let manifest">
                        <a mat-button color='primary' class='mr--md' (click)='viewManifest(manifest)'>View</a>
                        <a mat-button color='primary' (click)='downloadManifest(manifest)'>Download</a>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
      <ng-template #noManifests>
        <div class='row justify-content-center'>
          <div class='column--12 align-center'>
            <div style='width: 220px; margin: 0 auto;'>
              <img src='{{ CDN_URL }}/admin/images/empty-history_animated.svg' />
              <p>No manifests found for this location</p>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #isLoadingManifests>
      <div class='align-center mt--lg'>
        <app-loading></app-loading>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
