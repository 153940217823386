import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import * as _ from 'lodash';

@Component({
  selector: 'app-canada-post-receipt',
  templateUrl: './canada-post-receipt.component.html'
})
export class CanadaPostReceiptComponent implements OnInit {
  public shipment: any;
  public receiptInfo: any;

  public receiptDetails: Array<Array<{
    label: string,
    value: string,
  }>>;

  constructor(
    public drawerRef: MtxDrawerRef<CanadaPostReceiptComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { shipment: any },
  ) { }

  ngOnInit() {
    if (this.data && this.data.shipment) {
      this.shipment = this.data.shipment;
      this.receiptInfo = _.get(this.shipment, 'rates.initialCharge.metadata.receiptInfo');
      this.receiptDetails = _.map(this.receiptInfo, (detailGroup) => {
        return this.constructReceipt(detailGroup);
      });
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  private constructReceipt(info): any {
    return _.map(info, (detail: string, key: string) => {
      return {
        label: this.getReceiptLabel(_.snakeCase(key).toUpperCase()), // for ngx-translate,
        value: detail
      };
    });
  }

  private getReceiptLabel(labelKey: string): string {
    switch(labelKey) {
      case 'TRANSACTION_TYPE':
        return 'Transaction type /<br>Type de transaction:';
      case 'CARD_TYPE':
        return 'Card type / Type de carte:';
      case 'NAME_ON_CARD':
        return 'Name on card /<br>Nom sur la carte:';
      case 'SUB_TOTAL':
        return 'Order subtotal /<br>Total partiel de la commande:';
      case 'GST':
        return 'GST / TPS (#119321495):';
      case 'PST':
        return 'PST / TVP:';
      case 'HST':
        return 'HST / TVH:';
      case 'TOTAL_AMOUNT':
        return 'Total transaction amount /<br>Montant total de la transation:';
      case 'AUTHORIZATION_CODE':
        return 'Authorization code /<br>Code d\'autorisation:';
      case 'DATE':
        return 'Date and time /<br>Date et heure:';
      case 'MERCHANT_NAME':
        return 'Merchant name /<br>Nom du commerçant:';
      case 'MERCHANT_ONLINE_ADDRESS':
        return 'Merchant online address /<br>Adresse web du commerçant:';
      case 'ERROR':
        return 'There was an error retrieving your receipt';
      default:
        return '';
    }
  }
}
