<ng-container>
  <form [formGroup]="searchForm">
    <mat-card [ngClass]="{'mb--sm': paginatedAdjustments.length == 0 }">
      <mat-card-content class='pb--none'>
        <div class='row'>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Status</mat-label>
              <mat-select formControlName="chargeStatus" name='status'>
                <mat-option *ngFor='let status of statusItems' value="{{ status.id }}">{{ status.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Courier</mat-label>
              <mat-select formControlName="provider" name='providerItem'>
                <mat-option *ngFor='let providerItem of providerItems' value="{{ providerItem.id }}">{{ providerItem.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Tracking number #</mat-label>
              <input matInput formControlName="trackingNumber" name="trackingNumber" type="text" maxlength="30">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--8 align-right mt--xs'>
            <button mat-button color='primary' type='button' (click)='reset()' class='mr--md' [disabled]="searchForm.invalid || loading">Clear all</button>
            <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <ng-container *ngIf="progress !== 0 && progress !== 100">
    <div class="row">
      <p class='text-larger mt--md'>Approving all adjustments…</p>
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
    </div>
  </ng-container>
  <ng-container *ngIf='!loading, else isLoading'>
    <ng-container *ngIf="!loading && companyAdjustments.length > 0, else noAdjustments">
      <div class='column--12 column-md--6 align-left'>
        <button mat-flat-button color='primary' type='submit' class='mr--sm my--md' [disabled]="approveAllInProgress || !canEdit" (click)="approveAll()">Approve all</button>
      </div>
      <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
        <mat-card-content>
          <div class='responsive-table'>
            <table mat-table [dataSource]="paginatedAdjustments" multiTemplateDataRows>
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style='width: 5%'></th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <button
                  mat-icon-button
                  color='primary'
                  class='mat-small-icon mx--xs'
                  (click)='toggleAdjustmentDetails(element)'
                ><mat-icon [ngClass]="element === expandedElement ? 'icon-minus' : 'icon-plus'"></mat-icon></button>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef style='width: 25%'>Name</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style='width: 25%'>Email Address</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.account.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="shipments">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'># Shipments</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.shipmentsCount }}
                </td>
              </ng-container>
              <ng-container matColumnDef="totalAdjustment">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'>TOTAL ADJUSTMENT</th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  {{ element.totalAdjustmentsAmount | currency : 'CAD' : 'symbol-narrow' }}
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style='width: 15%'></th>
                <td mat-cell *matCellDef="let element" class='text--sm'>
                  <span>
                    <button
                    mat-icon-button
                    color='primary'
                    class='mat-small-icon mx--xs'
                    mtxTooltip="Approve"
                    mtxTooltipPosition="above"
                    mtxTooltipClass="mtx-recon-tooltip"
                    *ngIf="element.canApproveBatchAdjustments && !approveAllInProgress"
                    [disabled]="!canEdit"
                    (click)="approveBatchRecon(element)"><mat-icon class='icon-check'></mat-icon></button>
                  </span>
                  <span>
                    <button
                    mat-icon-button
                    color='primary'
                    class='mat-small-icon mx--xs'
                    mtxTooltip="Ignore"
                    mtxTooltipPosition="above"
                    mtxTooltipClass="mtx-recon-tooltip"
                    *ngIf="element.canApproveBatchAdjustments && !approveAllInProgress"
                    [disabled]="!canEdit"
                    (click)="ignoreBatchRecon(element)"
                  ><mat-icon class='icon-ignore'></mat-icon></button>
                  </span>
                </td>
              </ng-container>
              <!-- Adjustment Details -->
              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="expanded-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                    <table mat-table [dataSource]="element.shipments" multiTemplateDataRows matSort>
                      <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef style='width: 5%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          <button
                          mat-icon-button
                          color='primary'
                          class='mat-small-icon mx--xs'
                          (click)='toggleShipmentDetails(row)'
                        ><mat-icon [ngClass]="row === expandedShipmentElement ? 'icon-minus' : 'icon-plus'"></mat-icon></button>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>Date</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ row.createdAt | date: 'E, d MMM' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="provider">
                        <th mat-header-cell *matHeaderCellDef style='width: 15%'>Provider</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getProviderName(row.provider) }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="paymentType">
                        <th mat-header-cell *matHeaderCellDef style='width: 12%'>payment type</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getPaymentType(row.billingType.toUpperCase()) }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>price</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ row.rates.initialCharge.amount | currency : 'CAD' : 'symbol-narrow' }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustment">
                        <th mat-header-cell *matHeaderCellDef style='width: 10%'>adjustment</th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          {{ getAdjustmentValue(row) | currency : 'CAD' : 'symbol-narrow' }}
                          <mat-icon *ngIf="row.isPreviouslyDeclined" [matTooltip]="'Previously Declined'" mtxTooltipPosition="above" class="icon-info mat-icon-sm text--table-header ml--xs"></mat-icon>
                          <ng-template #popoverMergedShipments>
                            <div>
                              <div class="text--sm text-gray-optional">Parent shipment:</div>
                              <div class="mb-3 text--sm text-gray-dark">{{ row.tracking }}</div>
                              <div class="text--sm text-gray-optional">Children shipments:</div>
                              <div class="text--sm text-gray-dark">{{ row.mergedShipmentPopover }}</div>
                            </div>
                          </ng-template>
                          <mat-icon
                            class="icon-merge mat-icon-sm text--table-header ml--xs"
                            *ngIf="row.isMergedShipment"
                            [mtxTooltip]="popoverMergedShipments"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip">
                          </mat-icon>
                          <ng-template #popoverAssignedUnknownShipments>
                            <div class="text--sm text-gray-optional">Original tracking number</div>
                            <div class="row">
                              <div class="column-md--2">
                                <img [src]="row.providerImage" width="25px" height="25px"/>
                              </div>
                              <div class="column-md--7 mt--xxs text-gray-dark text--sm">
                                <span>{{ row.tracking }}</span>
                              </div>
                            </div>
                            <div class="text--sm text-gray-optional">New (assigned) tracking number</div>
                            <div class="row">
                              <div class="column-md--2">
                                <img [src]="row.providerImage" width="25px" height="25px"/>
                              </div>
                              <div class="column-md--7 mt--xxs text-gray-dark text--sm">
                                <span>{{ row.assignedUnknownShipmentPopover }}</span>
                              </div>
                            </div>
                          </ng-template>
                          <mat-icon
                          class="icon-assign mat-icon-sm text--table-header ml--xs "
                          *ngIf="row.hasAssignedUnknownShipment"
                          [mtxTooltip]="popoverAssignedUnknownShipments"
                          mtxTooltipPosition="above"
                          mtxTooltipClass="mtx-recon-tooltip">
                        </mat-icon>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustmentDetails">
                        <th mat-header-cell *matHeaderCellDef style='width: 25%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm p--md'>
                          {{ row.adjustmentReasons }}
                          <mat-icon *ngIf="row.isMissingOrUnknownReason" [mtxTooltip]="getMissingOrUnknownReasonPopoverMessage(row)" mtxTooltipPosition="above" mtxTooltipClass="mtx-recon-tooltip" class="icon-info mat-icon-sm text--danger"></mat-icon>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="adjustmentAction">
                        <th mat-header-cell *matHeaderCellDef style='width: 12%'></th>
                        <td mat-cell *matCellDef="let row" class='text--sm'>
                          <ng-container *ngIf="!approveAllInProgress && !row.isMissingOrUnknownReason">
                            <button
                            *ngIf="row.canApproveAdjustment"
                            mat-icon-button
                            color='primary'
                            class='mat-small-icon mx--xs'
                            mtxTooltip="Approve"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip"
                            [disabled]="!canEdit"
                            (click)="approveRecon(element, row)"><mat-icon class='icon-check'></mat-icon></button>
                            <button
                            *ngIf="row.canIgnoreAdjustment"
                            mat-icon-button
                            color='primary'
                            class='mat-small-icon mx--xs'
                            mtxTooltip="Ignore"
                            mtxTooltipPosition="above"
                            mtxTooltipClass="mtx-recon-tooltip"
                            [disabled]="!canEdit"
                            (click)="ignoreRecon(element, row)"><mat-icon class='icon-ignore'></mat-icon></button>
                          </ng-container>
                          <ng-container *ngIf="row.isMissingOrUnknownReason && !approveAllInProgress">
                            <button mat-icon-button [matMenuTriggerFor]="reconMenu" aria-label="Approve actions" color='primary' [disabled]="!canEdit">
                              <mat-icon>more_horiz</mat-icon>
                            </button>
                            <mat-menu #reconMenu="matMenu">
                              <!-- Preview Button -->
                              <button mat-menu-item (click)="reassignOther(element, row)">
                                <span>Assign “Other” to known charge types</span>
                              </button>
                              <!-- Add Payment Button -->
                              <button mat-menu-item class='border-top' (click)="deleteOtherAndRecalculateAdjustment(element, row)">
                                <span class="text--danger">Remove Other</span>
                              </button>
                              <!-- Add Payment Button -->
                              <button mat-menu-item class='border-top' (click)="ignoreRecon(element, row)">
                                <span class="text--danger">Ignore the entire adjustment</span>
                              </button>
                            </mat-menu>
                          </ng-container>
                          <ng-template #processing>
                            <app-loading [isInline]="true"></app-loading>
                          </ng-template>
                        </td>
                      </ng-container>

                      <!-- Shipment Details -->
                      <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let shipment" [attr.colspan]="displayedNestedColumns.length">
                          <div class="expanded-detail" [@detailExpand]="shipment === expandedShipmentElement ? 'expanded' : 'collapsed'">
                            <div class='row mt--md mb--md p--sm'>
                              <div class='column--12'>
                                <shipment-details [user]='user' [shipment]='shipment'></shipment-details>
                              </div>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedNestedColumns"></tr>
                      <tr [ngClass]="{'text-danger': row.isMissingOrUnknownReason || getAdjustmentValue(row) < 0}" mat-row *matRowDef="let row; columns: displayedNestedColumns;"></tr>
                      <tr style="height: 0" mat-row *matRowDef="let shipment; columns:['expandedDetail']"></tr>
                    </table>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
              <tr style="height: 0" mat-row *matRowDef="let row; columns:['expandedDetail']"></tr>
            </table>
          </div>

          <mat-paginator
          (page)="pageChanged($event)"
          [length]='companyAdjustmentsCount'
          [pageSize]="itemsPerPage"
          [pageIndex]='currentPage - 1'
          [hidePageSize]='true'>
        </mat-paginator>
        </mat-card-content>
      </mat-card>
    </ng-container>
    <ng-template #noAdjustments>
      <div class='row justify-content-center'>
        <div class='column--12 align-center'>
          <div style='width: 220px; margin: 0 auto;'>
            <img src='{{ CDN_URL }}/admin/images/empty-history_animated.svg' />
            <p>No Adjustments found</p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #isLoading>
    <div class='align-center mt--lg'>
      <app-loading></app-loading>
    </div>
  </ng-template>
</ng-container>
