<ng-container *ngIf='!loading && company && invoice, else isLoading'>
  <div class='row'>
    <div class='column--4'>
      <h5 class='my--xxs text--table-header'>Invoice number</h5>
      <div>{{ invoice.invoiceNumber }}</div>
    </div>
    <div class='column--4'>
      <h5 class='my--xxs text--table-header'>Outstanding balance</h5>
      <div>{{ invoice.balanceDue | currency }}</div>
    </div>
    <div class='column--4'>
      <h5 class='my--xxs text--table-header'>Due</h5>
      <div>{{ invoice.formattedDueDate }}</div>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
