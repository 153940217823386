<h2 mat-dialog-title class="my--xs">{{ title }}</h2>
<mat-dialog-content class="mat-typography mb--xs">
  <p class="mb--lg">{{ message }}</p>

  <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
    <mat-card-content>
        <table mat-table [dataSource]="shipmentDataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style="width: 5%;">
              <mat-checkbox class="full-width" color='primary' (change)="selectAll($event.checked)" [checked]="selectedAll">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let shipment">
              <mat-checkbox class="full-width" color='primary' (change)="selectAdjustment(shipment, $event.checked)" [checked]="shipment.selected">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef style="width: 12%;">Date</th>
            <td mat-cell *matCellDef="let shipment"> {{shipment.createdAt | date: 'E, d MMM' }} </td>
          </ng-container>

          <ng-container matColumnDef="provider">
            <th mat-header-cell *matHeaderCellDef style="width: 18%;"> Provider </th>
            <td mat-cell *matCellDef="let shipment"> {{shipment.provider}} </td>
          </ng-container>

          <ng-container matColumnDef="paymentType">
            <th mat-header-cell *matHeaderCellDef style="width: 15%;"> Payment type </th>
            <td mat-cell *matCellDef="let shipment"> {{ getPaymentType(shipment.billingType.toUpperCase()) }} </td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef style="width: 10%;"> Price </th>
            <td mat-cell *matCellDef="let shipment"> {{ shipment.rates.initialCharge.amount | currency : 'CAD' : 'symbol-narrow' }} </td>
          </ng-container>

          <ng-container matColumnDef="adjustment">
            <th mat-header-cell *matHeaderCellDef style="width: 10%"> Adjustment </th>
            <td mat-cell *matCellDef="let shipment"> {{ shipment.adjustmentValue | currency : 'CAD' : 'symbol-narrow' }}
              <mat-icon *ngIf="shipment.isPreviouslyDeclined" [matTooltip]="'Previously Declined'" matTooltipPosition="above" class="icon-info mat-icon-sm text--table-header ml--xs"></mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="adjustmentDetails">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let shipment" class="nobr reasons" [matTooltip]="shipment.adjustmentReasons" matTooltipPosition="left"> {{ shipment.adjustmentReasons }}
            </td>
          </ng-container>

          <tr mat-header-row style="background-color: #efefef;" *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="selection.toggle(row)">
          </tr>
        </table>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>

<div class='row' mat-dialog-actions>
  <div class='column--3'>
    <button
      mat-flat-button
      mat-dialog-close
      color='accent'
    >Cancel</button>
  </div>
  <div class='column--9 align-right'>
    <button
      mat-flat-button
      [color]="template"
      (click)="confirm()"
      [disabled]="shipmentsSelected.length === 0"
    >{{ action }}</button>
  </div>
</div>