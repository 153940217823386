<ng-container *ngIf='!loading, else isLoading'>
  <div class='container ml--none'>
    <div class='row justify-content-center'>
      <div class='column--6'>
        <mat-form-field>
          <mat-select
            name='dashboardZone'
            [value]='selectedDashboard ? selectedDashboard.id : undefined'
            (selectionChange)='selectDashboard($event)'
            [disabled]='!canEdit'
          >
            <mat-option *ngFor='let option of dashboardOptions' value="{{ option.id }}">{{ option.text }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button type='button' color='accent' class='btn-utility text--sm ml--md' (click)='addDashboard()' [disabled]='!hasZonesAvailable || !canEditZone'>
          <mat-icon class='icon-plus mat-icon-sm'></mat-icon>
          Add province
        </button>
      </div>
      <div class='column--6 align-right with-gutter pr--md'>
        <button mat-flat-button type='button' color='accent' class='btn-utility text--sm mt--xs' (click)='addDashboardCard()' [disabled]='!canEditCards'>
          <mat-icon class='icon-plus mat-icon-sm'></mat-icon>
          Add new card
        </button>
      </div>
    </div>
    <ng-container *ngIf="currentDashboard && currentDashboard.id">
      <div class='row mt--lg'>
        <div class='column--12 column-md--4'>
          <mat-form-field class='w-100'>
            <mat-select
              name='cardLeftSelected'
              placeholder='Select card (Left)'
              [value]='(selectedLeftCard && selectedLeftCard.id ? selectedLeftCard.id : 0) | number'
              (selectionChange)="selectDashboardCard('cardLeft', $event)"
              [disabled]='!canEdit'
            >
              <mat-option *ngFor='let option of dashboardCardOptions' value="{{ option.id }}">{{ option.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4'>
          <mat-form-field class='w-100'>
            <mat-select
              name='cardCenterSelected'
              placeholder='Select card (Center)'
              [value]='(selectedCenterCard && selectedCenterCard.id ? selectedCenterCard.id : 0) | number'
              (selectionChange)="selectDashboardCard('cardCenter', $event)"
              [disabled]='!canEdit'
            >
              <mat-option *ngFor='let option of dashboardCardOptions' value="{{ option.id }}">{{ option.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4 with-gutter'>
          <mat-form-field class='w-100'>
            <mat-select
              name='cardRightSelected'
              placeholder='Select card (Right)'
              [value]='(selectedRightCard && selectedRightCard.id ? selectedRightCard.id : 0) | number'
              (selectionChange)="selectDashboardCard('cardRight', $event)"
              [disabled]='!canEdit'
            >
              <mat-option *ngFor='let option of dashboardCardOptions' value="{{ option.id }}">{{ option.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class='row mt--sm'>
        <div class='column--12 column-md--4'>
          <app-dashboard-card
            [defaultCard]='currentDashboard.defaultCardLeft'
            [card]='currentDashboard.cardLeft'
            (editCardRequested)='editDashboardCard($event)'
            (addCardRequested)="addDashboardCard('cardLeft')"
          ></app-dashboard-card>
        </div>
        <div class='column--12 column-md--4'>
          <app-dashboard-card
            [defaultCard]='currentDashboard.defaultCardCenter'
            [card]='currentDashboard.cardCenter'
            (editCardRequested)='editDashboardCard($event)'
            (addCardRequested)="addDashboardCard('cardCenter')"
          ></app-dashboard-card>
        </div>
        <div class='column--12 column-md--4 with-gutter'>
          <app-dashboard-card
            [defaultCard]='currentDashboard.defaultCardRight'
            [card]='currentDashboard.cardRight'
            (editCardRequested)='editDashboardCard($event)'
            (addCardRequested)="addDashboardCard('cardRight')"
          ></app-dashboard-card>
        </div>
      </div>
      <div class='row mt--md'>
        <div class='column--12 align-right pr--md'>
          <button mat-flat-button color='primary' (click)='updateDashboard()' [disabled]='!canEditZone'>Save changes</button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
