import * as _ from 'lodash';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { formatCurrency } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Decimal from 'decimal.js';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { BillingTypes, BillingTypesVerbose } from '../../../../../../shared/constants';

@Component({
  selector: 'modals-batch-adjustments',
  templateUrl: './modals-batch-adjustments.component.html',
  styleUrls: ['./modals-batch-adjustments.component.scss']
})
export class ModalsBatchAdjustmentsComponent implements OnInit {
  public template: string;
  public title: string;
  public action: string;
  public companyAdjustment: any;
  public message: string;
  public selectedAll: boolean = true;
  public shipmentsSelected;
  public result: any[];
  public displayedColumns: string[] = ['select', 'date', 'provider', 'paymentType', 'price', 'adjustment', 'adjustmentDetails'];
  public shipmentDataSource: any;
  public selection = new SelectionModel<any>(true, []);

  constructor(
    private dialogRef: MatDialogRef<ModalsBatchAdjustmentsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      template: string;
      action: string;
      companyAdjustment: any;
    },
  ) {
    this.title = this.data.title;
    this.template = this.data.template;
    this.action = this.data.action;
    this.companyAdjustment = this.data.companyAdjustment;
  }

  ngOnInit(): void {
    this.shipmentsSelected = _.cloneDeep(this.companyAdjustment.shipments);
    this.shipmentDataSource = new MatTableDataSource(this.shipmentsSelected);
    this.result = [];
    this.updateMessage();
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.shipmentDataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.shipmentDataSource.data);
    this.updateMessage();
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      this.updateMessage();
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    this.updateMessage();
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  public selectAll(checked: boolean): void {
    this.selectedAll = checked;
    if (this.selectedAll) {
      this.companyAdjustment.shipments.forEach((shipment) => shipment.selected = true);
      this.shipmentsSelected = _.cloneDeep(this.companyAdjustment.shipments);
      this.shipmentDataSource = this.shipmentsSelected;
    } else {
      this.companyAdjustment.shipments.forEach((shipment) => shipment.selected = false);
      this.shipmentDataSource = this.companyAdjustment.shipments;
      this.shipmentsSelected = [];
    }

    this.updateMessage();
  }

  public selectAdjustment(shipment, checked: boolean) {
    shipment.selected = checked;
    if (shipment.selected) {
      this.shipmentsSelected.push(shipment);
    } else {
      this.shipmentsSelected = this.shipmentsSelected.filter((s) => s.id !== shipment.id);
    }

    this.updateMessage();
    this.selectedAll = this.companyAdjustment.shipments.length === this.shipmentsSelected.length;
  }

  public confirm(): void {
    this.result = this.shipmentsSelected.map((shipment) => shipment.id);
    this.dialogRef.close(this.result);
  }

  private updateMessage(): void {
    let cumulativeValue = 0;

    for (const shipment of this.shipmentsSelected) {
      cumulativeValue =  new Decimal(cumulativeValue || 0)
      .plus(shipment.adjustmentValue || 0)
      .toDecimalPlaces(2)
      .toNumber();
    }

    const totalAdjustmentsAmount = formatCurrency(cumulativeValue, 'en', '$', 'CAD');
    const shipmentsCount = this.shipmentsSelected.length;
    this.message = `${this.action} adjustment of ${totalAdjustmentsAmount} across ${shipmentsCount} shipment(s) for ${this.companyAdjustment.displayName}?`;
  }

  public getPaymentType(billingType: BillingTypes): BillingTypesVerbose {
    switch (billingType) {
      case BillingTypes.MACHOOL_ACCOUNT:
        return BillingTypesVerbose.MACHOOL_ACCOUNT;
      default:
        return BillingTypesVerbose.CREDIT_CARD;
    }
  }
}
