<div *ngIf='!loading, else isLoading'>
  <div class='container'>
    <div class='row'>
      <div class='column--12 align-center'>
        <h1>Dashboard for {{ user.firstName }}</h1>
      </div>
    </div>
    <div class='row'>
      <div class='column--6 align-center'>Half width in a custom grid</div>
      <div class='column--6 align-center'>Half width in a custom grid</div>
    </div>
    <div class='row'>
      <div class='column--4 align-center'>1/3 width in a custom grid</div>
      <div class='column--4 align-center'>1/3 width in a custom grid</div>
      <div class='column--4 align-center'>1/3 width in a custom grid</div>
    </div>
  </div>
</div>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
