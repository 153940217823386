import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggerService } from '@app/shared/services/logger.service';

export interface NotificationLogMessage {
  category: string;
  message: any;
}

@Injectable({ providedIn: 'root' })
export class BannerNotificationsService {
  constructor(private snackBar: MatSnackBar, private logger: LoggerService) {}

  public success(message: string, log?: NotificationLogMessage): void {
    this.snackBar.open(message, '', {
      panelClass: 'success',
    });
    if (log?.message) {
      this.logger.info(log?.category, log?.message);
    }
  }

  public error(message: string, log?: NotificationLogMessage): void {
    this.snackBar.open(message, '', {
      panelClass: 'error',
    });
    if (log?.message) {
      this.logger.error(log?.category, log?.message);
    }
  }
}
