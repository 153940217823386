import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { HelpersService } from '@app/shared/services/helpers.service';
import { catchError } from 'rxjs';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private firebaseAuth: AngularFireAuth,
    private helpersService: HelpersService,
    private sessionStorageService: SessionStorageService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const currentUser = this.sessionStorageService.retrieve('currentUser');
    if (!currentUser || !req.url.includes('api/v1')) return next.handle(req);
    if (req.headers.get('authorization') || req.headers.get('Authorization'))
      return next.handle(req);

    // Automatically add in the auth headers to the internal api calls
    const headers = this.helpersService.getAuthHeaders();
    const clonedReq = req.clone({
      headers: req.headers
        .set('Authorization', headers.authorization)
        .set('uid', headers.uid),
    });

    return next.handle(clonedReq).pipe(
      catchError((response): any => {
        if (response instanceof HttpErrorResponse) {
          // Log out of the app if you aren't authenticated by the get current call
          if (response.status === 401) {
            this.firebaseAuth.signOut().then(() => {
              this.router.navigate(['login']);
            });
          } else if (response.status === 403) {
            this.router.navigate(['home']);
          }
        }
      })
    );
  }
}
