import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { FinanceComponent } from '@app/pages/authenticated/pages/finance/finance.component';
import { CourierInvoicesComponent } from '@app/pages/authenticated/pages/finance/components/courier-invoices/courier-invoices.component';
import { InvoiceShipmentsComponent } from '@app/pages/authenticated/pages/finance/components/invoice-shipments/invoice-shipments.component';
import { ShipmentDetailsComponent } from '@app/pages/authenticated/components/shipment-details/shipment-details.component';
import { NotInvoicedCourierComponent } from '@app/pages/authenticated/pages/finance/components/not-invoiced-courier/not-invoiced-courier.component';
import { ShipmentsListComponent } from '@app/pages/authenticated/components/shipments-list/shipments-list.component';
import { NotInvoicedMaComponent } from '@app/pages/authenticated/pages/finance/components/not-invoiced-ma/not-invoiced-ma.component';
import { MachoolInvoicesComponent } from '@app/pages/authenticated/pages/finance/components/machool-invoices/machool-invoices.component';
import { MachoolInvoiceShipmentsComponent } from '@app/pages/authenticated/pages/finance/components/machool-invoice-shipments/machool-invoice-shipments.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_FINANCE] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: FinanceComponent,
        data: { scopes: [AdminScopes.VIEW_FINANCE], authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'courier-invoice/:invoiceName',
        component: InvoiceShipmentsComponent,
        data: { scopes: [AdminScopes.VIEW_FINANCE], authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'machool-invoice/:invoiceNumber',
        component: MachoolInvoiceShipmentsComponent,
        data: { scopes: [AdminScopes.VIEW_FINANCE], authGuardPipe: redirectUnauthorizedToLogin },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ShipmentDetailsComponent,
    ShipmentsListComponent,
  ],
  exports: [RouterModule],
  declarations: [
    FinanceComponent,
    CourierInvoicesComponent,
    InvoiceShipmentsComponent,
    MachoolInvoicesComponent,
    MachoolInvoiceShipmentsComponent,
    NotInvoicedCourierComponent,
    NotInvoicedMaComponent,
  ],
})
export class FinanceModule {}
