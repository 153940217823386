import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { AdminUser, UserService } from '@app/shared/services/user.service';
import { sidenavItems } from '@app/shared/constants';
import { Settings } from 'luxon';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
})
export class TopNavComponent implements OnInit {
  @Input() showMobileMenu: boolean = false;
  @Input() navItems: sidenavItems[];
  @Input() pageTitle: string = '';

  @Output() gotoNav: EventEmitter<sidenavItems> = new EventEmitter<sidenavItems>();
  public loading: boolean = true;
  public isEnglish: boolean;
  public user: AdminUser | undefined;
  public windowRef: any = window;
  public CDN_URL: string = environment.CDN_URL;

  constructor(public firebaseAuth: AngularFireAuth, private router: Router, private userService: UserService) {}
  ngOnInit() {
    this.loading = true;
    this.user = this.userService.getCurrentUser();
    if (!this.user) {
      this.logout();
    }
    this.isEnglish =
      (this.windowRef.Localize ? this.windowRef.Localize.getLanguage() || 'en' : 'en').indexOf('en') >= 0;
    Settings.defaultLocale = this.isEnglish ? 'en' : 'fr';
    this.loading = false;
  }

  public toggleLanguage() {
    if (this.windowRef.Localize) {
      this.windowRef.Localize.setLanguage(this.isEnglish ? 'fr-CA' : 'en');
      Settings.defaultLocale = this.isEnglish ? 'en' : 'fr';
      this.isEnglish = this.windowRef.Localize.getLanguage().indexOf('en') >= 0;
    }
  }

  public goto(navItem: sidenavItems) {
    this.gotoNav.emit(navItem);
  }

  public logout() {
    this.loading = true;
    this.firebaseAuth.signOut().then(() => {
      this.router.navigate(['login']);
      this.loading = false;
    });
  }
}
