import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { NotificationsComponent } from '@app/pages/authenticated/pages/notifications/notifications.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_NOTIFICATIONS] },
    canActivateChild:[ScopeGuard],
    children: [
      { path: '', component: NotificationsComponent, data: { scopes: [AdminScopes.VIEW_NOTIFICATIONS], authGuardPipe: redirectUnauthorizedToLogin }}
    ]
  }
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    NotificationsComponent,
  ]
})
export class NotificationsModule { }
