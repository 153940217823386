import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getSummaryCard(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/reports/summary_card`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getSummaryCardData(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/reports/summary_card_data`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
