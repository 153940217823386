import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class UnknownShipmentsService {
  constructor(
    private http: HttpClient,
    private helpersService: HelpersService
  ) {}

  public findAllUnknownShipments(params) {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/unknown-shipments`, { params }).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }
}
