<h2 mat-dialog-title class="my--xs align-center">Assign to the existing shipment</h2>
<mat-dialog-content class="mat-typography mb--xs">
  <mat-card class='mt--xs mat-elevation-z0 mb--sm'>
    <mat-card-content>
      <table mat-table [dataSource]="dataSource">

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef style='width: 10%'>Date</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ createdAt | date: 'E, d MMM' }}
          </td>
        </ng-container>

        <!-- Unknown Type Column -->
        <ng-container matColumnDef="unknownType">
          <th mat-header-cell *matHeaderCellDef style='width: 10%'>Unknown Type</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            <div class='pill-box pill-status' [ngClass]="getBadgeClass">{{ getStatusText }}</div>
          </td>
        </ng-container>

        <!-- Provider Column -->
        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef style='width: 5%'>Provider</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            <div *ngIf="!isInsuranceProvider">
              <img
                [src]="providerImageSrc"
                width="25px"
                height="25px"
                [matTooltip]="providerDisplayName"
                matTooltipPosition="above"
                matTooltipClass="mtx-recon-tooltip"/>
            </div>
            <div *ngIf="isInsuranceProvider">Insurance Provider</div>
          </td>
        </ng-container>

        <!-- Tracking Column -->
        <ng-container matColumnDef="tracking">
          <th mat-header-cell *matHeaderCellDef>Tracking</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ trackingNumber }}
          </td>
        </ng-container>

        <!-- Reference Column -->
        <ng-container matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef style='width: 10%'>Reference</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ reference }}
          </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="cost">
          <th mat-header-cell *matHeaderCellDef style='width: 5%'>Cost</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ totalAmount | currency : 'CAD' : 'symbol-narrow' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
  <form class="mt--lg" [formGroup]='form' (ngSubmit)='onSubmit()'>
    <div class='container'>
      <div class='row'>
        <div class='column--8'>
          <mat-form-field class="full-width">
            <mat-label>Tracking number</mat-label>
            <input matInput type='text' formControlName="assignToTrackingNumber" [value]="form.get('assignToTrackingNumber')"/>
          </mat-form-field>
        </div>
      </div>
      <div class='row'>
        <div class='column--8'>
          <mat-form-field class="full-width">
            <mat-label>Reason</mat-label>
            <mat-select
              formControlName="reasonType"
              name='reasonType'>
                <mat-option
                  *ngFor='let reasonType of reasonTypesDropdown'
                  value="{{ reasonType.id }}">{{ reasonType.text }}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class='row p--none' mat-dialog-actions>
        <div class='column--3'><button mat-flat-button mat-dialog-close color='accent'>Cancel</button></div>
        <div class='column--9 align-right'>
          <button
            mat-flat-button
            color='primary'
            type="submit"
            mat-dialog-close="true" cdkFocusInitial
            [disabled]="form.invalid"
          >Assign</button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>