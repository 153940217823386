<mat-nav-list [@openClose]="isExpanded ? 'open' : 'closed'" class='sidenav-list'>
  <mat-list-item class='machool-logo-container'>
    <div matListItemTitle class='machool-logo'>
      <img src='{{ CDN_URL }}/shared/images/logos/machool-logo-arrow.svg' class='machool-logo-small' />
      <span><img src='{{ CDN_URL }}/shared/images/logos/machool-logo.svg' class='machool-logo-full' /></span>
    </div>
  </mat-list-item>
  <mat-list-item (click)='goto(navItem)' *ngFor='let navItem of navItems' [ngClass]="{ 'active': navItem.active, 'with-spacer': navItem.withSpacer}">
    <mat-icon matListItemIcon class="material-{{ navItem.iconType ? navItem.iconType : 'icons' }}-outlined {{ navItem.iconClass }}">{{ navItem.iconClass ? '' : navItem.icon }}</mat-icon>
    <div matListItemTitle><span>{{ navItem.title }}</span></div>
  </mat-list-item>
</mat-nav-list>
