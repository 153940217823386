import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdminUser } from '@app/shared/services/user.service';
import { sidenavItems } from '@app/shared/constants';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-side-nav',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '250px',
        })
      ),
      state(
        'closed',
        style({
          width: '60px',
        })
      ),
      transition('open => closed', [animate('0.25s ease-in-out')]),
      transition('closed => open', [animate('0.25s ease-in-out')]),
    ]),
  ],
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  public loading: boolean = true;
  public CDN_URL: string = environment.CDN_URL;
  @Input() isExpanded: boolean = true;
  @Input() user: AdminUser;
  @Input() navItems: sidenavItems[];

  @Output() gotoNav: EventEmitter<sidenavItems> = new EventEmitter<sidenavItems>();

  constructor(private router: Router) {}
  ngOnInit() {
    this.loading = true;
    if (!this.user) {
      this.router.navigate(['login']);
    }
  }

  public goto(navItem: sidenavItems) {
    this.gotoNav.emit(navItem);
  }
}
