import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-update-courier-note',
  templateUrl: './update-note.component.html',
})
export class UpdateNoteComponent implements OnInit {
  public loading: boolean = true;
  public provider: any;
  public providerNoteForm: FormGroup;

  constructor(
    public drawerRef: MtxDrawerRef<UpdateNoteComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { provider: any },
    private formBuilder: FormBuilder,
    private providersService: ProvidersService,
    private bannerNotificationsService: BannerNotificationsService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.provider = this.data.provider;
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public updateNote(): void {
    const params = {
      provider: this.provider.providerType,
      category: this.provider.category,
      enabled: this.provider.enabled,
      note: this.providerNoteForm.value.note || '',
    };

    this.providersService.updateGlobalProvider(params).subscribe(
      (res) => {
        this.bannerNotificationsService.success('Global provider note successfully updated');
        this.loading = false;
        this.drawerRef.dismiss(true);
      },
      (err) => {
        this.loading = false;
        this.bannerNotificationsService.error('Failed to update the global provider note', {
          category: 'Updating courier note failed',
          message: err,
        });
      }
    );
  }

  private setupForm(): void {
    this.providerNoteForm = this.formBuilder.group({
      note: [this.provider.note || '', [Validators.required]],
    });
  }
}
