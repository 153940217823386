<ng-container *ngIf='!loading, else isLoading'>
  <div class='container'>
    <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            dynamicHeight
            animationDuration='0ms'
            [selectedIndex]="selectedTab"
            (selectedIndexChange)='selectedTab = $event'>
      <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
        <ng-template matTabContent>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="providers && providers.length === 0, else showProviderList">
      <div class='row mt--md'>
        <div class='column--12 column-md--4 align-center mx--auto'>
          <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' />
          <p>No providers found</p>
        </div>
      </div>
    </ng-container>
    <ng-template #showProviderList>
      <ng-container *ngIf="notifications && notifications.length > 0">
        <!-- Warnings if new provider not exists in global providers table -->
        <app-alert-banners *ngFor="let notification of notifications" [notification]='notification'></app-alert-banners>
      </ng-container>
      <div class='row mt--md'>
        <div class='column--8'>
          <form [formGroup]="purolatorAddressCorrectionForm" novalidate>
            <mat-checkbox
              #purolatorAddressCorrection
              class="text--sm"
              color='primary'
              formControlName='purolatorAddressCorrection'
              name='purolatorAddressCorrection'
              [(ngModel)]="purolatorAddressCorrectionEnabled"
              (change)="onAddressCorrectChange()"
            ><span class='no-bold text--sm' >Global address correction for all couriers</span></mat-checkbox>
          </form>
        </div>
        <div class='column--4 align-right'>
          <button mat-flat-button color='accent' type='button' (click)="deleteCacheKey()" [disabled]="!canEdit || deletingCourierCache">
            <mat-icon class='icon-refresh'></mat-icon>
            Delete courier cache
          </button>
        </div>
      </div>
      <div class='row'>
        <div class='column--12'>
          <mat-card class='mt--lg mat-elevation-z0'>
            <mat-card-content>
              <div class='responsive-table'>
                <table class='courier--listing' mat-table [dataSource]="selectedTab === 0 ? providerDataset : freightProviderDataset" multiTemplateDataRows style='min-width: 1115px;' matSort [matSortActive]="orderByValue" [matSortDirection]="sortAscending" (matSortChange)="setOrder($event)">
                  <ng-container matColumnDef="provider">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by provider" class='provider'>Courier ({{ (selectedTab === 0 ? providers : freightProviders).length || 0 }})</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.provider }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="country">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by country" class='country'>Country</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.country }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="markupString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by markup" class='markup'>Markup</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.markupString }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="deliveryDateString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by delivery date" class='delivery-date'>Delivery Date</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.deliveryDateString }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by note" class='note'>Note</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.note }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="statusString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status" class='status'>Status</th>
                    <td mat-cell *matCellDef="let element">
                      <div class='pill-box pill-status' [ngClass]='element.statusBadgeClass'>{{ element.statusString }}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class='action'></th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button color='primary' [matMenuTriggerFor]="courierMenu" aria-label="Invoice actions" [disabled]='!canEdit'>
                        <mat-icon>more_horiz</mat-icon>
                      </button>

                      <mat-menu #courierMenu="matMenu">
                        <!-- Edit Settings Button -->
                        <button mat-menu-item (click)='editCourierSettings(element)' [disabled]="!canEdit">
                          <span>Edit</span>
                        </button>
                        <!-- Add Note Button -->
                        <button mat-menu-item (click)='editCourierNote(element)' [disabled]="!canEdit">
                          <span>Add note</span>
                        </button>
                        <!-- Add Surcharges Button -->
                        <button mat-menu-item (click)='editCourierSurcharges(element)' [disabled]="!canEdit || !element.surcharges">
                          <span>Surcharges</span>
                        </button>
                      </mat-menu>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedParentColumns.length" class="child-providers mat-elevation-z0">
                      <table mat-table [dataSource]='element.childProviders' class='mb--md'>
                        <ng-container matColumnDef="provider">
                          <td mat-cell *matCellDef="let element" class='provider'>
                            {{ element.provider }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="country">
                          <td mat-cell *matCellDef="let element" class='country'>
                            {{ element.country }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="markupString">
                          <td mat-cell *matCellDef="let element" class='markup'>
                            {{ element.markupString }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="deliveryDateString">
                          <td mat-cell *matCellDef="let element" class='delivery-date'>
                            {{ element.deliveryDateString }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="note">
                          <td mat-cell *matCellDef="let element" class='note'>&nbsp;</td>
                        </ng-container>
                        <ng-container matColumnDef="statusString">
                          <td mat-cell *matCellDef="let element" class='status'>
                            <div class='pill-box pill-status' [ngClass]='element.statusBadgeClass'>{{ element.statusString }}</div>
                          </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      </table>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedParentColumns"></tr>
                  <tr mat-row *matRowDef="let element; columns: displayedParentColumns;">

                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"  [ngClass]="{ 'display--none': (row.childProviders || []).length === 0 }"></tr>
                </table>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
