import { Component, Inject, OnInit } from '@angular/core';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { Dashboard, DashboardService } from '@app/shared/services/dashboard.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProvinceList } from '@app/shared/constants';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-add-dashboard',
  templateUrl: './add-dashboard.component.html',
})
export class AddDashboardComponent implements OnInit {
  public loading: boolean = true;
  public dashboardForm: FormGroup;
  private readonly provinces = ProvinceList;
  public provincesDropdown;

  constructor(
    public drawerRef: MtxDrawerRef<AddDashboardComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { dashboards: Dashboard[] },
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService,
    private bannerNotificationsService: BannerNotificationsService,
    private logger: LoggerService
  ) {}

  ngOnInit() {
    this.loading = true;
    const takenProvinces: string[] =
      this.data.dashboards && this.data.dashboards.length > 0
        ? this.data.dashboards.map((dashboard) => dashboard.province)
        : [];
    this.provincesDropdown = this.provinces.filter((province) => !takenProvinces.includes(province.id));
    this.setupForm();
  }

  public dismissDrawer(success: boolean = false): void {
    this.drawerRef.dismiss(success);
  }

  public saveDashboard(): void {
    if (!this.dashboardForm.invalid) {
      const createDashboardDto: any = this.dashboardForm.value;
      this.dashboardService.createDashboard(createDashboardDto).subscribe(
        (res) => {
          this.bannerNotificationsService.success('Dashboard was saved successfully');
          this.logger.log('Create dashboard success', res);
          this.dismissDrawer(true);
        },
        (err) => {
          this.bannerNotificationsService.error('Saving the Dashboard failed', {
            category: 'Create dashboard error',
            message: err,
          });
          this.dismissDrawer();
        }
      );
    }
  }

  private setupForm() {
    this.dashboardForm = this.formBuilder.group({
      province: ['', [Validators.required]],
    });
    this.loading = false;
  }
}
