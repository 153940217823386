<ng-container *ngIf='!loading && company, else isLoading'>
  <div class='row mt--md' *ngIf="canEdit">
    <div class='column--12'>
      <mat-card class='mat-elevation-z0'>
        <mat-card-content>
          <div class='semi-bold text-larger summary-title mb--sm'>Add charge</div>
          <form [formGroup]="createChargeForm" (ngSubmit)='issueCharge()'>
            <div class="row">
              <div class='column--12 column-lg--9'>
                <mat-form-field class='mr--md'>
                  <mat-label>Charge type</mat-label>
                  <mat-select formControlName="type" name='type' placeholder='Select charge type'>
                    <mat-option *ngFor='let type of chargeTypesDropdown' value="{{ type.id }}">{{ type.text }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Base amount ($)</mat-label>
                  <mat-icon matPrefix>attach_money</mat-icon>
                  <input matInput type='number' id="baseAmount" formControlName="baseAmount" />
                </mat-form-field>
              </div>
              <div class='column--12 column-lg--3 align-right'>
                <button mat-flat-button color='primary' type='submit' [disabled]='createChargeForm.invalid'>Issue Charge</button>
              </div>
            </div>
            <!-- charge-specific options -->
            <div class="row">
              <mat-form-field *ngIf="selectedChargeType() === CompanyChargeCode.PICKUP_FEE">
                <mat-label>Tracking No.</mat-label>
                <input matInput type='text' id="trackingNumber" formControlName="trackingNumber" />
              </mat-form-field>
              <mat-form-field *ngIf="selectedChargeType() === CompanyChargeCode.STORE_PURCHASE">
                <mat-label>Order UUID</mat-label>
                <input matInput type='text' id="orderUuid" formControlName="orderUuid"/>
              </mat-form-field>
              <mat-form-field *ngIf="selectedChargeType() === CompanyChargeCode.STORE_PURCHASE">
                <mat-label>Shipping amount ($)</mat-label>
                <mat-icon matPrefix>attach_money</mat-icon>
                <input matInput type='number' id="shippingAmount" formControlName="shippingAmount" />
              </mat-form-field>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class='row mt--md' *ngIf="companyCharges?.length > 0, else noTransactions">
    <div class='column--12'>
      <mat-card class='mat-elevation-z0'>
        <mat-card-content>
          <table mat-table [dataSource]="paginatedCharges" class="mat-elevation-z0">
            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef style='width: 120px'>Date</th>
              <td mat-cell class="company-charge-cost-cell" *matCellDef="let element">
                {{ element.chargedAt | timeToString : DATE_YEAR }}
              </td>
            </ng-container>
            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef style='width: 180px'>Type</th>
              <td mat-cell class="company-charge-cost-cell" *matCellDef="let element">
                {{ mapVerboseChargeCode(element.code) }}
              </td>
            </ng-container>
            <!-- Description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell  *matHeaderCellDef>Description</th>
              <td mat-cell class="company-charge-cost-cell" *matCellDef="let element">
                {{ element.description }}
              </td>
            </ng-container>
            <!-- Cost breakdown Column -->
            <ng-container matColumnDef="cost">
              <th mat-header-cell *matHeaderCellDef style='width: 320px'>Cost</th>
              <td mat-cell *matCellDef="let element" style="padding-top: 4px">
                <div class='mb--md'>
                  <div
                    class='cost-summary'
                    *ngFor="let rate of element.rates as rates; let i = index">
                    <div
                      class='row cost-summary-rows mt--sm'
                      *ngIf="showSection(rate)"
                      [ngClass]="rate.adjustmentGroup ? 'has-adjustment' : ''"
                    >
                      <ng-container *ngIf="rate.label === 'total'">
                        <div class='column--12'>
                          <div class='border-bottom mb--sm pt--sm'></div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="!rate.adjustmentGroup && showSection(rate)">
                        <div
                          class='column--12 cost-summary-labels'
                          [ngClass]="[i === (rates.length - 1) ? 'column-md--5' : 'column-md--9', rate.adjustmentGroup ? 'btn-link' : '']"
                        >
                          <span [innerHTML]='rate.label'></span>
                        </div>
                        <div
                          class='column--12 cost-summary-values align-right'
                          [ngClass]="i === (rates.length - 1) ? 'column-md--7' : 'column-md--3'"
                        >
                          <span *ngIf="showAmount(rate)">{{ getRateValue(rate.amount) }}</span>
                          <span *ngIf="rate.valueIncluded">Included</span>
                          <span *ngIf="rate.label === 'taxes' && rate.amount === 0">{{ getRateValue(rate.amount) }}</span>
                          <div *ngIf="rate.label === 'total'">
                            <strong>{{ getRateValue(rate.amount) }}</strong>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="rate.adjustmentGroup && showSection(rate)">
                        <div class='column--8 cost-summary-labels text--xs'>
                          <ng-container *ngIf="rate.stripeReceiptUrl, else noReceipt">
                            <a class='pl--md text--primary cursor-pointer' (click)="getReceipt(rate.stripeReceiptUrl)">
                              <span class="display-inline-block" [innerHTML]='rate.label'></span>
                            </a>
                          </ng-container>
                          <ng-template #noReceipt>
                            <span class='pl--md display-inline-block' [innerHTML]='rate.label'></span>
                          </ng-template>
                        </div>
                        <div class='column--4 cost-summary-value align-right text--xs'>
                          <span *ngIf="showAmount(rate)">-{{ getRateValue(rate.amount) }}</span>
                        </div>
                      </ng-container>
                      <div class='cost-summary-list mb--sm w-100 text--xs' *ngIf="rate.list && rate.list.length > 0">
                        <ng-container *ngFor="let item of rate.list; let idx = index">
                          <div class='row' *ngIf="rate.label !== 'taxes' || (rate.label === 'taxes' && item.amount !== 0)">
                            <div class='column--8 cost-summary-labels'>
                              <span class='ml--md'>{{ rate.label !== 'taxes' ? (item.name) : (getTaxRatesLabel(item)) }}</span>
                            </div>
                            <div class='column--4 cost-summary-values align-right' *ngIf="item.amount !== 0">                                        &nbsp;
                              <span>{{ getRateValue(item.amount) }}</span>
                            </div>
                            <div class='column--4 cost-summary-values align-right' *ngIf="item.amount === 0">
                              <ng-template>
                                {{ getRateValue('Included') }}
                              </ng-template>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef style='width: 50px'></th>
              <td mat-cell class="company-charge-cost-cell" style="padding-top: 6px !important;" *matCellDef="let element">
                <button mat-icon-button [disabled]="!canEdit" (click)='cancelCharge(element)' class='mat-small-icon text-danger'><mat-icon class='icon-bin'></mat-icon></button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator
                  (page)="pageChanged($event)"
                  [length]='companyCharges.length'
                  [pageSize]="itemsPerPage"
                  [pageIndex]='currentPage'
                  [disabled]='companyCharges.length <= itemsPerPage'
                  [hidePageSize]='true'>
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <ng-template #noTransactions>
    <div class='row mt--md'>
      <div class='column--12 align-center'>
        <p><img src='{{ CDN_URL }}/admin/images/empty-transactions.svg' height='147px' /></p>
        <p>No company charges found.</p>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
