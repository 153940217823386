<mat-sidenav-container *ngIf="!loading, else isLoading">
  <mat-sidenav *ngIf='!showMobileMenu' #sidenav mode='side' opened [ngClass]="isExpanded ? 'sidenav-open' : 'sidenav-closed'">
    <button mat-mini-fab (click)="toggle()" class='toggle-side-nav'>
      <mat-icon [@rotateIcon]="isExpanded ? 'open' : 'closed'" aria-label="Toggle Menu">chevron_left</mat-icon>
    </button>
    <app-side-nav [isExpanded]='isExpanded' [user]='user' [navItems]='navItems' (gotoNav)='gotoNav($event)'></app-side-nav>
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'showMobileMenu': showMobileMenu}" [@openClose]="isExpanded ? 'open' : 'closed'">
    <div class='container--full-width main-content'>
      <app-top-nav [showMobileMenu]='showMobileMenu' [pageTitle]='pageTitle' [navItems]='navItems' (gotoNav)='gotoNav($event)'></app-top-nav>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
