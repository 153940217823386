<h3>Payment history <mat-chip *ngIf='!loading && invoice' class="ml--md chip-invoice status-{{ invoice.className }}">{{ invoice.status }}</mat-chip></h3>
<ng-container *ngIf='!loading && company && invoice, else isLoading'>
  <app-invoice-meta [company]='company' [invoice]='invoice'></app-invoice-meta>

  <ng-container *ngIf='invoicePaymentDataSet.length > 0, else noPayments'>
    <mat-card class='mat-elevation-z0 my--md'>
      <mat-card-content class='pt--none'>
        <table mat-table [dataSource]="invoicePaymentDataSet" class="mt--sm mat-elevation-z0">

          <!-- Payment Date Column -->
          <ng-container matColumnDef="paymentDate">
            <th mat-header-cell *matHeaderCellDef >Date</th>
            <td mat-cell *matCellDef="let element" class='text--sm'> {{ element.formattedDate }} </td>
          </ng-container>

          <!-- Amount Column -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef >Amount</th>
            <td mat-cell *matCellDef="let element" class='text--sm'> {{ element.amount | currency }} </td>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef >Description</th>
            <td mat-cell *matCellDef="let element" class='text--sm'> {{ element.description }} </td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class='align-center'></th>
            <td class='text--sm align-right' mat-cell *matCellDef="let element">
              <!-- Delete Button -->
              <button
                mat-icon-button
                class='mat-smaller-icon text--danger'
                (click)='deleteInvoicePayment(element)'
                matTooltip="Delete payment"
                matTooltipPosition="above"
              ><mat-icon class='icon-bin'></mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-template #noPayments>
    <p class='my--lg'><strong>No payments for this invoice.</strong></p>
  </ng-template>

  <div class='row'>
    <div class='column--6'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Close</button>
    </div>
    <div *ngIf='invoice.balanceDue > 0' class='column--6 align-right'>
      <button mat-flat-button color='primary' (click)='addInvoicePayment(invoice)'>Add payment</button>
    </div>
  </div>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
