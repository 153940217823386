import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class FinanceService {
  constructor(private http: HttpClient) {}

  public getInvoices(params: any): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/finance/invoices`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public getCourierInvoiceShipments(courierInvoiceName): any {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/finance/invoices/${courierInvoiceName}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public getMAInvoiceShipments(courierInvoiceNumber): any {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/finance/machool-invoices/${courierInvoiceNumber}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public getNoCourierInvoiceShipments(params): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/finance/no-courier-invoice`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public getNoMAInvoiceShipments(params): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/finance/no-machool-invoice`, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }
}
