import { Component, Input, OnInit } from '@angular/core';
import { Animations, DefaultTimezone, ReconProviderItems } from '@app/shared/constants';
import { AdminUser } from '@app/shared/services/user.service';
import { FinanceService } from '@app/pages/authenticated/pages/finance/finance.service';
import { DateTime } from 'luxon';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-not-invoiced-by-machool',
  templateUrl: './not-invoiced-ma.component.html',
  animations: [Animations.ExpandAnimation],
})
export class NotInvoicedMaComponent implements OnInit {
  @Input() user: AdminUser;

  public loading: boolean = true;
  public searchForm: FormGroup | undefined;
  public totalShipments: number = 0;
  public shipments: any = [];
  public defaultTimezone = DefaultTimezone;
  public providerItems: any = ReconProviderItems;
  public dateRange = {
    start: DateTime.now().setZone(this.defaultTimezone).minus({ days: 7 }).toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };
  public range = new FormGroup({
    start: new FormControl<Date | null>(this.dateRange.start, [Validators.required]),
    end: new FormControl<Date | null>(this.dateRange.end, [Validators.required]),
  });

  constructor(
    private auditService: FinanceService,
    private formBuilder: FormBuilder,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit() {
    if (this.user) {
      this.providerItems.sort((a, b) => a.text.localeCompare(b.text));
      this.providerItems.unshift({ id: 'all', value: 'all', text: 'All' });
      this.reset();
    }
  }

  public reset() {
    this.loading = true;
    this.shipments = [];
    this.range.patchValue({
      start: this.dateRange.start,
      end: this.dateRange.end,
    });
    this.createForm();
    this.getNoMAInvoiceShipments();
  }
  public search() {
    this.getNoMAInvoiceShipments();
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      provider: ['all'],
      trackingNumber: [],
      startDate: [this.dateRange.start, [Validators.required]],
      endDate: [this.dateRange.end, [Validators.required]],
    });
  }

  private getNoMAInvoiceShipments() {
    this.loading = true;
    const searchValues = this.searchForm?.value;
    const startDate = DateTime.fromJSDate(this.range.value.start).setZone(this.defaultTimezone).startOf('day');
    const endDate = DateTime.fromJSDate(this.range.value.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    const searchParams = {
      startDate,
      endDate,
      trackingNumber: searchValues?.trackingNumber,
      provider: searchValues?.provider,
    };

    if (searchValues?.trackingNumber) {
      delete searchParams.startDate;
      delete searchParams.endDate;
    } else {
      delete searchParams.trackingNumber;
    }

    this.auditService.getNoMAInvoiceShipments(searchParams).subscribe(
      (res) => {
        this.shipments = res?.shipments || [];
        this.totalShipments = res?.totalShipments || 0;
        // setup the total not invoiced to customer
        this.loading = false;
      },
      (err: Error) => {
        this.bannerNotificationsService.error(err.message, {
          category: 'GET Machool invoice data error',
          message: err,
        });
      }
    );
  }
}
