import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth, AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth';
import { firebase, FirebaseUIModule } from 'firebaseui-angular';
import { environment } from '../environments/environment';
import { UnauthenticatedModule } from './pages/unauthenticated/unauthenticated.module';
import { AuthenticatedModule } from './pages/authenticated/authenticated.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AuthInterceptor } from '@app/shared/interceptors/auth.interceptor';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { QuillConfigModule } from 'ngx-quill/config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AuthenticatedModule,
    BrowserAnimationsModule,
    CommonModule,
    FirebaseUIModule.forRoot(environment.firebaseUiAuthConfig),
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    SharedModule,
    UnauthenticatedModule,
    QuillConfigModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          // [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          // [{ direction: 'rtl' }], // text direction

          // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          // [{ font: [] }],
          [{ align: [] }],

          // ['clean'], // remove formatting button

          // ['link', 'image', 'video'], // link and image, video
        ],
      },
    }),
  ],
  providers: [
    // SETUP FOR FIREBASE MULTI-TENANCY:
    { provide: TENANT_ID, useValue: environment.firebaseTenantID },
    [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
    provideLottieOptions({
      player: () => player,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private fireAuthService: AngularFireAuth) {
    this.fireAuthService.setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }
}
