<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Canada Post Receipt</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>

<div class='row'>
  <div class='column--12'>
    <div style="font-size: 14px; line-height: 1.428; font-family: 'Inter Regular', sans-serif; display: flex; justify-content: center">
      <div style='width: 100%; display: flex; flex-direction: column;'>
        <h4>Customer credit card receipt /<br>Reçu de carte de crédit</h4>
        <p style="margin-bottom: 10px; margin-top: 1rem;">The online transaction has been approved by the credit card issuer. /<br>La transaction en lingne a été approuvée par l'établissement émetteur de la carte de crédit.</p>
        <section *ngFor="let section of receiptDetails" style="margin-bottom: 0.5em; margin-top: 0.5em;">
          <div *ngFor="let field of section" style="display: flex; flex-wrap: wrap; align-items: center;line-height: 1em; padding-bottom: 5px; padding-top: 5px;">
            <div style="flex: 0 0 58.33333333%; max-width: 58.33333333%;">
              <span [innerHTML]="field.label"></span>
            </div>
            <div style="flex: 0 0 41.66666667%; max-width: 41.66666667%;">
              <span [innerHTML]="field.value"></span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div class='row mt--md'>
  <div class='column--12 align-center'>
    <button mat-flat-button (click)='dismissDrawer()'>Close</button>
  </div>
</div>
