<ng-container *ngIf='!loading'>
  <ng-container *ngIf='showMobileMenu'>
    <div class='row row--valign-center mt--md'>
      <div class='column--6'>
        <div class='machool-logo'>
          <img src='{{CDN_URL}}/shared/images/logos/machool-logo.svg' class='machool-logo-full' height='40px' />
        </div>
      </div>
      <div class='column--6 align-right'>
        <button mat-fab color='primary' class='text--lg' [matMenuTriggerFor]='mobileMenuList'><mat-icon>menu</mat-icon></button>
        <mat-menu #mobileMenuList="matMenu">
          <button mat-menu-item [ngClass]="{ 'active': navItem.active }" (click)='goto(navItem)' *ngFor='let navItem of navItems'>
            <mat-icon matListItemIcon class="material-{{ navItem.iconType ? navItem.iconType : 'icons' }}-outlined {{ navItem.iconClass }}">{{ navItem.iconClass ? '' : navItem.icon }}</mat-icon>
            <span>{{ navItem.title }}</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)='logout()'>
            <mat-icon class="material-icons-outlined">logout</mat-icon>
            <span>Sign out</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </ng-container>
  <div class='row row--valign-center'>
    <div class='column--12 column-md--6'>
      <h2>{{ pageTitle }}</h2>
    </div>
    <div class='column--6 align-right'>
      <button *ngIf='!showMobileMenu' mat-button color='primary' class='text--sm mr--xs' (click)='logout()'>Sign out</button>
      <button *ngIf='!showMobileMenu' mat-button color='primary' class='text--sm mr--md' (click)='toggleLanguage()'>{{ isEnglish ? 'FR' : 'EN' }}</button>
    </div>
  </div>
</ng-container>
