import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { CouriersPageComponent } from '@app/pages/authenticated/pages/couriers/couriers.component';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { UpdateNoteComponent } from '@app/pages/authenticated/pages/couriers/components/update-note/update-note.component';
import { ModalEditCourierSurchargesComponent } from '@app/pages/authenticated/pages/couriers/components/modal-edit-courier-surcharges/modal-edit-courier-surcharges.component';
import { EditCourierSettingsComponent } from '@app/pages/authenticated/pages/couriers/components/edit-courier-settings/edit-courier-settings.component';
import { EditChildCourierSettingsComponent } from '@app/pages/authenticated/pages/couriers/components/edit-child-courier-settings/edit-child-courier-settings.component';
import { CourierSettingsComponent } from '@app/pages/authenticated/pages/couriers/components/courier-settings/courier-settings.component';
import { ModalsAssignOtherToChargeComponent } from '@app/pages/authenticated/pages/reconciliation/modals/assign-other-to-charge/modals-assign-other-to-charge.component';
import { ModalsBatchAdjustmentsComponent } from '@app/pages/authenticated/pages/reconciliation/modals/batch-adjustments/modals-batch-adjustments.component';
import { ModalsAssignUnknownTrackingComponent } from '../reconciliation/modals/assign-unknown-tracking/modals-assign-unknown-tracking.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_COURIERS] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: CouriersPageComponent,
        data: {
          scopes: [AdminScopes.VIEW_COURIERS],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    CouriersPageComponent,
    CourierSettingsComponent,
    EditChildCourierSettingsComponent,
    EditCourierSettingsComponent,
    UpdateNoteComponent,
    ModalEditCourierSurchargesComponent,
    ModalsAssignOtherToChargeComponent,
    ModalsBatchAdjustmentsComponent,
    ModalsAssignUnknownTrackingComponent,
  ],
})
export class CouriersModule {}
