<ng-container *ngIf='!loading, else isLoading'>
  <div class='row'>
    <div class='column--12 mb--lg'>
      <ng-container *ngIf='invoice'>
        <h2 class='mb--xxs mt--none semi-bold'>Invoice #: {{invoice.invoiceNumber}}</h2>
        <h3 class='mb--sm mt--none no-bold'>
          Company: {{ invoice.companyName }}<br />
          Date: {{invoice.createdAt | timeToString : DATE_FORMAT : defaultTimezone}} <br />
          Due: {{invoice.dueDate | timeToString : DATE_FORMAT : defaultTimezone}} <br />
          Range: {{invoice.dateRangeStart | timeToString : DATE_FORMAT : defaultTimezone}}-{{invoice.dateRangeEnd | timeToString : DATE_FORMAT : defaultTimezone}} <br />
          Total: {{ invoice.amount | currency }}
        </h3>
      </ng-container>
      <ng-template #noInvoice>
        <h2 class='mb--sm'>Shipments for Invoice: {{invoiceNumber}}</h2>
      </ng-template>
      <a [routerLink]="['/finance/']" class='text--primary-green'>< Back to machool invoices</a>
    </div>
  </div>
  <div class='row row-start'>
    <div class='column--12'>
      <shipments-list [shipments]='shipments' [totalShipments]='totalShipments' [showCourierInvoice]='true' [user]='user'></shipments-list>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
