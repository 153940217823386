import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, Subscription } from 'rxjs';
import { FinanceService } from '@app/pages/authenticated/pages/finance/finance.service';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Animations, DateFormats, DefaultTimezone } from '@app/shared/constants';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-machool-invoice-shipments',
  templateUrl: './machool-invoice-shipments.component.html',
  animations: [Animations.ExpandAnimation],
})
export class MachoolInvoiceShipmentsComponent implements OnInit, OnDestroy {
  public loading = true;
  public user: AdminUser;
  public invoiceNumber: string;
  public shipments: any = [];
  public invoice: any;
  public totalShipments: number = 0;
  public DATE_FORMAT: string = DateFormats.DATE_YEAR;
  public defaultTimezone = DefaultTimezone;

  private routeSub: Subscription;

  constructor(
    private auditService: FinanceService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.routeSub = this.route.params.subscribe((params) => {
      if (!this.invoiceNumber) {
        this.invoiceNumber = params['invoiceNumber'];
      }
    });
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_FINANCE])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.getShipmentsByInvoice();
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  private getShipmentsByInvoice(): void {
    this.auditService.getMAInvoiceShipments(this.invoiceNumber).subscribe(
      (res) => {
        this.invoice = res?.invoice;
        this.invoice.companyName = this.invoice?.company?.name ? this.invoice.company.name : 'N/A';
        this.shipments = res?.shipments || [];
        this.totalShipments = res?.totalShipments || 0;
        this.loading = false;
      },
      (err: Error) => {
        this.bannerNotificationsService.error(err.message, {
          category: 'GET Machool invoice shipments data error',
          message: err,
        });
      }
    );
  }
}
