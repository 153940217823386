<h1>Add funds</h1>
<form [formGroup]="transactionForm">
  <div class='row mt--md'>
    <div class='column--12'>
      <mat-form-field style="min-width: 240px">
        <mat-label>Amount</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input class="p--none" matInput type='number' formControlName="amount"/>
        <mat-error class="text--sm" *ngIf="transactionForm.get('amount').hasError('insufficientBalance')">
          Exceeds wallet balance.
        </mat-error>
      </mat-form-field>
    </div>
    <div class='column--12'>
      <mat-form-field appearance="outline" class='w-100'>
        <mat-label>Note</mat-label>
        <textarea matInput formControlName='description' rows='4'></textarea>
      </mat-form-field>
    </div>
    <div class='column--6'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
    </div>
    <div class='column--6 align-right'>
      <button mat-flat-button color='primary' (click)='createTransaction()' [disabled]='transactionForm.invalid'>Save</button>
    </div>
  </div>
</form>
