<ng-container *ngIf='!loading && filterForm, else isLoading'>
  <form [formGroup]="filterForm">
    <mat-card [ngClass]="{'mb--sm': paginatedInvoices.length == 0 }">
      <mat-card-content class='pb--none'>
        <div class='row'>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Company name</mat-label>
              <input matInput formControlName="companyName" name="companyName" value="">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Invoice number</mat-label>
              <input matInput formControlName='invoiceNumber' name='invoiceNumber' value="">
            </mat-form-field>
          </div>
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" name='status' (selectionChange)='updateDateDisabled($event)'>
                <mat-option *ngFor='let option of invoiceStatusesDropdown' value="{{ option.text }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class='column--12 column-md--4'>
            <mat-form-field class='full-width'>
              <mat-label>Date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker" [disabled]='disableDatePicker'>
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #rangePicker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class='column--12 column-md--8 align-right mt--xs'>
            <button mat-button color='primary' type='button' class='mr--md' (click)='reset()' [disabled]="filterForm.invalid || loading">Clear all</button>
            <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="filterForm.invalid || loading">Search</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <div class='row' *ngIf="!loading && paginatedInvoices.length > 0">
    <div class='column--9 mt--md'>
      <button mat-flat-button color='primary' type='button' class='mr--md mt--none' [disabled]='!canEditAndEmailInvoices || loading || selection.selected.length === 0' (click)="sendEmail(selection.selected)">Email invoice</button>
      <button mat-flat-button color='primary' type='button' class='mr--md mt--none' [disabled]='!canEditAndEmailInvoices || loading || selection.selected.length === 0' (click)="downloadInvoices(selection.selected)">Download invoices</button>
      <button mat-flat-button color='primary' type='button' class='mt--none' [disabled]='!canEditAndEmailInvoices || loading || selection.selected.length === 0' (click)="addBulkInvoicePayment(selection.selected)">Add Payments</button>
    </div>
  </div>
  <mat-card *ngIf="!loading && paginatedInvoices.length > 0" class='mt--md mat-elevation-z0 mb--sm'>
    <mat-card-content>

      <div class='responsive-table'>
        <table mat-table [dataSource]="invoicesDataSet" multiTemplateDataRows matSort (matSortChange)="sortData($event)">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef style='width: 2%'>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef style='width: 8%' mat-sort-header sortActionDescription="Sort by invoice">Invoice</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.invoiceNumber }}
            </td>
          </ng-container>
          <ng-container matColumnDef="paidBy">
            <th mat-header-cell *matHeaderCellDef style='width: 8%' mat-sort-header sortActionDescription="Sort by paidBy">Paid By</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.billingInvoicePaymentInfo.type }}
              <span class="mat-icon-style" [matTooltip]="element.billingInvoicePaymentInfo.tooltip" matTooltipPosition="above"><mat-icon class="{{ element.billingInvoicePaymentInfo.icon }} mat-icon-sm text-base mt-20 pt-10 text-{{ element.billingInvoicePaymentInfo.color }}"></mat-icon></span>
            </td>
          </ng-container>
          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef style='width: 18%' mat-sort-header sortActionDescription="Sort by Company">Company</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.companyName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef style='width: 11%' mat-sort-header sortActionDescription="Sort by date">Date</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.formattedDate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateRange">
            <th mat-header-cell *matHeaderCellDef style='width: 11%'>Date Range</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.dateRangeString }}
            </td>
          </ng-container>
          <ng-container matColumnDef="due">
            <th mat-header-cell *matHeaderCellDef style='width: 11%' mat-sort-header sortActionDescription="Sort by due">Due</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.formattedDueDate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef style='width: 10%' mat-sort-header sortActionDescription="Sort by invoice amount">Invoice Amount</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.amount | currency }}
            </td>
          </ng-container>
          <ng-container matColumnDef="outstanding">
            <th mat-header-cell *matHeaderCellDef style='width: 9%' mat-sort-header sortActionDescription="Sort by outstanding balance">Outstanding</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.balanceDue | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style='width: 10%; min-width: 155px;' mat-sort-header sortActionDescription="Sort by status">Status</th>
            <td mat-cell *matCellDef="let element" class='text--sm pr--none'>
              <mat-chip
              class="chip-invoice status-{{ element.className }}"
              [matTooltip]="'Outstanding balance: ' + (element.balanceDue | currency)"
              matTooltipPosition="above"
              [matTooltipDisabled]="element.balanceDue && element.balanceDue === 0"
            >{{ element.className === 'processing' ? 'Processing ...' : element.status }}</mat-chip>
              <mat-icon
              *ngIf="element.lastEmailSent"
              class='icon-email-checked mat-icon-sm text--table-header ml--xs'
              [matTooltip]="element.lastEmailSent"
              matTooltipPosition="above">
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style='width: 2%' class='align-right'>
              <!-- Refresh Button -->
              <button
                mat-icon-button
                color='primary'
                class='mat-small-icon mx--xs'
                (click)='search(true)'
                matTooltip="Refresh invoices"
                matTooltipPosition="above"
              ><mat-icon class='icon-refresh'></mat-icon></button>
            </th>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element"  class='align-right text--sm'>
              <ng-container>
                <button mat-icon-button [matMenuTriggerFor]="invoiceMenu" aria-label="Invoice actions" color='primary'>
                  <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #invoiceMenu="matMenu">
                  <!-- Preview Button -->
                  <button mat-menu-item (click)="previewInvoice(element)" [disabled]="!element.invoiceUrl">
                    <span>Preview PDF</span>
                  </button>
                  <button mat-menu-item (click)="downloadInvoice(element, 'pdf')" [disabled]="!element.invoiceUrl || !element.pdfStatementAvailable">
                    <span>Download PDF</span>
                  </button>
                  <button mat-menu-item (click)="downloadInvoice(element, 'csv')" [disabled]="!element.csvStatementAvailable || !element.invoiceUrl">
                    <span>Download CSV</span>
                  </button>
                  <button mat-menu-item (click)="sendEmail([element])" [disabled]="!canEditAndEmailInvoices || !element.invoiceUrl">
                    <span>Email invoice</span>
                  </button>
                  <!-- Add Payment Button -->
                  <button mat-menu-item [disabled]="element.balanceDue === 0" (click)="addInvoicePayment(element)" [disabled]="!canEditAndEmailInvoices || !element.invoiceUrl">
                    <span>Add payment</span>
                  </button>
                  <!-- Add Payment Button -->
                  <button mat-menu-item (click)="viewInvoicePayments(element)" [disabled]="!canEditAndEmailInvoices || !element.invoiceUrl">
                    <span>Payment history</span>
                  </button>
                  <!-- Delete Button -->
                  <button mat-menu-item class='border-top text-danger' (click)='deleteInvoice(element)' [disabled]="!canEditAndEmailInvoices">
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;">
          </tr>
        </table>
      </div>

      <mat-paginator
        (page)="pageChanged($event)"
        [length]='invoicesCount'
        [pageSize]="itemsPerPage"
        [pageSizeOptions]="itemsPerPageOptions"
        [pageIndex]='currentPage'
        [disabled]='invoicesCount <= itemsPerPage'>
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
