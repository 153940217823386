import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { UnknownTrackingComponent } from './unknown-tracking.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_RECONCILIATION] },
    canActivateChild:[ScopeGuard],
    children: [
      { path: '', component: UnknownTrackingComponent, data: { scopes: [AdminScopes.VIEW_RECONCILIATION], authGuardPipe: redirectUnauthorizedToLogin }}
    ]
  }
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    UnknownTrackingComponent,
  ]
})
export class UnknownTrackingModule { }
