import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { FirebaseUIModule } from 'firebaseui-angular';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { InvoiceDocumentsModule } from './pages/invoice-documents/invoice-documents.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    AngularFireModule,
    AngularFireAuthModule,
    FirebaseUIModule,
    SharedModule,
    InvoiceDocumentsModule,
  ],
  declarations: [
    LoginComponent,
    NotFoundComponent,
  ],
})
export class UnauthenticatedModule { }
