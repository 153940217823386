<h1>Generate Invoice</h1>
<h4 class="pl--lg text-danger">Generating invoice for {{ companies.length }} companies </h4>
<ng-container *ngIf='!loading && companies, else isLoading'>
  <form [formGroup]="generateInvoiceForm">
    <div class='row mt--sm pl--lg'>
      <div class='column--8'>
        <mat-form-field class='full-width'>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #rangePicker></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <mat-form-field class="full-width">
          <mat-label>Invoice date</mat-label>
          <input matInput formControlName="invoiceDate" [matDatepicker]="invoiceDatePicker">
          <mat-datepicker-toggle matIconSuffix [for]="invoiceDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #invoiceDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='create()' [disabled]='generateInvoiceForm.invalid || companies.length === 0'>Save</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
