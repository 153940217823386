<ng-container *ngIf='!loading && company, else isLoading'>
  <div class='row mt--md' *ngIf="showMachoolPickupFeeWarning">
    <div class='column--12 text--danger'>
      <div class='display-inline-flex'>
        <mat-icon>warning_amber</mat-icon>
        <span class='ml--sm'>Machool Pickup fee should not be undefined, please set the valid pickup fee and update.</span>
      </div>
    </div>
  </div>
  <form [formGroup]="machoolPickupForm">
    <div class='row mt--md'>
      <div class='column--12'>
        <ul class='no-list-style'>
          <li *ngFor="let provider of machoolPickupProviders">
            <div class="row">
              <div class="column--6">
                  <mat-checkbox
                  color='primary'
                  [value]='provider.name'
                  [checked]="provider.selected"
                  [disabled]='!canEdit'
                  (change)="selectMachoolPickupProvider($event)">
                  <span class='circle' [ngClass]="provider.circleClass"></span>
                  <span class='ml--md'>{{provider.name}}</span>
                </mat-checkbox>
              </div>
              <div class="column--6">
                <mat-form-field class="">
                  <mat-label>Amount</mat-label>
                  <mat-icon matPrefix>attach_money</mat-icon>
                  <input matInput type='number' formControlName="{{provider.pickupFeeField}}" [value]="machoolPickupForm.get(provider.pickupFeeField).value"/>
                </mat-form-field>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class='row mt--md'>
      <div class='column--12'>
        <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || machoolPickupForm.invalid || loading" (click)='updatePickups()'>Update</button>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
