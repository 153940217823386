import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumValuesToArray'
})
export class EnumValuesToArrayPipe implements PipeTransform {
  transform(data: Object) {
    const keys = Object.keys(data);
    return keys.map(el => Object(data)[el]);
  }
}
