import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedComponent } from './authenticated.component';
import { AngularFireAuthGuard, isNotAnonymous, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { HomeComponent } from '@app/pages/authenticated/pages/home/home.component';
import { NgModule } from '@angular/core';
import { map, pipe } from 'rxjs';
import { PermissionsModule } from '@app/pages/authenticated/pages/permissions/permissions.module';

export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(
    isNotAnonymous,
    map((loggedIn) => loggedIn || redirect)
  );
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    component: AuthenticatedComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedTo },
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'shipments',
        loadChildren: () => import('./pages/shipments/shipments.module').then((m) => m.ShipmentsModule),
      },
      {
        path: 'manifests',
        loadChildren: () => import('./pages/manifests/manifests.module').then((m) => m.ManifestsModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('./pages/reconciliation/reconciliation.module').then((m) => m.ReconciliationModule),
      },
      {
        path: 'service-builder',
        loadChildren: () =>
          import('./pages/service-builder/service-builder.module').then((m) => m.ServiceBuilderModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsModule),
      },
      {
        path: 'cancellations',
        loadChildren: () => import('./pages/cancellations/cancellations.module').then((m) => m.CancellationsModule),
      },
      {
        path: 'insurance-claims',
        loadChildren: () =>
          import('./pages/insurance-claims/insurance-claims.module').then((m) => m.InsuranceClaimsModule),
      },
      {
        path: 'invoices',
        loadChildren: () => import('./pages/invoices/invoices.module').then((m) => m.InvoicesModule),
      },
      {
        path: 'couriers',
        loadChildren: () => import('./pages/couriers/couriers.module').then((m) => m.CouriersModule),
      },
      {
        path: 'api-keys',
        loadChildren: () => import('./pages/api-keys/api-keys.module').then((m) => m.ApiKeysModule),
      },
      {
        path: 'reconciliation/company/:companyId',
        loadChildren: () => import('./pages/reconciliation/reconciliation.module').then((m) => m.ReconciliationModule),
      },
      {
        path: 'unknown-tracking',
        loadChildren: () =>
          import('./pages/unknown-tracking/unknown-tracking.module').then((m) => m.UnknownTrackingModule),
      },
      {
        path: 'unbillable-shipment',
        loadChildren: () =>
          import('./pages/unbillable-shipments/unbillable-shipments.module').then((m) => m.UnbillableShipmentsModule),
      },
      {
        path: 'platforms',
        loadChildren: () => import('./pages/platforms/platforms.module').then((m) => m.PlatformsModule),
      },
      {
        path: 'finance',
        loadChildren: () =>
          import('@app/pages/authenticated/pages/finance/finance.module').then((m) => m.FinanceModule),
      },
      {
        path: 'permissions',
        loadChildren: () =>
          import('@app/pages/authenticated/pages/permissions/permissions.module').then((m) => m.PermissionsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticatedRoutingModule {}
