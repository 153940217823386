import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShippingProviders } from '@app/shared/constants';

@Component({
  selector: 'app-courier-settings',
  templateUrl: './courier-settings.component.html',
})
export class CourierSettingsComponent implements OnInit {
  @Input() public providerSettingsForm: FormGroup;
  @Input() public provider: ShippingProviders;
  @Input() public canEditStatus: boolean = false;

  public loading = false;
  public providerHasFuelSurcharges: boolean;

  constructor() {}

  ngOnInit() {
    this.providerSettingsForm.updateValueAndValidity();
    this.providerHasFuelSurcharges =
      this.providerSettingsForm.value.fuelSurchargeMarkupMargin != null;
    this.disableMarkupField();
  }

  public disableMarkupField(): void {
    const markupDollarValue =
      this.providerSettingsForm.controls['markupDollarValue'].value;
    const markupPercentageValue =
      this.providerSettingsForm.controls['markupPercentageValue'].value;
    if (markupDollarValue) {
      this.providerSettingsForm.get('markupDollarValue').enable();
      this.providerSettingsForm.get('markupPercentageValue').disable();
    }
    if (markupPercentageValue) {
      this.providerSettingsForm.get('markupPercentageValue').enable();
      this.providerSettingsForm.get('markupDollarValue').disable();
    }
  }

  public keyPressed($event) {
    if ($event.target.value.length === 0) {
      this.providerSettingsForm.controls['markupDollarValue'].enable();
      this.providerSettingsForm.controls['markupPercentageValue'].enable();
    }
    if (
      $event.target &&
      $event.target.id === 'markupPercentageValue' &&
      $event.target.value.length > 0
    ) {
      this.providerSettingsForm.controls['markupDollarValue'].disable();
    }
    if (
      $event.target &&
      $event.target.id === 'markupDollarValue' &&
      $event.target.value.length > 0
    ) {
      this.providerSettingsForm.controls['markupPercentageValue'].disable();
    }
    this.providerSettingsForm.updateValueAndValidity();
  }
}
