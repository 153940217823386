import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'modal-confirmation-component',
  templateUrl: 'modal-confirmation.component.html',
})
export class ModalConfirmationComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title?: string; content?: string; confirmBtnLabel?: string; btnColor?: ThemePalette;}) {}
}
