<ng-container *ngIf='!loading && provider, else isLoading'>
  <h3>{{ provider.provider }}</h3>

  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    dynamicHeight
    animationDuration='0ms'
    [selectedIndex]="activeChildProviderIndex"
    (selectedIndexChange)='activeChildProviderIndex = $event'
  >
    <mat-tab *ngFor='let childProviderTab of childProviderTabs' [label]='childProviderTab.provider'>
      <ng-template matTabContent>
        <app-courier-settings
          id='childProviderForm'
          [providerSettingsForm]="getProviderFormGroup(activeChildProviderIndex)"
          [provider]="provider.childProviders[activeChildProviderIndex]"
          [canEditStatus]="canEditStatus"
        ></app-courier-settings>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class='row mt--md'>
    <div class='column--6'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
    </div>
    <div class='column--6 align-right'>
      <button mat-flat-button color='primary' (click)='updateSettings()' [disabled]='!allFormsValid'>Save</button>
    </div>
  </div>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
