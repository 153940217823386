import { Component, Inject, OnInit } from '@angular/core';
import { ShipmentsService } from '@app/shared/services/shipments.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from '@app/shared/services/logger.service';
import * as FileSaver from 'file-saver';
import { HelpersService } from '@app/shared/services/helpers.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'modal-create-return-label',
  templateUrl: 'modal-create-return-label.component.html',
})
export class ModalCreateReturnLabelComponent implements OnInit {
  // Inputs
  public shipment: any;
  public createReturnShipmentDto: any;
  public gettingLabel: boolean = false;

  public processCompleted: boolean = false;
  public hasError: boolean = false;
  public retryError: string =
    'Could not process the request. Please try again and contact Machool Support if the problem persists.';
  private generalError: string = 'Unable to create return label';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shipment?: any;
      createReturnShipmentDto?: any;
    },
    private logger: LoggerService,
    private shipmentsService: ShipmentsService,
    private helpersService: HelpersService,
    private bannerNotificationsService: BannerNotificationsService
  ) {
    this.shipment = this.data.shipment;
    this.createReturnShipmentDto = this.data.createReturnShipmentDto;
  }

  ngOnInit() {
    this.processCompleted = false;
    this.hasError = false;
    this.shipmentsService.createReturnShipment(this.shipment.provider, this.createReturnShipmentDto).subscribe(
      (response) => {
        if ((response || []).length > 0) {
          this.shipment = response[0];
          this.logger.log('Create Return Shipment Complete', response);
          this.processCompleted = true;
        }
      },
      (err: any) => {
        this.logger.error('Create Return Shipment Error', err);
        if (err.message) {
          this.bannerNotificationsService.error(this.generalError + ': ' + err.message);
        } else {
          this.bannerNotificationsService.error(this.generalError + ': ' + this.retryError);
        }
        this.hasError = true;
        this.processCompleted = true;
      }
    );
  }

  public downloadFile() {
    const query = {
      provider: this.shipment.provider,
      type: 'return-labels',
      id: this.shipment.id,
      uuid: this.shipment.orderId,
    };
    this.shipmentsService.getDocuments(query).subscribe(
      (res: any) => {
        this.logger.log('GET Shipment documents', res);

        let mimeType = 'application/pdf';
        let title = 'return-labels';
        const providerName = this.shipment.provider;
        const trackingNumber = query.id || this.shipment.tracking || '';
        const downloadFileName = `${providerName}-${trackingNumber}-return-label.pdf`;

        if (res.errors.length === 0) {
          const blob = this.helpersService.b64toBlob(res.data, mimeType);
          FileSaver.saveAs(blob, downloadFileName);
          this.gettingLabel = false;
        } else {
          this.bannerNotificationsService.error('Could not get shipment document');
          this.logger.error('GET shipment document error', {
            errors: res.errors,
          });
          this.gettingLabel = false;
        }
      },
      (err: any) => {
        this.bannerNotificationsService.error('Could not get shipment document');
        this.logger.error('GET shipment document error', err);
        this.gettingLabel = false;
      }
    );
  }
}
