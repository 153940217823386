import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ParamMap, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { LoggerService } from '@app/shared/services/logger.service';
import { InvoiceDownloadTypes } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-invoice-documents',
  templateUrl: './invoice-documents.component.html',
  styleUrls: ['./invoice-documents.component.scss'],
})
export class InvoiceDocumentsComponent implements OnInit {
  public CDN_URL: string = environment.CDN_URL;
  public documentType: string;
  public invoiceNumber: string;
  public token: string;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private billingInvoicesService: BillingInvoicesService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.documentType = params.get('documentType');
      this.invoiceNumber = params.get('invoiceNumber');
      this.token = params.get('token');
    });
  }

  public async validateTokenAndDownloadDocument(
    documentType: string,
    invoiceNumber: string,
    token: string
  ): Promise<any> {
    this.billingInvoicesService.validateTokenAndGetDocument({ documentType, invoiceNumber, token }).subscribe(
      (response: any) => {
        if (response?.errors?.length > 0) {
          this.bannerNotificationsService.error('Problem downloading invoice: ' + response.errors[0].message);
          this.logger.error('Invoice documents download - error', response.errors);
          return;
        }
        this.logger.log('Invoices - GET PDF', response);
        const isPdf = documentType === InvoiceDownloadTypes.PDF;
        const contentType = isPdf ? 'application/pdf' : 'text/csv';
        const statementData = response?.data;
        const downloadFileName = `Machool - Invoice ${invoiceNumber}`;
        const blob = this.helpersService.b64toBlob(statementData, contentType);
        FileSaver.saveAs(blob, `${downloadFileName}.${documentType}`);
        if (response) {
          this.bannerNotificationsService.success('File was downloaded successfully');
        }
        return response;
      },
      (err: Error) => {
        this.bannerNotificationsService.error('Downloading file failed');
        this.logger.error('GET invoices document', err);
      }
    );
  }
}
