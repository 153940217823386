import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ReportsComponent } from '@app/pages/authenticated/pages/reports/reports.component';
import { ReportCardComponent } from '@app/pages/authenticated/pages/reports/components/report-card/report-card.component';
import { MachoolReportsComponent } from '@app/pages/authenticated/pages/reports/components/machool-reports/machool-reports.component';
import { ACCReportsComponent } from '@app/pages/authenticated/pages/reports/components/acc-reports/acc-reports.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_REPORTS] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: ReportsComponent,
        data: {
          scopes: [AdminScopes.VIEW_REPORTS],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [RouterModule],
  declarations: [
    ACCReportsComponent,
    MachoolReportsComponent,
    ReportCardComponent,
    ReportsComponent,
  ],
})
export class ReportsModule {}
