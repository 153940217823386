import * as _ from 'lodash';
import * as FileSaver from 'file-saver';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AdminUser } from '@app/shared/services/user.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import atLeastOneRequired from '@app/shared/validators/at-least-one-required.validator';
import { LoggerService } from '@app/shared/services/logger.service';
import { CompanyInvoice } from '../../../users/users.model';
import { DefaultTimezone, InvoiceDownloadTypes, Mailers, PanelWidths } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { PageEvent } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import { InvoicesService } from '@app/shared/services/invoices.service';
import { BillingInvoicesService, InvoiceBy, InvoiceStatuses } from '@app/shared/services/billing-invoices.services';
import { SelectionModel } from '@angular/cdk/collections';
import { MailerService } from '@app/shared/services/mailer.service';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { InvoicePaymentsListComponent } from '../../../invoices/components/invoice-payments-list/invoice-payments-list.component';
import { CreatePaymentComponent } from '../../../invoices/components/create-payment/create-payment.component';
import { ViewDocumentComponent } from '../../../../components/view-document/view-document.component';
import { CurrencyPipe } from '@angular/common';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
})
export class InvoicesListComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @Input() user: AdminUser;
  @Input() canViewCustomerInvoices: boolean = false;
  @Input() canEditAndEmailInvoices: boolean = false;
  @Input() selectedCompanyName: string;
  @Output() tabChanged: EventEmitter<any> = new EventEmitter<any>();

  public loading: boolean = true;
  public filterForm: FormGroup | undefined;
  public currentPage: number = 0;
  public itemsPerPage: number = 100;
  public itemsPerPageOptions: number[] = [100, 150, 200];
  public paginatedInvoices: any[] = [];
  public displayedColumns: string[] = [
    'select',
    'invoiceNumber',
    'paidBy',
    'companyName',
    'date',
    'dateRange',
    'due',
    'amount',
    'outstanding',
    'status',
    'action',
  ];

  private openingModal: boolean = false;
  public invoices: any[] = [];
  public invoicesCount: number = 0;
  public invoicesTotalOutstanding: number = 0;

  public readonly invoiceStatusesDropdown = [
    { id: '', text: 'Any' },
    { id: 'NC', text: InvoiceStatuses.NEWLY_CREATED },
    { id: 'O', text: InvoiceStatuses.OPEN },
    { id: 'P', text: InvoiceStatuses.PAID },
    { id: 'OD', text: InvoiceStatuses.OVERDUE },
    { id: 'PP', text: InvoiceStatuses.PARTIALLY_PAID },
    { id: 'PO', text: InvoiceStatuses.PARTIALLY_OVERDUE },
  ];

  public dateRange = {
    start: DateTime.now().startOf('day').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };
  public disableDatePicker: boolean = false;
  public oneYearDateRange = {
    start: DateTime.now().minus({ year: 1 }).startOf('day').toJSDate(),
    end: DateTime.now().endOf('day').toJSDate(),
  };
  public invoiceMethodValue: InvoiceBy = InvoiceBy.DATE_RANGE;
  public range = new FormGroup({
    start: new FormControl<Date | null>(undefined),
    end: new FormControl<Date | null>(undefined),
  });
  selection = new SelectionModel<any>(true, []);

  private currencyPipe = new CurrencyPipe('en');
  public defaultTimezone = DefaultTimezone;
  public invoicesDataSet: any;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private invoiceService: InvoicesService,
    private mailerService: MailerService,
    private bannerNotificationsService: BannerNotificationsService,
    private drawer: MtxDrawer,
    private billingInvoicesService: BillingInvoicesService
  ) {}

  ngOnInit() {
    this.loading = true;
    if (this.canViewCustomerInvoices) {
      this.createForm(true);
      if (this.selectedCompanyName) {
        this.loadInvoices();
      } else {
        this.loading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.tabChanged.emit();
  }

  public updateDateDisabled(updateEvent: any) {
    this.disableDatePicker = updateEvent?.value === InvoiceStatuses.NEWLY_CREATED;
  }

  private loadInvoices() {
    // When coming from customers page to view invoices for the selected company
    if (this.selectedCompanyName) {
      this.filterForm.patchValue({
        companyName: this.selectedCompanyName,
        status: 'Any',
      });
      const query = {
        company_name: this.selectedCompanyName,
      };
      this.disableDatePicker = false;
      this.search(false, query);
    } else {
      this.search(true);
    }
    this.loading = false;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public sortData(sort: Sort) {
    const data = this.paginatedInvoices.slice();
    if (!sort.active || sort.direction === '') {
      this.invoicesDataSet = data;
      return;
    }

    this.invoicesDataSet = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'invoiceNumber':
          return this.compare(a.invoiceNumber, b.invoiceNumber, isAsc);
        case 'paidBy':
          return this.compare(a.billingInvoicePaymentInfo.type, b.billingInvoicePaymentInfo.type, isAsc);
        case 'companyName':
          return this.compare(a.companyName, b.companyName, isAsc);
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'due':
          return this.compare(a.dueDate, b.dueDate, isAsc);
        case 'amount':
          return this.compare(Number(a.amount), Number(b.amount), isAsc);
        case 'outstanding':
          return this.compare(Number(a.balanceDue), Number(b.balanceDue), isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  public reset() {
    this.createForm(true);
    this.paginatedInvoices = [];
    this.selectedCompanyName = '';
  }

  public search(reset: boolean = true, withQueryProvider?: any) {
    this.currentPage = reset ? 0 : this.currentPage;
    const filterValues = (this.filterForm || {}).value;
    const query = withQueryProvider
      ? withQueryProvider
      : {
          status:
            filterValues.status === InvoiceStatuses.NEWLY_CREATED || filterValues.status === 'Any'
              ? ''
              : filterValues.status,
          invoice_number: filterValues?.invoiceNumber ? filterValues?.invoiceNumber.trim() : '',
          company_name: filterValues?.companyName ? filterValues?.companyName.trim() : '',
        };

    // Only add date search if necessary
    if (!withQueryProvider && (this.disableDatePicker || (this.range.value.start && this.range.value.end))) {
      const startDate = this.disableDatePicker ? DateTime.now() : DateTime.fromJSDate(this.range.value.start);
      const endDate = this.disableDatePicker ? DateTime.now() : DateTime.fromJSDate(this.range.value.end);

      _.extend(query, {
        start_date: startDate.startOf('day').toUTC().toISO({ includeOffset: false }),
        end_date: endDate.endOf('day').toUTC().toISO({ includeOffset: false }),
      });
    }
    this.invoices = [];
    this.invoicesCount = 0;

    this.invoiceService.getInvoicesByStatus(query).subscribe(
      (res) => {
        this.logger.log('Invoices by status GET invoices', res);
        this.invoicesCount = res.invoicesCount;
        this.invoices = res.invoices;
        this.invoices.map((invoice: any) => {
          invoice.className = this.billingInvoicesService.convertStatusToClass(invoice);
          invoice.companyName = invoice?.company?.name ? invoice.company.name : 'N/A';
          invoice.csvStatementAvailable = this.invoiceService.isInvoiceSummaryPath(invoice.invoiceUrl);
          invoice.pdfStatementAvailable = !invoice.company?.settings?.noBillingStatement;
          invoice.billingInvoicePaymentInfo = invoice.company?.billingInvoicePaymentInfo;
        });

        const end = this.itemsPerPage * (this.currentPage + 1);
        const start = end - this.itemsPerPage;
        this.paginatedInvoices = this.invoices.slice(start, end);

        this.invoicesDataSet = new MatTableDataSource(this.paginatedInvoices);
        this.invoicesDataSet.sort = this.sort;
      },
      (error) => {
        this.logger.log('Invoices - GET invoices error', error);
        this.invoicesCount = 0;
        this.invoices = [];
      }
    );
  }

  private createForm(clearForm: boolean = false) {
    this.filterForm = this.formBuilder.group({
      companyName: ['', []],
      invoiceNumber: ['', []],
      status: ['Any', []],
      startDate: [undefined],
      endDate: [undefined],
    });

    // Null out and disable the date picker since it's now set to NEWLY_CREATED
    this.range.patchValue({
      start: null,
      end: null,
    });

    this.disableDatePicker = !clearForm;

    this.filterForm.setValidators(atLeastOneRequired([]));
  }

  public addInvoicePayment(invoice: CompanyInvoice): void {
    if (invoice.company) {
      const drawerRef = this.drawer.open(CreatePaymentComponent, {
        width: PanelWidths.desktopFull,
        disableClose: true,
        closeOnNavigation: false,
        data: { company: invoice.company, invoice },
      });
      drawerRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.search(true);
        }
      });
    }
  }

  public previewInvoice(selectedInvoice: CompanyInvoice): void {
    this.billingInvoicesService
      .getStatementsDetails({
        invoiceNumber: selectedInvoice.invoiceNumber,
        summaryOnly: true,
      })
      .subscribe(
        (response: any) => {
          this.logger.log('Invoices - GET PDF', response);
          const { pdfSummary } = response;
          const downloadFileName = pdfSummary
            ? `Machool - Invoice Summary ${selectedInvoice.invoiceNumber}.pdf`
            : `Machool - Invoice ${selectedInvoice.invoiceNumber}.pdf`;

          if (!this.openingModal) {
            this.drawer.open(ViewDocumentComponent, {
              width: this.helpersService.getDrawerWidth(),
              disableClose: true,
              closeOnNavigation: false,
              data: {
                document: pdfSummary ? pdfSummary.data : response.data,
                downloadFileName,
                previewTitle: 'View Invoice',
              },
            });
            this.openingModal = false;
          }
        },
        (err: Error) => {
          this.openingModal = false;
          this.logger.error('Invoices - GET PDF', err);
        }
      );
  }

  public downloadInvoice(selectedInvoice: CompanyInvoice, downloadType: string): void {
    this.billingInvoicesService
      .getStatementsDetails({
        invoiceNumber: selectedInvoice.invoiceNumber,
        statementsOnly: true,
      })
      .subscribe(
        (response: any) => {
          this.logger.log('Invoices - GET PDF', response);
          const { pdfStatement, csvStatement, zipStatement } = response;
          const isPdf = downloadType === InvoiceDownloadTypes.PDF;
          const downloadFileName = `Machool - Invoice ${selectedInvoice.invoiceNumber}`;
          const hasCsvData = !!csvStatement?.data;
          const fileExtension = isPdf ? 'pdf' : hasCsvData ? 'csv' : 'zip';
          const fullFileName = `${downloadFileName}.${fileExtension}`;
          let contentType: string;
          let statementData: string | undefined;
          if (isPdf) {
            contentType = 'application/pdf';
            statementData = pdfStatement?.data;
          } else if (hasCsvData) {
            contentType = 'text/csv';
            statementData = csvStatement?.data;
          } else if (zipStatement) {
            contentType = 'application/zip';
            statementData = zipStatement?.data;
          } else {
            this.logger.error('No statement data available for download.', statementData);
            return;
          }
          // Not able to download CSV for older statement;
          if (!statementData && downloadType === InvoiceDownloadTypes.CSV) {
            return;
          }
          const data = statementData || response.data;
          const blob = this.helpersService.b64toBlob(data, contentType);
          FileSaver.saveAs(blob, fullFileName);
        },
        (err: Error) => {
          this.logger.error('Invoices - GET PDF', err);
        }
      );
  }

  public viewInvoicePayments(invoice: CompanyInvoice): void {
    const drawerRef = this.drawer.open(InvoicePaymentsListComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { company: invoice.company, invoice },
    });
    drawerRef.afterDismissed().subscribe((result) => {
      if (result) {
        if (_.get(result, 'addPayment')) {
          this.addInvoicePayment(result.addPayment);
        } else {
          this.search(true);
        }
      }
    });
  }

  public deleteInvoice(selectedInvoice: CompanyInvoice): void {
    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Delete invoice #${selectedInvoice.invoiceNumber}`,
          content: `Are you sure you want to delete this invoice?`,
          confirmBtnLabel: 'Delete',
          btnColor: 'warn',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingInvoicesService.deleteInvoice(selectedInvoice.id).subscribe(
            () => {
              this.search(true);
              this.bannerNotificationsService.success('Invoice was deleted');
            },
            (err: Error) => {
              this.logger.error('Invoices - DELETE invoice error', err);
              this.bannerNotificationsService.error(
                `There was a problem deleting this invoice ${_.get(err, 'error.message', '')}`
              );
            }
          );
        }
        this.openingModal = false;
      });
    }
  }

  public downloadInvoices(invoices) {
    if (invoices && invoices.length > 0) {
      const invoiceNumbers = invoices.map((invoice) => invoice.invoiceNumber);
      this.billingInvoicesService.getZippedInvoices({ invoiceNumbers }).subscribe(
        (response: any) => {
          const blob = this.helpersService.b64toBlob(response.data, 'application/octet-stream');
          FileSaver.saveAs(blob, `Machool Invoices.zip`);
        },
        (err: Error) => {
          this.logger.error('Invoices - GET ALL PDF', err);
        }
      );
    }
  }

  public sendEmail(invoices) {
    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Send invoice to ${invoices.length} customers`,
          content: `Are you sure you want to send these invoices?`,
          confirmBtnLabel: 'Send email',
          btnColor: 'primary',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const templateData: any = {
            additionalData: {
              isBatch: true,
              invoices: JSON.stringify(invoices),
            },
          };

          this.mailerService.send(Mailers.SEND_CUSTOMER_INVOICE, templateData).subscribe(
            (response: any) => {
              this.loading = false;
              this.logger.log('Customer Invoice - Send Email', response);
              this.bannerNotificationsService.success('Sending email job started successfully.');
              this.selection.clear();
              this.openingModal = false;
            },
            (err: any) => {
              this.logger.error('Invoice email send error', err);
              this.loading = false;
              this.bannerNotificationsService.error('Problem Sending Invoices: ' + err.message);
              this.openingModal = false;
              this.selection.clear();
            }
          );
        } else {
          this.openingModal = false;
        }
      });
    }
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.invoices.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    } else {
      this.selection.select(...this.invoices);
    }
  }

  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  public addBulkInvoicePayment(invoices: any[]) {
    const selectedInvoices = this.selection.selected;
    this.invoicesTotalOutstanding =
      selectedInvoices.length > 0 ? this.getTotalOutstandingBalance(this.selection.selected) : 0;
    const totalAmount = this.currencyPipe.transform(this.invoicesTotalOutstanding, null, 'symbol', '1.2-2');
    const filteredInvoices = invoices.filter((invoice) => {
      return invoice.balanceDue != 0;
    });

    if (this.invoicesTotalOutstanding === 0) {
      this.logger.log('Total amount selected for payments is 0');
      this.bannerNotificationsService.error('Total amount selected for payments is 0');

      return;
    }

    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Adding payments to ${filteredInvoices.length} companies`,
          content: `Are you sure you want to add a payment of <strong>${totalAmount}</strong> to clear the outstanding balance for these companies?`,
          confirmBtnLabel: 'Add Payments',
          btnColor: 'primary',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const queryData: any = {
            isBatch: true,
            invoices: JSON.stringify(filteredInvoices),
          };

          this.billingInvoicesService.createBulkInvoicePayments(queryData).subscribe(
            (response: any) => {
              this.logger.log('Customer Invoice - Create Bulk Payments', response);
              this.bannerNotificationsService.success('Bulk Invoice payment job started ...');
              this.selection.clear();
              this.openingModal = false;
            },
            (err: any) => {
              this.bannerNotificationsService.error('Problem creating invoice payment: ' + (err.message || err));
              this.logger.error('Customer Invoice - Create Bulk Payment error', err);
              this.openingModal = false;
              this.selection.clear();
            }
          );
        } else {
          this.openingModal = false;
        }
      });
    }
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedInvoices = this.invoices.slice(start, end);
    this.invoicesDataSet = this.paginatedInvoices;
  }

  private getTotalOutstandingBalance(invoices: any[]) {
    return invoices.reduce((sum, invoice) => sum + Number(invoice?.balanceDue || 0), 0);
  }
}
