<ng-container *ngIf='!loading, else isLoading'>

  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    dynamicHeight
    animationDuration='0ms'
    [selectedIndex]="selectedTab"
    (selectedIndexChange)='selectedTab = $event'
  >
    <mat-tab *ngFor='let tab of tabs' [label]='tab.label'>
      <ng-template matTabContent>
        <div class='my--lg'>
          <app-courier-invoices
            *ngIf="tab.id === 'app-courier-invoices'"
            [user]='user'
          ></app-courier-invoices>
          <app-machool-invoices
            *ngIf="tab.id === 'app-machool-invoices'"
            [user]='user'
          ></app-machool-invoices>
          <app-not-invoiced-by-courier
            *ngIf="tab.id === 'app-not-invoiced-by-courier'"
            [user]='user'
          ></app-not-invoiced-by-courier>
          <app-not-invoiced-by-machool
            *ngIf="tab.id === 'app-not-invoiced-by-machool'"
            [user]='user'
          ></app-not-invoiced-by-machool>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
