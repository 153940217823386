import { Component, Input, OnInit } from '@angular/core';

export enum AlertSeverity {
  WARNING = 'warning',
  DANGER = 'danger',
  INFO = 'INFO',
}

@Component({
  selector: 'app-alert-banners',
  templateUrl: 'alert-banners.component.html',
  styleUrls: ['alert-banners.component.scss'],
})
export class AlertBannersComponent implements OnInit {
  @Input() notification: {
    severity: AlertSeverity;
    message: string;
    class?: string;
    icon?: string;
  };
  constructor() {}

  ngOnInit() {
    if (this.notification) {
      switch (this.notification.severity) {
        case AlertSeverity.WARNING:
          this.notification.icon = 'icon-warn';
          this.notification.class = 'warning';
          break;
        case AlertSeverity.DANGER:
          this.notification.icon = 'icon-warn';
          this.notification.class = 'danger';
          break;
        case AlertSeverity.INFO:
          this.notification.icon = 'icon-info';
          this.notification.class = 'info';
          break;
        default:
          this.notification.icon = 'icon-warn';
          this.notification.class = 'danger';
          break;
      }
    }
  }
}
