import { Component, Inject, OnInit } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { DefaultTimezone } from '@app/shared/constants';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
})
export class CreateInvoiceComponent implements OnInit {
  public loading: boolean = true;
  public company: Company;
  public lastInvoiceNumber: number;
  public invoiceForm: FormGroup;
  public defaultTimezone = DefaultTimezone;

  public id: number;
  public invoiceNumber: string;
  public dueDate: Date | string = DateTime.now().plus({ day: 7 }).toJSDate();
  public customInvoiceCreatedAtDate: Date | string = DateTime.now().toJSDate();
  public dateRange = {
    start: DateTime.now().setZone(this.defaultTimezone).minus({ day: 7 }).toJSDate(),
    end: DateTime.now()
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .toJSDate(),
  };

  public range = new FormGroup({
    start: new FormControl<Date | null>(this.dateRange.start, [Validators.required]),
    end: new FormControl<Date | null>(this.dateRange.end, [Validators.required]),
  });
  public applyCreditCardFee: boolean = false;

  constructor(
    public drawerRef: MtxDrawerRef<CreateInvoiceComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { company: Company; lastInvoiceNumber: number },
    private formBuilder: FormBuilder,
    private billingInvoicesService: BillingInvoicesService,
    private bannerNotificationsService: BannerNotificationsService,
    private logger: LoggerService
  ) {}
  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.lastInvoiceNumber = this.data.lastInvoiceNumber;
    this.dueDate = DateTime.now()
      .plus({ day: this?.company?.billingNetDays ? this.company.billingNetDays : 7 })
      .toJSDate();
    this.setupForm();
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public create(): void {
    this.loading = true;
    const invoice = this.invoiceForm.value;
    delete invoice.id;
    const dueDate = DateTime.fromJSDate(invoice.dueDate).setZone(this.defaultTimezone).startOf('day').toISO();
    const customInvoiceCreatedAtDate = DateTime.fromJSDate(invoice.customInvoiceCreatedAtDate)
      .setZone(this.defaultTimezone)
      .startOf('day')
      .toISO();
    const account = _.clone(this.company.owner);
    const queryData: any = {
      dueDate,
      invoiceNumber: invoice.invoiceNumber.trim(),
      currentAccount: _.extend(account, { company: this.company }),
      applyCreditCardFee: invoice.applyCreditCardFee,
      customCreatedAtDate: customInvoiceCreatedAtDate,
    };
    const startDate = DateTime.fromJSDate(this.range.value.start).setZone(this.defaultTimezone).startOf('day');
    const endDate = DateTime.fromJSDate(this.range.value.end)
      .setZone(this.defaultTimezone)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    _.extend(queryData, {
      startDate: startDate.toISO(),
      startDateUTC: startDate.toUTC().toISO(),
      endDate: endDate.toISO(),
      endDateUTC: endDate.toUTC().toISO(),
      isBatch: false,
    });

    this.billingInvoicesService.createInvoicePdf(queryData).subscribe(
      (response: any) => {
        if (response?.errors?.length > 0) {
          this.bannerNotificationsService.error('Problem creating invoice: ' + response.errors[0].message, {
            category: 'Invoices - Create PDF error',
            message: response.errors,
          });
          this.loading = false;
          this.drawerRef.dismiss('complete');
          return;
        }
        this.logger.log('Invoices - Create PDF invoice', response);
        this.loading = false;
        this.bannerNotificationsService.success('Invoice creation job started successfully.');
        this.drawerRef.dismiss('complete');
      },
      (err: any) => {
        if (err === 'Request timed out') {
          // Invoice creation is taking too long, so let user go while request processes
          this.loading = false;
          this.drawerRef.dismiss('processing');
        } else {
          this.logger.error('Invoices - Create Invoice error', err);
          this.loading = false;
        }
      }
    );
  }

  private setupForm() {
    this.invoiceForm = this.formBuilder.group({
      id: [this.id],
      invoiceNumber: [
        this.lastInvoiceNumber ? `MA${this.lastInvoiceNumber + 1}` : this.invoiceNumber,
        [Validators.required],
      ],
      dueDate: [this.dueDate, [Validators.required]],
      customInvoiceCreatedAtDate: [this.customInvoiceCreatedAtDate],
      startDate: [this.dateRange.start, [Validators.required]],
      endDate: [this.dateRange.end, [Validators.required]],
      applyCreditCardFee: [this?.company?.settings?.applyCreditCardFee || this.applyCreditCardFee],
    });
  }
}
