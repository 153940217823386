<h1>{{ isEditing ? 'Edit' : 'Add' }} Card</h1>
<form [formGroup]="cardForm" (ngSubmit)='saveCard()'>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Card name</mat-label>
        <input matInput type="text" formControlName="name">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Top section image url</mat-label>
        <input matInput type="text" formControlName="imageUrl">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Top section image url FR</mat-label>
        <input matInput type="text" formControlName="imageUrlFR">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12 mb--md'>
      <quill-editor class="w-100" name="body" formControlName='body' [maxLength]="maxInputLen" [required]='true'></quill-editor>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Additional button label (Optional)</mat-label>
        <input matInput type="text" formControlName="buttonLabel">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Additional button link (Optional)</mat-label>
        <input matInput type="text" formControlName="buttonUrl">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class="full-width">
        <mat-label>Additional button link FR (Optional)</mat-label>
        <input matInput type="text" formControlName="buttonUrlFR">
      </mat-form-field>
    </div>
  </div>
  <div *ngIf='isEditing' class='row'>
    <div class='column--12'>
      <mat-checkbox formControlName='saveAsNew' value='1'>Save as a new card</mat-checkbox>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--4'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
    </div>
    <div class='column--8 align-right'>
      <span class='mr--md' [matTooltip]="!canDelete ? cannotDeleteMessage : undefined" matTooltipPosition="above">
        <button
          *ngIf='isEditing'
          mat-flat-button
          color='warn'
          type='button'
          (click)='deleteCard()'
          [disabled]='!canDelete'
        >DELETE Card</button>
      </span>
      <button mat-flat-button color='primary' type='submit' [disabled]='cardForm.invalid'>{{ isEditing ? 'Update' : 'Save' }}</button>
    </div>
  </div>
</form>
