<div class='mt--md'>
  <form [formGroup]='providerSettingsForm' novalidate>
    <div class='row pb--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Margin rate (%)</mat-label>
          <mat-icon matSuffix>percent</mat-icon>
          <input id='markupPercentageValue' matInput type="number" formControlName="markupPercentageValue" name='markupPercentageValue' (keyup)="keyPressed($event)" max='100' min='0' step='0.01' />
          <mat-hint class='text--xs' align="start">Adjust your cost by adding (or subtracting) a margin to the Machool rate by percentage.</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class='row pb--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Markup rate ($)</mat-label>
          <mat-icon matPrefix>attach_money</mat-icon>
          <input id='markupDollarValue' matInput type="number" formControlName="markupDollarValue" name='markupDollarValue' (keyup)="keyPressed($event)" max='100000' min='1' step='0.01' />
          <mat-hint class='text--xs' align="start">Adjust your cost by marking up (or absorbing) the Machool rate by a specific $ amount</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class='row pb--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Adjust expected delivery date</mat-label>
          <div matSuffix style="position: relative; bottom: 1px; right: 9px;">days</div>
          <input id='deliveryDate' matInput type="number" formControlName="deliveryDate" name='deliveryDate' (keyup)="keyPressed($event)" max='10' min='0' step='1' />
          <mat-hint class='text--xs' align="start">Adjust the expected delivery date by adding the corresponding amount of business days</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="providerHasFuelSurcharges" class='row pb--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Fuel surcharges (%)</mat-label>
          <mat-icon matSuffix>percent</mat-icon>
          <input id='fuelSurchargeMarkupMargin' matInput type="number" formControlName="fuelSurchargeMarkupMargin" name='fuelSurchargeMarkupMargin' (keyup)="keyPressed($event)" max='100' min='0' step='0.01' />
          <mat-hint class='text--xs' align="start">Adjust your cost by marking up (or absorbing) the Machool rate by percentage</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="canEditStatus" class='row pb--md'>
      <div class='column--10'>
        <mat-form-field class="full-width">
          <mat-label>Edit Courier Status</mat-label>
          <mat-select formControlName="enabled">
            <mat-option [value]="true">Enable</mat-option>
            <mat-option [value]="false">Disable</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>

