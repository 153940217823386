import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company, CompanyChargeDto } from '@app/pages/authenticated/pages/users/users.model';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { BillingName, BillingTypes, CompanyChargeCode, DateFormats, ShipmentChargeStatus } from '@app/shared/constants';
import { environment } from '../../../../../../../../environments/environment';
import { PageEvent } from '@angular/material/paginator';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { PaymentsService } from '@app/shared/services/payments.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-company-charges',
  templateUrl: './company-charges.component.html',
})
export class CompanyChargesComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public companyCharges: CompanyChargeDto[] = [];
  public loading = true;
  public canEdit: boolean = false;
  public DATE_YEAR: string = DateFormats.DATE_YEAR_SML;
  public paginatedCharges: CompanyChargeDto[] = [];
  public currentPage: number = 0;
  public itemsPerPage: number = 10;
  public CDN_URL: string = environment.CDN_URL;
  public displayedColumns: string[] = ['date', 'type', 'description', 'cost', 'actions'];
  public chargeTypesDropdown: { id: string; text: string }[];
  public createChargeForm: FormGroup;
  private currencyPipe = new CurrencyPipe('en');
  public CompanyChargeCode = CompanyChargeCode;

  constructor(
    private companyService: CompanyService,
    private userService: UserService,
    private logger: LoggerService,
    private dialog: MatDialog,
    private router: Router,
    private paymentsService: PaymentsService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_COMPANY_CHARGES])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_COMPANY_CHARGES]);

      // note: remove address complete item for now
      this.chargeTypesDropdown = [
        {
          id: CompanyChargeCode.PICKUP_FEE,
          text: this.mapVerboseChargeCode(CompanyChargeCode.PICKUP_FEE),
        },
        {
          id: CompanyChargeCode.API_SUBSCRIPTION,
          text: this.mapVerboseChargeCode(CompanyChargeCode.API_SUBSCRIPTION),
        },
        {
          id: CompanyChargeCode.STORE_PURCHASE,
          text: this.mapVerboseChargeCode(CompanyChargeCode.STORE_PURCHASE),
        },
        {
          id: CompanyChargeCode.ECOMMERCE_PLUGIN,
          text: this.mapVerboseChargeCode(CompanyChargeCode.ECOMMERCE_PLUGIN),
        },
      ];
      this.buildForm();
      this.getCompanyCharges();
    } else {
      this.router.navigate(['users']);
    }
  }

  public getCompanyCharges() {
    this.companyService.getCompanyCharges(this.company.id).subscribe((charges) => {
      this.logger.log(`GET Company Charges`, charges);
      this.resetForm();
      this.companyCharges = charges;
      this.companyCharges?.forEach((companyCharge: CompanyChargeDto) => {
        companyCharge.rates = this.mapChargeToRateFormat(companyCharge);
      });
      this.paginatedCharges = this.companyCharges.slice(0, this.itemsPerPage);
      this.loading = false;
    });
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedCharges = this.companyCharges.slice(start, end);
  }

  public mapVerboseChargeCode(chargeCode: CompanyChargeCode): string {
    switch (chargeCode) {
      case CompanyChargeCode.PICKUP_FEE:
        return 'Pickup Fee';
      case CompanyChargeCode.STORE_PURCHASE:
        return 'Supply Store Order';
      case CompanyChargeCode.API_SUBSCRIPTION:
        return 'API Subscription Fee';
      case CompanyChargeCode.ADDRESS_CORRECT:
        return 'Address Correction Fee';
      case CompanyChargeCode.ECOMMERCE_PLUGIN:
        return 'E-Commerce Plugin';
    }
  }

  public cancelCharge(charge: CompanyChargeDto) {
    const message = `Are you sure you want to <strong>cancel and refund</strong> this transaction?`;
    this.dialog
      .open(ModalConfirmationComponent, {
        width: '600px',
        data: {
          title: `Cancel transaction`,
          content: message,
          confirmBtnLabel: 'DELETE',
          btnColor: 'warn',
        },
      })
      .afterClosed()
      .subscribe((cancelConfirmed) => {
        if (!cancelConfirmed) return;

        const paymentDto = {
          action: 'approve',
          chargeUuid: charge.uuid,
          createdAt: charge.chargedAt,
        };

        this.paymentsService.csrCompanyRefund(paymentDto).subscribe((res) => {
          this.logger.log(`CANCELLED Company charge`, res);
          this.getCompanyCharges();
        });
      });
  }

  public resetForm() {
    this.createChargeForm.reset();
    this.createChargeForm.setValue({ type: '', baseAmount: 0, trackingNumber: '', orderUuid: '', shippingAmount: 0 });
  }

  public buildForm() {
    this.createChargeForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      baseAmount: [0, [Validators.required, Validators.min(0.01)]],
      trackingNumber: [''],
      orderUuid: [''], // note, may pivot to order number down the line depending on visibility
      shippingAmount: [0, [Validators.min(0)]],
    });

    this.createChargeForm.get('type').valueChanges.subscribe((value) => {
      // make order uuid mandatory if store purchase selected
      if (value === CompanyChargeCode.STORE_PURCHASE) {
        this.createChargeForm.controls['orderUuid'].setValidators([Validators.required]);
      } else {
        this.createChargeForm.controls['orderUuid'].clearValidators();
      }
      this.createChargeForm.controls['orderUuid'].updateValueAndValidity();
    });
  }

  public selectedChargeType(): CompanyChargeCode {
    return this.createChargeForm.value['type'];
  }

  public issueCharge(): void {
    this.loading = true;
    const getBillingNameFromType = (type: BillingTypes) => {
      switch (type) {
        case BillingTypes.CREDIT_CARD:
          return BillingName.CREDIT_CARD;
        case BillingTypes.MACHOOL_ACCOUNT:
          return BillingName.MACHOOL_ACCOUNT;
        case BillingTypes.MACHOOL_WALLET:
          return BillingName.MACHOOL_WALLET;
        case BillingTypes.THIRDPARTY_ACCOUNT:
          throw new Error(`Third party billing not supported`);
      }
    };

    const shouldUseWallet = parseFloat(`${this.company.walletBalance || 0}`) > 0;
    const billingType =
      shouldUseWallet && this.company.billingType === BillingTypes.CREDIT_CARD
        ? BillingTypes.MACHOOL_WALLET
        : this.company.billingType;

    const userObj = {
      company: this.company,
      ...this.user,
    };

    const chargeDto = {
      code: this.createChargeForm.value['type'],
      baseAmount: this.createChargeForm.value['baseAmount'],
      shippingAmount: this.createChargeForm.value['shippingAmount'],
      paymentType: billingType,
      paymentName: getBillingNameFromType(billingType),
      metadata: {
        trackingNumber: this.createChargeForm.value['trackingNumber'] || undefined,
        orderUuid: this.createChargeForm.value['orderUuid'] || undefined,
      },
      user: userObj,
    };
    this.companyService.createCompanyCharge(this.company.id, chargeDto).subscribe((res) => {
      this.getCompanyCharges();
    });
  }

  // TODO: This logic should move to backend and reuse in app-cli and admin-portal
  public mapChargeToRateFormat(charge: CompanyChargeDto): any[] {
    const rates: {
      label: string;
      amount: number;
      list?: any[];
      discount?: any;
      type?: string;
      infoMessage?: string;
      stripeReceiptUrl?: string;
      adjustment?: boolean;
      adjustmentGroup?: boolean;
    }[] = [];

    rates.push({
      label: this.mapVerboseChargeCode(charge.code),
      amount: charge.baseAmount,
    });

    if (charge.shippingAmount) {
      rates.push({
        label: `surcharges`,
        amount: charge.shippingAmount,
        list: [
          {
            code: 'SHIPPING',
            name: 'Shipping',
            amount: charge.shippingAmount,
          },
        ],
      });
    }

    const sumTaxes = (charge.gstAmount || 0) + (charge.hstAmount || 0) + (charge.qstAmount || 0);

    const taxes = {
      label: 'taxes',
      amount: sumTaxes,
      list: [],
    };
    taxes.list.push(
      {
        code: 'GST',
        name: 'GST',
        amount: charge.gstAmount,
      },
      {
        code: 'HST',
        name: 'HST',
        amount: charge.hstAmount,
      },
      {
        code: 'QST',
        name: 'QST',
        amount: charge.qstAmount,
      }
    );
    rates.push(taxes);

    rates.push({
      label: 'total',
      amount: charge.totalAmount,
    });

    const isRefunded = charge ? charge.status === ShipmentChargeStatus.REFUND_APPROVED : false;
    const chargeTypeName = isRefunded ? 'Refunded' : 'Paid';
    if (charge.walletTransaction) {
      rates.push({
        label: `<span>${chargeTypeName}</span> <span>${DateTime.fromISO(charge.chargedAt).toFormat(
          this.DATE_YEAR
        )}</span> <span>by Machool Wallet</span>`,
        amount: charge.totalAmount,
        adjustmentGroup: true,
        adjustment: false,
      });
    } else {
      rates.push({
        label: `<span>${chargeTypeName}</span> <span>${DateTime.fromISO(charge.chargedAt).toFormat(
          this.DATE_YEAR
        )}</span>`,
        amount: charge.totalAmount,
        adjustment: false,
        stripeReceiptUrl: charge.stripeReceiptUrl,
        adjustmentGroup: true,
      });
    }
    return rates;
  }

  public showSection(rate): boolean {
    // There are some surcharges or options that are included with amount 0,
    // So we as long as we have something in their list we should display them,
    // for other rates we should just make sure the amount is not 0
    if (rate.label === 'surcharges' || rate.label === 'options') {
      return rate.list && rate.list.length > 0;
    } else {
      return rate.amount !== 0 || (rate.amount === 0 && _.get(rate, 'valueIncluded', false));
    }
  }

  public showAmount(rate): boolean {
    return (
      rate.label !== 'options' &&
      rate.label !== 'surcharges' &&
      rate.label !== 'taxes' &&
      rate.label !== 'total' &&
      rate.label !== 'discount' &&
      rate.amount > 0
    );
  }

  public getReceipt(receiptUrl: string) {
    if (!receiptUrl) return;
    window.open(
      receiptUrl,
      'targetWindow',
      `
      toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=700,
      height=900`
    );
  }

  public getTaxRatesLabel(rate) {
    if (!rate.name && rate.code && rate.code.length > 0) {
      if (this.isQstOrPstTax(rate.code.toLowerCase())) {
        return 'QST';
      } else {
        return rate.code.toUpperCase();
      }
    } else {
      if (this.isQstOrPstTax(rate.name.toLowerCase())) {
        return 'QST';
      } else {
        return rate.name.toUpperCase();
      }
    }
  }

  private isQstOrPstTax(taxCode): boolean {
    return taxCode.includes('qst') || taxCode.includes('pst');
  }

  public getRateValue(value) {
    const returnValue = _.isString(value) ? value : this.currencyPipe.transform(value, null, 'symbol', '1.2-2');
    return !returnValue || _.isEmpty(returnValue) || _.isNull(returnValue) ? 'Included' : returnValue;
  }
}
