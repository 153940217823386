<h1>{{ data && data.courierModifier ? 'Update' : 'Add new' }} modifier</h1>
<ng-container *ngIf='!loading && company, else isLoading'>
  <form [formGroup]="courierModifierForm">
    <div class='row mt--md'>
      <div class='column--12'>
        <mat-form-field style='min-width: 240px;'>
          <mat-label>Courier</mat-label>
          <mat-select formControlName="provider" name='provider' (selectionChange)='selectCourier($event)'>
            <mat-option *ngFor='let courier of couriersList' value="{{ courier.provider }}">{{ courier.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf='selectedCourier && lastMileProviders && lastMileProviders.length > 0'>
          <mat-form-field style='min-width: 240px;' class='ml--md'>
            <mat-label>Last Mile</mat-label>
            <mat-select formControlName="lastMileProvider" name='lastMileProvider'>
              <mat-option *ngFor='let lmCourier of lastMileProviders' value="{{ lmCourier.provider }}">{{ lmCourier.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
      <div class='column--12'>
        <mat-form-field>
          <mat-label>Markup rate (%)</mat-label>
          <mat-icon matSuffix>percent</mat-icon>
          <input matInput type='number' id="markupPercentageValue" formControlName="markupPercentageValue" (keyup)="keyPressed($event)" />
        </mat-form-field>
        <p class='mt--none'><small>Adjust your cost by making up (or absorbing) the Machool rate by percentage</small></p>
      </div>
      <div class='column--12'>
        <mat-form-field>
          <mat-label>Markup rate ($)</mat-label>
          <mat-icon matPrefix>attach_money</mat-icon>
          <input matInput type='number' id="markupDollarValue" formControlName="markupDollarValue" (keyup)="keyPressed($event)" />
        </mat-form-field>
        <p class='mt--none'><small>Adjust your cost by making up (or absorbing) the Machool rate by a specific $ amount</small></p>
      </div>
      <div class='column--12'>
        <mat-form-field>
          <mat-label>Delivery date</mat-label>
          <div matSuffix style="position: relative; bottom: 1px; right: 9px;">days</div>
          <input matInput type='number' formControlName="deliveryDate" />
        </mat-form-field>
        <p class='mt--none'><small>Adjust the expected delivery date by adding the corresponding amount of days</small></p>
      </div>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' [disabled]='!courierModifierForm.valid' (click)='saveModifier()'>Save</button>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
