import { Component, Inject, OnInit } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';

@Component({
  selector: 'app-admin-charge-details',
  templateUrl: './admin-charge-details.component.html'
})
export class AdminChargeDetailsComponent implements OnInit {
  public shipment: any;
  public adminChargeDetailsStringified: string;

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    public drawerRef: MtxDrawerRef<AdminChargeDetailsComponent>,
    @Inject(MTX_DRAWER_DATA) public data: { shipment: any },
  ) {}

  ngOnInit() {
    if (this.data && this.data.shipment) {
      this.shipment = this.data.shipment;
      this.adminChargeDetailsStringified = JSON.stringify(this.shipment.adminChargeDetails, undefined, 3);
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }
}
