<ng-container *ngIf='!loading, else isLoading'>
  <div class='container'>
    <div class='row'>
      <div class='column--12'>Service Builder</div>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
