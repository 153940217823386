<form *ngIf='!loading && company && paymentTypeForm, else isLoading' [formGroup]="paymentTypeForm">
  <div class='row mt--md'>
    <div class='column--12'>
      <label>Billing Type: </label>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-radio-button
        class='text--sm'
        color='primary'
        name="billingType"
        (change)='updateBillingType($event)'
        [checked]="paymentTypeForm.get('billingType').value === billingTypesEnum.CREDIT_CARD"
        [value]="billingTypesEnum.CREDIT_CARD"
        [disabled]='cannotChangeBillingType'
      >{{ convertBillingTypeToVerbose(billingTypesEnum.CREDIT_CARD) }}</mat-radio-button>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-radio-button
        class='text--sm'
        color='primary'
        name="billingType"
        [disabled]='cannotChangeBillingType || company.hasChildrenCompanies'
        (change)='updateBillingType($event)'
        [checked]="paymentTypeForm.get('billingType').value === billingTypesEnum.THIRDPARTY_ACCOUNT"
        [value]="billingTypesEnum.THIRDPARTY_ACCOUNT"
      >
        {{ convertBillingTypeToVerbose(billingTypesEnum.THIRDPARTY_ACCOUNT) }}
        {{ company.parentCompany && company.parentCompany.name ? '(Billed to: ' + company.parentCompany.name + ')' : ''}}
      </mat-radio-button>
    </div>
  </div>
  <div class='row'>
    <div class='column--12 column-md--4 pl--lg'>
      <mat-form-field class='full-width'>
        <mat-label>Email address</mat-label>
        <input matInput formControlName='email' name='email' value="" >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-radio-button
        class='text--sm'
        color='primary'
        name="billingType"
        (change)='updateBillingType($event)'
        [checked]="paymentTypeForm.get('billingType').value === billingTypesEnum.MACHOOL_ACCOUNT"
        [value]="billingTypesEnum.MACHOOL_ACCOUNT"
        [disabled]='cannotChangeBillingType'
      >{{ convertBillingTypeToVerbose(billingTypesEnum.MACHOOL_ACCOUNT) }}</mat-radio-button>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--12 pl--lg'>
      <mat-form-field>
        <mat-label>Credit Limit</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input matInput type='number' formControlName="billingLimit" [value]="paymentTypeForm.get('billingLimit').value"/>
      </mat-form-field>

      <span class='optional-text ml--md'>Between $100 and $999,999</span>
    </div>
    <div class='column--12 pl--lg'>
      <p class='mt--none pt--none'>Customers who exceed this balance cannot create any shipments until a payment is made. A warning banner will appear when they are at 90% of their limit.</p>
    </div>
  </div>
  <div class='row my--md'>
    <div class='column--12 pl--lg'>
      <mat-form-field>
        <mat-label>Net # days</mat-label>
        <input matInput type='number' formControlName="billingNetDays" [value]="paymentTypeForm.get('billingNetDays').value"/>
      </mat-form-field>
    </div>
    <div class='column--12 pl--lg'>
      <p class='mt--none pt--none'>Number of days added to the current date to determine when an invoice is due.</p>
    </div>
  </div>
  <div class='row mt--xs mb--md'>
    <div class='column--8 pb--none mb--none'>
      <label>Invoice paid by:</label>
    </div>
    <div class='column--8 pt--none mt--none pl--none'>
      <mat-radio-group class="text--sm" formControlName='invoicePaymentMethod' [(ngModel)]="invoicePaymentTypeMethod">
        <mat-radio-button color='primary' *ngFor='let invoicePaymentType of invoicePaymentTypes' [value]="invoicePaymentType.value">{{invoicePaymentType.name}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class='row mt--md'>
    <div class='column--12'>
      <mat-checkbox class="text--sm" color='primary' formControlName="shippingDisabled"><span class='no-bold'>Disable creating shipments function and display alert message</span></mat-checkbox>
    </div>
    <div class='column--12 pl--lg'>
      <mat-form-field appearance="outline" class='w-100'>
        <textarea matInput formControlName='shippingDisabledMessage' rows='4'></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--12'>
      <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || paymentTypeForm.invalid || loading" (click)='updatePaymentType()'>Save</button>
    </div>
  </div>
</form>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
