import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modal-edit-courier-surcharges',
  templateUrl: './modal-edit-courier-surcharges.component.html',
  styleUrls: ['./modal-edit-courier-surcharges.component.scss'],
})
export class ModalEditCourierSurchargesComponent implements OnInit {
  public provider: any;
  public providerName;
  public providerSurcharges;
  public surchargesForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ModalEditCourierSurchargesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      provider?: any;
    },
    private formBuilder: FormBuilder,
  ) {
    this.provider = this.data.provider;
  }

  ngOnInit() {
    this.providerSurcharges = this?.provider?.surcharges;
    this.providerName = this?.provider?.provider;
    this.buildForm();
  }

  private buildForm(): void {
    const formGroupConfig = {};
    this.providerSurcharges.forEach((providerSurcharge) => {
      formGroupConfig[providerSurcharge.code] =  [providerSurcharge.amount]
    })
    this.surchargesForm = this.formBuilder.group(formGroupConfig);
  }

  public updateSurcharges(): any {
    const updatedSurcharges = this.providerSurcharges.map(item => {
      if (this.surchargesForm.value.hasOwnProperty(item.code)) {
        return { ...item, amount: this.surchargesForm.value[item.code] };
      } else {
        return item;
      }
    });
    this.dialogRef.close(updatedSurcharges);
  }
}
