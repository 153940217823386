import { Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { first } from 'rxjs';
import * as _ from 'lodash';
import {
  EDI_INVOICE_MODAL_TYPE,
  InsuranceProviders,
  PanelWidths,
  ShipmentStatuses,
  ShippingProviderNames,
  ShippingProviders,
  ThirdPartyInsuranceProviderStatus,
} from '../../../../shared/constants';
import { HelpersService } from '../../../../shared/services/helpers.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { AdminScopes, AdminUser, UserService } from '../../../../shared/services/user.service';
import { UnknownShipmentsService } from '../../../../shared/services/unknown-shipments.service';
import { ProvidersService } from '../../../../shared/services/providers.service';
import { AdjustmentsService } from '../../../../shared/services/adjustments.service';
import { EdiInvoiceComponent } from '../../components/edi-invoice/edi-invoice.component';
import { ModalsAssignUnknownTrackingComponent } from '../reconciliation/modals/assign-unknown-tracking/modals-assign-unknown-tracking.component';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-unknown-tracking',
  templateUrl: './unknown-tracking.component.html',
})
export class UnknownTrackingComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  public sortAscending: SortDirection = 'desc';
  public loading: boolean = true;
  public user: AdminUser;
  public canEdit: boolean = false;
  public CDN_URL: string = environment.CDN_URL;

  public unknownShipments = [];
  public unknownShipmentsCount = 0;
  public paginatedUnknownShipments = [];
  public unknownShipmentsDataSet: any;

  public currentPage: number = 0;
  public itemsPerPage: number = 150;
  public itemsPerPageOptions: number[] = [150, 200, 250];
  public displayedColumns: string[] = [
    'date',
    'unknownType',
    'provider',
    'trackingNumber',
    'reference',
    'cost',
    'action',
  ];
  public providerItems = [
    { id: '', value: '', text: 'All' },
    {
      id: ShippingProviders.PUROLATOR,
      value: ShippingProviders.PUROLATOR,
      text: ShippingProviderNames.PUROLATOR,
    },
    { id: ShippingProviders.DHL, value: ShippingProviders.DHL, text: ShippingProviderNames.DHL },
    { id: ShippingProviders.GLS, value: ShippingProviders.GLS, text: ShippingProviderNames.GLS },
    {
      id: ShippingProviders.NATIONEX,
      value: ShippingProviders.NATIONEX,
      text: ShippingProviderNames.NATIONEX,
    },
    {
      id: ShippingProviders.CANPAR,
      value: ShippingProviders.CANPAR,
      text: ShippingProviderNames.CANPAR,
    },
    {
      id: ShippingProviders.FEDEX,
      value: ShippingProviders.FEDEX,
      text: ShippingProviderNames.FEDEX,
    },
    { id: ShippingProviders.RIVO, value: ShippingProviders.RIVO, text: ShippingProviderNames.AIR_CANADA },
    {
      id: ShippingProviders.CANADA_POST,
      value: ShippingProviders.CANADA_POST,
      text: ShippingProviderNames.CANADA_POST,
    },
    {
      id: ShippingProviders.CHASSEURS_COURRIER,
      value: ShippingProviders.CHASSEURS_COURRIER,
      text: ShippingProviderNames.CHASSEURS_COURRIER,
    },
    {
      id: ShippingProviders.UNIUNI,
      value: ShippingProviders.UNIUNI,
      text: ShippingProviderNames.UNIUNI,
    },
    {
      id: ShippingProviders.FLASHBOX,
      value: ShippingProviders.FLASHBOX,
      text: ShippingProviderNames.FLASHBOX,
    },
    { id: ShippingProviders.UPS, value: ShippingProviders.UPS, text: ShippingProviderNames.UPS },
    {
      id: ShippingProviders.ICS_COURIER,
      value: ShippingProviders.ICS_COURIER,
      text: ShippingProviderNames.ICS_COURIER,
    },
    { id: ShippingProviders.LIVRAPIDE, value: ShippingProviders.LIVRAPIDE, text: ShippingProviderNames.LIVRAPIDE },
    {
      id: ShippingProviders.CANADA_POST_3PL,
      value: ShippingProviders.CANADA_POST_3PL,
      text: ShippingProviderNames.CANADA_POST_3PL,
    },
    { id: ShippingProviders.DELIVRO, value: ShippingProviders.DELIVRO, text: ShippingProviderNames.DELIVRO },
    { id: ShippingProviders.DAYROSS, value: ShippingProviders.DAYROSS, text: ShippingProviderNames.DAYROSS },
  ];
  public trackingNumber: string = '';
  public searchForm: FormGroup | undefined;

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private drawer: MtxDrawer,
    private bannerNotificationsService: BannerNotificationsService,
    private formBuilder: FormBuilder,
    private unknownShipmentsService: UnknownShipmentsService,
    private providersService: ProvidersService,
    private adjustmentsService: AdjustmentsService
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_UNKNOWN_TRACKING])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_UNKNOWN_TRACKING]);
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
    this.createForm();
    this.getUnknownShipments();
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      trackingNumber: [this.trackingNumber || ''],
      provider: [''],
    });
  }

  public reset() {
    this.createForm();
    this.getUnknownShipments();
  }

  public sortData(sort: Sort) {
    const data = this.paginatedUnknownShipments.slice();
    if (!sort.active || sort.direction === '') {
      this.unknownShipmentsDataSet = data;
      return;
    }

    this.unknownShipmentsDataSet = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'date':
          return this.compare(a.date, b.date, isAsc);
        case 'provider':
          return this.compare(a.provider, b.provider, isAsc);
        case 'cost':
          return this.compare(Number(a.totalAmount), Number(b.totalAmount), isAsc);
        default:
          return 0;
      }
    });
  }

  public search(reset: boolean = true) {
    this.loading = true;
    this.currentPage = reset ? 0 : this.currentPage;
    const searchValues = (this.searchForm || {}).value;
    const queryData: any = {
      tracking_number: searchValues.trackingNumber || '',
      provider: searchValues.provider,
      type: 'U',
    };
    this.getUnknownShipments(queryData);
  }

  private getUnknownShipments(withQuery = false) {
    this.loading = true;
    const query = withQuery
      ? withQuery
      : {
          type: 'U',
        };

    this.unknownShipments = [];
    this.unknownShipmentsCount = 0;
    this.paginatedUnknownShipments = [];

    this.unknownShipmentsService.findAllUnknownShipments(query).subscribe(
      (res: any) => {
        this.logger.log('Unknown Shipments, GET All Unknown Shipments', res);
        this.unknownShipments = res.unknownShipments;
        this.unknownShipmentsCount = res.unknownShipmentsCount;
        const end = this.itemsPerPage * (this.currentPage + 1);
        const start = end - this.itemsPerPage;
        this.paginatedUnknownShipments = this.unknownShipments.slice(start, end);
        this.unknownShipmentsDataSet = new MatTableDataSource(this.paginatedUnknownShipments);
        this.unknownShipmentsDataSet.sort = this.sort;
        this.loading = false;
      },
      (err: Error) => {
        this.logger.error('Unknown Shipments, GET All Unknown Shipments error', err);
        this.loading = false;
        this.unknownShipments = [];
        this.unknownShipmentsCount = 0;
      }
    );
  }

  public getProviderImage(provider: string): string {
    const mappedProvider = provider === ShippingProviders.RIVO ? 'ac' : provider;
    return this.providersService.getProviderSmallLogo(mappedProvider);
  }

  public getProviderName(provider: ShippingProviders, service?: any): string {
    return this.helpersService.convertShipmentProviderToString(provider, service);
  }

  public getInsuranceProviderName(insuranceProvider: InsuranceProviders): string {
    return this.helpersService.convertInsuranceProviderToString(insuranceProvider);
  }

  public openEdiInvoiceDrawer(shipment) {
    this.drawer.open(EdiInvoiceComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment, type: EDI_INVOICE_MODAL_TYPE.UNKNOWN_TRACKING },
    });
  }

  public getTrackingReference(trackingNumber: any, defaultValue: 'More Info' | 'DO NOT BILL RECON ADJ') {
    if (!trackingNumber.data) return defaultValue;
    if (!trackingNumber.data.providerInvoiceItem) return defaultValue;
    if (!trackingNumber.data.providerInvoiceItem.extras) return defaultValue;
    if (trackingNumber.data.shipmentStatus) {
      if (trackingNumber.data.shipmentStatus === ThirdPartyInsuranceProviderStatus.CANCELLED) return 'CANCELLED';
      if (trackingNumber.data.shipmentStatus === ShipmentStatuses.CANCELLED) return 'CANCELLED';
      if (trackingNumber.data.shipmentStatus === ShipmentStatuses.PENDING_CANCEL) return 'PENDING CANCEL';
    }

    const { extras } = trackingNumber.data.providerInvoiceItem;

    return (
      extras.customerRef1 ||
      extras.customerRef2 ||
      extras.customerRef3 ||
      extras.customerRef4 ||
      extras.customerRef5 ||
      extras.customerNamePU ||
      extras.customerNameDel ||
      extras.extraInfo ||
      extras.reference ||
      defaultValue
    );
  }

  public getBadgeClass(data: any): string {
    return this.isInsuranceProvider(data?.provider || '') ? 'unconfirmed' : 'created';
  }

  public getStatusText(data: any): string {
    return this.isInsuranceProvider(data?.provider || '')
      ? `${this.getInsuranceProviderName(data?.provider)}`
      : 'Shipping Provider';
  }

  public isInsuranceProvider(provider: InsuranceProviders | string): boolean {
    return [InsuranceProviders.UPIC, InsuranceProviders.MARSH].includes(provider as InsuranceProviders);
  }

  // We use Canada post for AirCanada return
  private hasMultiLegTrackingAssigned(assignToTrackingNumber: string, provider: ShippingProviders): boolean {
    return (
      assignToTrackingNumber &&
      assignToTrackingNumber.startsWith('MA') &&
      [ShippingProviders.CANADA_POST].includes(provider)
    );
  }

  public assignUnknownTrackingNumber(unknownTrackingNumber) {
    const parsedUnknownData = unknownTrackingNumber.data;
    const { createdAt, trackingNumber, totalAmount, providerInvoiceItem, provider } = parsedUnknownData;
    const isInsuranceProvider = this.isInsuranceProvider(provider);
    const getBadgeClass = this.getBadgeClass(providerInvoiceItem);
    const getStatusText = this.getStatusText(providerInvoiceItem);

    const dialogRef = this.dialog.open(ModalsAssignUnknownTrackingComponent, {
      width: '1000px',
      data: {
        createdAt: createdAt,
        trackingNumber: trackingNumber,
        reference: this.getTrackingReference(unknownTrackingNumber, 'More Info'),
        totalAmount: totalAmount,
        provider,
        providerDisplayName: this.helpersService.convertShipmentProviderToString(provider),
        providerImageSrc: this.getProviderImage(provider),
        isInsuranceProvider,
        getBadgeClass,
        getStatusText,
      },
    });
    dialogRef.afterClosed().subscribe((modalSubmitValue) => {
      if (!modalSubmitValue) return;

      const assignToTrackingNumber = modalSubmitValue.assignToTrackingNumber.trim();
      if (_.isEmpty(assignToTrackingNumber)) return;

      const hasMultiLegTrackingAssigned = this.hasMultiLegTrackingAssigned(assignToTrackingNumber, provider);

      let query = {
        unknownShipmentId: unknownTrackingNumber.id,
        reasonType: modalSubmitValue.reasonType,
        assignToTrackingNumber,
        insuranceProviderType: providerInvoiceItem.insuranceProviderType,
        ...parsedUnknownData,
      };

      query = hasMultiLegTrackingAssigned ? { ...query, provider: ShippingProviders.RIVO } : query;

      this.adjustmentsService.assignUnknownTracking(query).subscribe(
        (response: { adjustmentCreated: boolean }) => {
          if (response.adjustmentCreated === true) {
            this.currentPage = 0;
            this.getUnknownShipments();
            this.bannerNotificationsService.success('Tracking number assigned successfully');
          } else {
            this.bannerNotificationsService.error('Tracking number could not be assigned');
          }
        },
        (error) => {
          this.bannerNotificationsService.error('Tracking number could not be assigned');
          this.logger.error('Unknown Tracking, Tracking number could not be assigned', error);
        }
      );
    });
  }

  public ignoreUnknownTrackingNumber(unknownTrackingNumber) {
    this.adjustmentsService
      .ignoreUnknownTracking({
        unknownShipmentId: unknownTrackingNumber.id,
      })
      .subscribe(
        (response: boolean) => {
          if (response === true) {
            this.currentPage = 0;
            this.getUnknownShipments();
            this.bannerNotificationsService.success('Tracking number ignored successfully');
          } else {
            this.bannerNotificationsService.error('Tracking number could not be ignored');
          }
        },
        (error) => {
          this.bannerNotificationsService.error('Tracking number could not be ignored');
          this.logger.error('Unknown Tracking, Tracking number could not be ignored', error);
        }
      );
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedUnknownShipments = this.unknownShipments.slice(start, end);
    this.unknownShipmentsDataSet = this.paginatedUnknownShipments;
  }

  public copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.bannerNotificationsService.success('Tracking number copied to clipboard');
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
