import { NgModule } from '@angular/core';
import { ModalConfirmationComponent } from './modal-confirmation/modal-confirmation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SvgAnimationComponent } from '@app/shared/components/svg-animation/svg-animation.component';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { AlertBannersComponent } from '@app/shared/components/alert-banners/alert-banners.component';
import { NgForOf, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [AlertBannersComponent, LoadingComponent, ModalConfirmationComponent],
  imports: [MatIconModule, MatButtonModule, MatDialogModule, NgForOf, NgIf, SvgAnimationComponent],
  exports: [AlertBannersComponent, LoadingComponent, ModalConfirmationComponent, SvgAnimationComponent],
})
export class ComponentsModule {}
