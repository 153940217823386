<h1>{{ data && data.note ? 'Update' : 'Add' }} Note</h1>
<form [formGroup]="noteForm">
  <div class='row mt--md'>
    <div class='column--12'>
      <mat-form-field>
        <mat-select formControlName="type" name='type'>
          <mat-option *ngFor='let noteType of (companyNoteType | enumValuesToArray)' value="{{ noteType }}">{{ convertEnumToVerbose(noteType) }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class='column--12'>
      <mat-form-field appearance="outline" class='w-100'>
        <mat-label>Note</mat-label>
        <textarea matInput formControlName='body' rows='4'></textarea>
      </mat-form-field>
    </div>
    <div class='column--6'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
    </div>
    <div class='column--6 align-right'>
      <button mat-flat-button color='primary' (click)='saveNote()'>Save</button>
    </div>
  </div>
</form>
