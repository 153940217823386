import { Component, Inject, Input, OnInit } from '@angular/core';
import { Company, CompanyInvoice } from '@app/pages/authenticated/pages/users/users.model';
import { DateFormats, PanelWidths } from '@app/shared/constants';
import { MTX_DRAWER_DATA, MtxDrawer, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import * as _ from 'lodash';
import { BillingInvoicesService } from '@app/shared/services/billing-invoices.services';
import { LoggerService } from '@app/shared/services/logger.service';
import { DateTime } from 'luxon';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-invoice-payments-list',
  templateUrl: './invoice-payments-list.component.html',
})
export class InvoicePaymentsListComponent implements OnInit {
  @Input() company: Company;
  @Input() invoice: CompanyInvoice;

  public loading: boolean = true;
  public invoicePaymentDataSet: any;
  public DATE_YEAR_SML: string = DateFormats.DATE_YEAR_SML;
  public displayedColumns = ['paymentDate', 'amount', 'description', 'action'];
  private openingDrawer: boolean = false;
  private openingModal: boolean = false;

  constructor(
    public drawerRef: MtxDrawerRef<InvoicePaymentsListComponent>,
    @Inject(MTX_DRAWER_DATA)
    public data: { company: Company; invoice: CompanyInvoice },
    private billingInvoicesService: BillingInvoicesService,
    private logger: LoggerService,
    private dialog: MatDialog,
    private bannerNotificationsService: BannerNotificationsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.company = this.data.company;
    this.invoice = this.data.invoice;
    (this.invoice?.invoicePayments || []).forEach((payment) => {
      payment.formattedDate = DateTime.fromISO(payment.paymentDate, {
        zone: 'America/Toronto',
      }).toFormat(DateFormats.DATE_YEAR_SML);
    });
    this.invoicePaymentDataSet = (this.invoice?.invoicePayments || []).sort((a, b) =>
      a.paymentDate < b.paymentDate ? -1 : 1
    );
    this.loading = false;
  }

  public dismissDrawer(): void {
    this.drawerRef.dismiss(false);
  }

  public addInvoicePayment(invoice: CompanyInvoice): void {
    this.drawerRef.dismiss({ addPayment: invoice });
  }

  public deleteInvoicePayment(invoicePayment: any): void {
    if (!this.openingModal) {
      this.openingModal = true;
      const dialogRef = this.dialog.open(ModalConfirmationComponent, {
        data: {
          title: `Delete invoice payment`,
          content: `Are you sure you want to delete this payment for: $${invoicePayment.amount}?`,
          confirmBtnLabel: 'Delete',
          btnColor: 'warn',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.billingInvoicesService.deleteInvoicePayment(invoicePayment.id).subscribe(
            () => {
              this.drawerRef.dismiss(true);
              this.bannerNotificationsService.success('Invoice payment was deleted');
            },
            (err: Error) => {
              this.logger.error('Invoices - DELETE invoice payment error', err);
              this.bannerNotificationsService.error(
                `There was a problem deleting this invoice payment${_.get(err, 'error.message', '')}`
              );
            }
          );
        }
        this.openingModal = false;
      });
    }
  }
}
