<h1>{{ data && data.dynamicPricing ? 'Update' : 'Add new' }} Dynamic pricing</h1>
<ng-container *ngIf="!loading && company; else isLoading">
  <form [formGroup]="dynamicPricingForm">
    <div class="row mt--md">
      <div class="column--5">
        <mat-form-field style="min-width: 230px">
          <mat-label>Source</mat-label>
          <mat-select formControlName="sourceCourier" name="sourceCourier">
            <mat-option *ngFor="let sourceCourier of sourceCouriers" value="{{ sourceCourier.provider }}">{{
              sourceCourier.name
            }}</mat-option>
          </mat-select>
          <mat-hint class="text--xs" align="start">Cheapest service</mat-hint>
        </mat-form-field>
      </div>
      <div class="column--1 display-flex justify-content--flex-end">
        <p class="display-inline-flex mr--sm ml--sm"><small>vs</small></p>
      </div>
      <div class="column--5">
        <mat-form-field style="min-width: 230px">
          <mat-label>Courier</mat-label>
          <mat-select formControlName="targetCourier" name="targetCourier">
            <mat-option *ngFor="let targetCourier of targetCouriers" value="{{ targetCourier.provider }}">{{
              targetCourier.name
            }}</mat-option>
          </mat-select>
          <mat-hint class="text--xs" align="start">Cheapest service</mat-hint>
        </mat-form-field>
      </div>
      <div class="row">
        <div class="column--4">
          <p class="display-inline-flex">Minimum margin</p>
        </div>
        <div class="column--4">
          <mat-form-field class="w-100">
            <mat-icon matSuffix>percent</mat-icon>
            <input matInput type="number" id="minMarginPercentageValue" formControlName="minMarginPercentageValue" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="column--4">
          <p class="display-inline-flex">Beat price by</p>
        </div>
        <div class="column--4">
          <mat-form-field class="w-100">
            <mat-icon matSuffix>percent</mat-icon>
            <input
              matInput
              type="number"
              id="beatPriceByPercentageValue"
              formControlName="beatPriceByPercentageValue"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="column--6">
        <button mat-flat-button color="accent" (click)="dismissDrawer()">Cancel</button>
      </div>
      <div class="column--6 align-right">
        <button mat-flat-button color="primary" [disabled]="!dynamicPricingForm.valid" (click)="saveDynamicPricing()">
          Save
        </button>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #isLoading>
  <div class="align-center mt--lg">
    <app-loading></app-loading>
  </div>
</ng-template>
