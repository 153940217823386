import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';

const atLeastOneRequired = (ignoredControls: string[]): any => {

  return (controlGroup: FormGroup) => {
    const controls = controlGroup.controls;

    if (controls) {
      const controlsToCheck = _.difference(Object.keys(controls), ignoredControls);
      const theOne = controlsToCheck.find(key => controls[key].value !== '');
      if (!theOne) {
        return { atLeastOneRequired: true };
      }
    }
    return null;
  };
};

export default atLeastOneRequired;
