import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { BillingTypes, BillingTypesVerbose, InvoicePaymentTypes, Roles } from '@app/shared/constants';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import * as _ from 'lodash';
import { HelpersService } from '@app/shared/services/helpers.service';
import { MatRadioChange } from '@angular/material/radio';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-payment-type',
  templateUrl: './payment-type.component.html',
})
export class PaymentTypeComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();
  public billingTypesEnum = BillingTypes;

  // @ts-ignore
  public paymentTypeForm: FormGroup;
  public billingContactsArray: FormArray = new FormArray([]);
  public limitDisabled: boolean = true;
  public parentLookupDisabled: boolean = true;
  public loading = true;
  public canEdit: boolean = false;
  public hasParentId: boolean = false;
  public billingType: BillingTypes;
  public invoicePaymentTypes: { value: InvoicePaymentTypes; name: string }[] = [
    { value: InvoicePaymentTypes.PAD, name: InvoicePaymentTypes.PAD },
    { value: InvoicePaymentTypes.CREDIT_CARD, name: 'Credit card (add 3%)' },
    { value: InvoicePaymentTypes.OTHER, name: 'Other' },
  ];
  public invoicePaymentTypeMethod: InvoicePaymentTypes = InvoicePaymentTypes.PAD;
  public cannotChangeBillingType: boolean = false;

  private defaultShippingDisabledMessage: string =
    'Your account currently has an overdue balance of [outstanding balance], please make a payment to continue shipping.';
  private parentCompanyId: number | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private helpersService: HelpersService,
    private logger: LoggerService,
    private router: Router,
    private dialog: MatDialog,
    private bannerNotificationsService: BannerNotificationsService
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_PAYMENT_TYPE])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_PAYMENT_TYPE]);
      this.billingType = this.companyService.getBillingType(this.company);
      this.hasParentId = !!this.company.parentCompany;
      this.parentCompanyId = _.get(this.company, 'parentCompany.id')
        ? parseInt(_.get(this.company, 'parentCompany.id'), 10)
        : null;
      this.invoicePaymentTypeMethod =
        this.company?.settings?.billingInvoicePaymentType || this.invoicePaymentTypeMethod;
      this.cannotChangeBillingType =
        (this.billingType === BillingTypes.MACHOOL_ACCOUNT && this.company.billingAmount > 0) ||
        (this.billingType === BillingTypes.CREDIT_CARD && this.company.walletBalance > 0);
      this.updateDisabledFlags();
      this.createForm();
    } else {
      this.router.navigate(['users']);
    }
  }

  public createForm(): void {
    if (this.company) {
      const defaultBillingLimit =
        this.billingType === BillingTypes.MACHOOL_ACCOUNT && this.company.billingLimit
          ? this.company.billingLimit
          : 500;
      this.paymentTypeForm = this.formBuilder.group({
        email: ['', []],
        billingType: [{ value: this.billingType, disabled: !this.canEdit }, { validators: [Validators.required] }],
        billingLimit: [
          { value: Math.floor(defaultBillingLimit), disabled: !this.canEdit },
          {
            validators: [Validators.required, Validators.max(999999), Validators.min(100)],
          },
        ],
        billingNetDays: [
          { value: this.company.billingNetDays, disabled: !this.canEdit },
          { validators: [Validators.required] },
        ],
        invoicePaymentMethod: [
          { value: this.invoicePaymentTypeMethod, disabled: !this.canEdit },
          { validators: [Validators.required] },
        ],
        shippingDisabled: [
          {
            value: _.get(this.company, 'settings.shippingDisabled', false),
            disabled: !this.canEdit,
          },
        ],
        shippingDisabledMessage: [
          {
            value: _.get(this.company, 'settings.shippingDisabled', false)
              ? this.company.settings.shippingDisabledMessage || this.defaultShippingDisabledMessage
              : this.defaultShippingDisabledMessage,
            disabled: !this.canEdit,
          },
        ],
      });

      this.updateDisabled();
    }
  }

  public updateBillingType(event: MatRadioChange): void {
    this.paymentTypeForm.patchValue({
      billingType: event.value,
    });
    this.billingType = event.value;
    this.updateDisabledFlags();
  }

  public updatePaymentType(): void {
    if (this.canEdit) {
      if (this.billingType === BillingTypes.THIRDPARTY_ACCOUNT) {
        // if 3rd party is newly selected (value in email) try to get the company and confirm
        this.confirmThirdParty();
      } else {
        this.parentCompanyId = null;
        // if it isn't 3rd party, just update
        this.updateCompanyPaymentDetails();
      }
    }
  }

  public convertBillingTypeToVerbose(billingType: string): BillingTypesVerbose {
    return this.helpersService.convertBillingTypesEnumToString(billingType as BillingTypes);
  }

  public updateDisabled(): void {
    if (this.paymentTypeForm && this.canEdit) {
      if (this.parentLookupDisabled) {
        this.paymentTypeForm.get('email').disable();
      } else {
        this.paymentTypeForm.get('email').enable();
      }

      if (this.limitDisabled) {
        this.paymentTypeForm.get('billingLimit').disable();
        this.paymentTypeForm.get('billingNetDays').disable();
      } else {
        this.paymentTypeForm.get('billingLimit').enable();
        this.paymentTypeForm.get('billingNetDays').enable();
        this.paymentTypeForm.get('invoicePaymentMethod').enable();
      }
      this.paymentTypeForm.updateValueAndValidity();
    }
    this.loading = false;
  }

  private updateDisabledFlags(): void {
    this.parentLookupDisabled = this.billingType !== BillingTypes.THIRDPARTY_ACCOUNT || !!this.company.parentCompany;
    this.limitDisabled = this.billingType !== BillingTypes.MACHOOL_ACCOUNT;
    this.updateDisabled();
  }

  private confirmThirdParty(): void {
    if (this.canEdit) {
      if (this.company.hasParentCompany && this.paymentTypeForm.get('email').disabled) {
        this.updateCompanyPaymentDetails(this.company.parentCompany.billingType);
      } else {
        if (_.get(this.paymentTypeForm.get('email'), 'value', '').length === 0) {
          this.bannerNotificationsService.error('Email address must be specified.');
          return;
        } else {
          this.userService.getFromAll({ email: this.paymentTypeForm.get('email').value }).subscribe(
            (accounts) => {
              this.logger.log('Users Lookup GET accounts', accounts);

              if (!accounts || accounts.length === 0) {
                // if we couldn't find an account for that email
                this.bannerNotificationsService.error('Email address is not in our database.');
              } else if (accounts.length > 1) {
                // if we found more than 1 account by that email
                this.bannerNotificationsService.error('We found more than one account with that email.');
              } else {
                const companyOwner =
                  accounts[0].role === Roles.OWNER || accounts[0].role === Roles.MACHOOL ? accounts[0] : false;
                if (!companyOwner) {
                  // if company is found, email supplied isn't the owner
                  this.bannerNotificationsService.error('Email address is not an owner fo a company.');
                } else if (_.get(companyOwner, 'company.parentCompany.name')) {
                  // if company is found, but is already a child, throw an error
                  this.bannerNotificationsService.error('The company is already a child account.');
                } else {
                  // All checks passed, so prompt for confirmation before we update the company settings
                  const parentBillingTypeVerbose = this.companyService.getBillingTypeVerbose(companyOwner.company);

                  const dialogRef = this.dialog.open(ModalConfirmationComponent, {
                    width: '600px',
                    data: {
                      title: 'Confirmation',
                      content: `The payment type for ${companyOwner.company.name} is <strong>${parentBillingTypeVerbose} billing</strong>`,
                      confirmBtnLabel: 'Confirm',
                    },
                  });
                  dialogRef.afterClosed().subscribe((isConfirmed) => {
                    if (isConfirmed) {
                      this.parentCompanyId = companyOwner.company.id;
                      this.updateCompanyPaymentDetails(companyOwner.company.billingType);
                    }
                  });
                }
              }
            },
            (error) => {
              this.logger.log('Users Lookup GET accounts ERROR', error);
              this.bannerNotificationsService.error('There was a problem finding the company by that email.');
            }
          );
        }
      }
    }
  }

  private updateCompanyPaymentDetails(overrideBillingType?: BillingTypes): void {
    const formValues = this.paymentTypeForm.value;
    const updateDto = {
      billingType: overrideBillingType ? overrideBillingType : formValues.billingType,
      billingLimit: formValues.billingLimit,
      billingNetDays: formValues.billingNetDays,
      settings: {
        shippingDisabled: formValues.shippingDisabled,
        shippingDisabledMessage: formValues.shippingDisabledMessage,
        billingInvoicePaymentType: formValues.invoicePaymentMethod,
      },
      parentCompanyId: this.parentCompanyId,
    };

    this.companyService.update(this.company.uuid, updateDto).subscribe(
      (response: any) => {
        this.logger.log('Modals Billing Type - UPDATE Payment method', response);
        this.refreshCompany.emit();
        this.bannerNotificationsService.success('Payment method updated');
      },
      (err: any) => {
        this.bannerNotificationsService.error(
          `There was a problem updating the Payment method. ${_.get(err, 'error.message', '')}`
        );
        this.logger.error('Modals Billing Type -UPDATE Payment method error', err);
      }
    );
  }
}
