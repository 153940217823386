<ng-container *ngIf='!loading, else isLoading'>
  <div class='container mx--none'>
    <ng-container *ngIf='canViewCards || canViewTopRevenue'>
      <form [formGroup]="filterForm">
        <div class='row'>
          <div class='column--3'>
            <mat-form-field class='full-width'>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class='column--4'>
            <mat-form-field class='full-width'>
              <mat-label>Provinces</mat-label>
              <mat-select formControlName="provinces" name='provinces' placeholder='All provinces' multiple>
                <mat-option *ngFor='let option of provincesDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--3'>
            <mat-form-field class='full-width'>
              <mat-label>Couriers</mat-label>
              <mat-select formControlName="couriers" name='couriers' placeholder='All couriers' multiple>
                <mat-option *ngFor='let option of couriersDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class='column--2 with-gutter'>
            <mat-form-field class='full-width'>
              <mat-label>Account types</mat-label>
              <mat-select formControlName="accountTypes" name='accountTypes' placeholder='All types' multiple>
                <mat-option *ngFor='let option of accountTypeDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class='row'>
          <div class='column--12 pt--xs pr--md align-right'>
            <button mat-flat-button color='primary' type='submit' (click)='getAllInformation()' [disabled]='isAnyCardLoading()'>Search</button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf='canViewCards'>
      <div class='row mt--md'>
        <div class='column--12'>
          <h3>Accounts</h3>
        </div>
      </div>
      <div class='row'>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #accountSubStats>(Active accounts: {{accountsWithOne}})</ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingAccountsCard"
            [loadingCardFailed]="loadingAccountsCardFailed"
            iconClass="icon-user bg-report-red"
            titleClass="text-report-red"
            cardTitle="ALL ACCOUNTS"
            [totalStat]="totalAccounts"
            [subStatsTemplate]="accountSubStats"
            (refresh)="refreshAccountsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.ACCOUNTS)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingNewAccountsCard"
            [loadingCardFailed]="loadingNewAccountsCardFailed"
            iconClass="icon-users bg-report-red"
            titleClass="text-report-red"
            cardTitle="NEW SIGNUPS"
            [totalStat]="totalNewAccounts"
            (refresh)="refreshNewAccountsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.NEW_ACCOUNTS)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm with-gutter'>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingStartedShippingCard"
            [loadingCardFailed]="loadingStartedShippingCardFailed"
            iconClass="icon-users bg-report-red"
            titleClass="text-report-red"
            cardTitle="STARTED SHIPPING"
            [totalStat]="totalStartedShipping"
            (refresh)="refreshStartedShippingCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.STARTED_SHIPPING)'
          ></app-report-card>
        </div>
      </div>
      <div class='row mt--md'>
        <div class='column--12'>
          <h3>Revenue</h3>
        </div>
      </div>
      <div class='row'>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #revenueSubbStats>
            (BYOA: {{(byoaRevenue | currency : 'CAD' : 'symbol-narrow')}},
            MA: {{(maRevenue | currency : 'CAD' : 'symbol-narrow')}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingRevenueCard"
            [loadingCardFailed]="loadingRevenueCardFailed"
            iconClass="icon-history bg-report-indigo"
            titleClass="text-report-indigo"
            cardTitle="REVENUE (by ship date)"
            [totalStat]="totalRevenue"
            [isCurrency]="true"
            [subStatsTemplate]="revenueSubbStats"
            (refresh)="refreshRevenueCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.REVENUE)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #revenueChargedSubbStats>
            (BYOA: {{(byoaChargedRevenue | currency : 'CAD' : 'symbol-narrow')}},
            MA: {{(maChargedRevenue | currency : 'CAD' : 'symbol-narrow')}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingChargedRevenueCard"
            [loadingCardFailed]="loadingChargedRevenueCardFailed"
            iconClass="icon-history bg-report-indigo"
            titleClass="text-report-indigo"
            cardTitle="REVENUE (by charge date)"
            [totalStat]="totalChargedRevenue"
            [isCurrency]="true"
            [subStatsTemplate]="revenueChargedSubbStats"
            (refresh)="refreshChargedRevenueCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.REVENUE_BY_CHARGE)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #greenSubbStats>(Number of shipments: {{greenShipments}})</ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingGreenShipmentsCard"
            [loadingCardFailed]="loadingGreenShipmentsCardFailed"
            iconClass="icon-leaf bg-report-green"
            titleClass="text-report-green"
            cardTitle="GREEN SHIPMENTS REVENUE"
            [totalStat]="greenRevenue"
            [isCurrency]="true"
            [subStatsTemplate]="greenSubbStats"
            (refresh)="refreshGreenShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.GREEN_SHIPMENTS_REVENUE)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingStripeCard"
            [loadingCardFailed]="loadingStripeCardFailed"
            iconClass="icon-creditcard bg-report-stripe"
            titleClass="text-report-stripe"
            cardTitle="STRIPE"
            [totalStat]="stripeRevenue"
            [isCurrency]="true"
            (refresh)="refreshStripeCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.STRIPE)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #reconSubbStats>
            (Stripe: {{stripeReconAdjustment | currency : 'CAD' : 'symbol-narrow'}},
            Machool billing: {{maReconAdjustment | currency : 'CAD' : 'symbol-narrow'}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingReconAdjustmentsCard"
            [loadingCardFailed]="loadingReconAdjustmentsCardFailed"
            iconClass="icon-charge bg-report-recon"
            titleClass="text-report-recon"
            cardTitle="RECON ADJUSTMENTS"
            [totalStat]="reconAdjustment"
            [isCurrency]="true"
            [subStatsTemplate]="reconSubbStats"
            (refresh)="refreshReconAdjustmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.RECON_ADJUSTMENTS)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #cqcdRevenueSubbStats>
            (BYOA: {{(byoaCQCDRevenue | currency : 'CAD' : 'symbol-narrow')}},
            MA: {{(maCQCDRevenue | currency : 'CAD' : 'symbol-narrow')}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingCQCDRevenueCard"
            [loadingCardFailed]="loadingCQCDRevenueCardFailed"
            iconClass="icon-cqcd bg-tier-cqcd"
            titleClass="text-tier-cqcd"
            cardTitle="CQCD"
            [totalStat]="totalCQCDRevenue"
            [isCurrency]="true"
            [subStatsTemplate]="cqcdRevenueSubbStats"
            (refresh)="refreshCQCDRevenueCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.CQCD)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #pdcRevenueSubbStats>
            (BYOA: {{(byoaPDCRevenue | currency : 'CAD' : 'symbol-narrow')}},
            MA: {{(maPDCRevenue | currency : 'CAD' : 'symbol-narrow')}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingPDCRevenueCard"
            [loadingCardFailed]="loadingPDCRevenueCardFailed"
            iconClass="icon-panierdachat bg-tier-panier"
            titleClass="text-tier-panier"
            cardTitle="PANIERDACHAT"
            [totalStat]="totalPDCRevenue"
            [isCurrency]="true"
            [subStatsTemplate]="pdcRevenueSubbStats"
            (refresh)="refreshPDCRevenueCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.PANIER_DA_CHAT)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm with-gutter'>
          <ng-template #walletSubbStats>
            (Accounts with active wallet: {{ totalWalletAccounts }})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingWalletBalanceCard"
            [loadingCardFailed]="loadingWalletBalanceCardFailed"
            iconClass="icon-logo bg-report-wallet-balance"
            titleClass="text-report-wallet-balance"
            cardTitle="Machool Wallet"
            [totalStat]="totalWalletBalance"
            [subStatsTemplate]="walletSubbStats"
            [isCurrency]="true"
            (refresh)="refreshWalletBalanceCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.WALLET_BALANCE)'
          ></app-report-card>
        </div>
      </div>
      <div class='row mt--md'>
        <div class='column--12'>
          <h3>Shipments</h3>
        </div>
      </div>
      <div class='row mb--lg'>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #allShipmentsSubbStats>
            (BYOA: {{byoaShipments}}, MA: {{maShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingAllShipmentsCard"
            [loadingCardFailed]="loadingAllShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - ALL"
            [totalStat]="totalShipments"
            [subStatsTemplate]="allShipmentsSubbStats"
            (refresh)="refreshAllShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #manualShipmentsSubbStats>
            (BYOA: {{manualByoaShipments}}, MA: {{manualMaShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingManualShipmentsCard"
            [loadingCardFailed]="loadingManualShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - MANUAL"
            [totalStat]="totalManualShipments"
            [subStatsTemplate]="manualShipmentsSubbStats"
            (refresh)="refreshManualShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_MANUAL)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #ecomShipmentsSubbStats>
            (BYOA: {{ecommerceByoaShipments}}, MA: {{ecommerceMaShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingEcommShipmentsCard"
            [loadingCardFailed]="loadingEcommShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - E-COM"
            [totalStat]="totalEcommerceShipments"
            [subStatsTemplate]="ecomShipmentsSubbStats"
            (refresh)="refreshEcommShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_ECOMMERCE)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #apiShipmentsSubbStats>
            (BYOA: {{apiByoaShipments}}, MA: {{apiMaShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingApiShipmentsCard"
            [loadingCardFailed]="loadingApiShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - API"
            [totalStat]="totalApiShipments"
            [subStatsTemplate]="apiShipmentsSubbStats"
            (refresh)="refreshApiShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_API)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm'>
          <ng-template #connectShipmentsSubbStats>
            (BYOA: {{connectByoaShipments}}, MA: {{connectMaShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingConnectShipmentsCard"
            [loadingCardFailed]="loadingConnectShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - CONNECT"
            [totalStat]="totalConnectShipments"
            [subStatsTemplate]="connectShipmentsSubbStats"
            (refresh)="refreshConnectShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_CONNECT)'
          ></app-report-card>
        </div>
        <div class='column--6 column-xl--4 mt--sm with-gutter'>
          <ng-template #freightShipmentsSubbStats>
            (BYOA: {{freightByoaShipments}}, MA: {{freightMaShipments}})
          </ng-template>
          <app-report-card
            [canDownloadData]='canDownloadCardData'
            [loadingData]='downloadingReportData'
            [loadingCard]="loadingFreightShipmentsCard"
            [loadingCardFailed]="loadingFreightShipmentsCardFailed"
            iconClass="icon-box bg-report-aqua"
            titleClass="text-report-aqua"
            cardTitle="SHIPMENTS - FREIGHT"
            [totalStat]="totalFreightShipments"
            [subStatsTemplate]="freightShipmentsSubbStats"
            (refresh)="refreshFreightShipmentsCard()"
            (downloadData)='downloadCardData(ReportCardGroupTypes.SHIPMENTS_FREIGHT)'
          ></app-report-card>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf='canViewTopRevenue'>
      <div class='row mt--md'>
        <div class='column--12'>
          <h3>Top companies by revenue</h3>
        </div>
      </div>
      <div class='row'>
        <div class='column--12 pr--md pb--sm'>
          <mat-card>
            <mat-card-content>
              <ng-container *ngIf="!loadingTopRevenue, else loadingTop">
                <div class='responsive-table' *ngIf='!topRevenueAccountsEmpty, else emptyRevenueAccounts'>
                  <table mat-table [dataSource]="revenueAccountsToList">
                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef style='width: 50px'></th>
                      <td mat-cell *matCellDef="let element; let i = index;">
                        {{ i+1 }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="companyName">
                      <th mat-header-cell *matHeaderCellDef>Company / Name</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.companyName }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="total">
                      <th mat-header-cell *matHeaderCellDef>Total # of Shipments</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.total }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="revenue">
                      <th mat-header-cell *matHeaderCellDef>Revenue</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.revenue | currency }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="sales_rep">
                      <th mat-header-cell *matHeaderCellDef>Sales Rep</th>
                      <td mat-cell *matCellDef="let element">
                        {{ element.salesRep ? element.salesRep : 'Other' }}
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let element; columns: columnsToDisplay; let i = index" class="top-company-row">
                    </tr>
                  </table>
                </div>
                <ng-template #emptyRevenueAccounts>
                  <div class='h1 mt--sm mb--none align-center'>
                    <i class='icon-warn text--warn'></i>
                    <div class='text-center'>Ship! Nothing matches the search criteria.</div>
                  </div>
                </ng-template>
              </ng-container>
              <ng-template #loadingTop>
                <div class='align-center mt--sm'>
                  <app-loading></app-loading>
                </div>
              </ng-template>
            </mat-card-content>
          </mat-card>
          <ng-container *ngIf='!loadingTopRevenue && !topRevenueAccountsEmpty && topRevenueAccounts.length > initialAccountListLength'>
            <div class='row'>
              <div class='column--12 mt--md'>
                <button mat-button color='primary' class='mdc-small-icon' (click)='showHideAccounts()'>
                  <ng-container *ngIf='hasMoreAccounts, else showLess'>
                    Show more
                    <mat-icon class='icon-chevron-down ml-sm' iconPositionEnd></mat-icon>
                  </ng-container>
                  <ng-template #showLess>
                    Show less
                    <mat-icon class='icon-chevron-up ml-sm' iconPositionEnd></mat-icon>
                  </ng-template>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
