<h1>Add Province / Territory Dashboard</h1>
<ng-container *ngIf='!loading, else isLoading'>
  <form [formGroup]="dashboardForm" (ngSubmit)='saveDashboard()'>
    <div class='row mt--md'>
      <div class='column--12'>
        <mat-form-field class='full-width'>
          <mat-label>Provinces</mat-label>
          <mat-select formControlName="province" name='province' placeholder='Select province'>
            <mat-option *ngFor='let option of provincesDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class='row mt--md'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' type='submit' [disabled]='dashboardForm.invalid'>Save</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--sm'>
    <app-loading></app-loading>
  </div>
</ng-template>
