import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  catchError,
  map,
  Observable,
  OperatorFunction,
  throwError,
  TimeoutError,
  timeoutWith,
} from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';
import { CompanyInvoice } from '@app/pages/authenticated/pages/users/users.model';
import { HelpersService } from '@app/shared/services/helpers.service';

export enum InvoiceBy {
  DATE_RANGE = 'byDateRange',
  TRACKING_NUMBER = 'byTrackingNumber',
}

export enum InvoiceStatuses {
  OPEN = 'Open',
  PARTIALLY_PAID = 'Partially Paid',
  PAID = 'Paid',
  OVERDUE = 'Overdue',
  PARTIALLY_OVERDUE = 'Partially Overdue',
  NEWLY_CREATED = 'Newly created',
}

export interface InvoicePayment {
  id: number;
  invoiceId?: number;
  paymentDate: string;
  amount: number;
  description?: string;
  invoice?: CompanyInvoice;
  createdAt: Date;
  formattedPaymentDate?: string;
}

@Injectable({ providedIn: 'root' })
export class BillingInvoicesService {
  private readonly INVOICE_TIMEOUT = 10000; // 10 seconds
  constructor(
    private http: HttpClient,
    private helpersService: HelpersService
  ) {}

  // public getReceipt(orderId: string, adjustment: boolean = false): Observable<any> {
  //   return this.http.get(`${environment.APP_SERVICES_URL}/admin/shipment_charges`, { params: new HttpParams().set('shipmentUuid', orderId) })
  //     .pipe(
  //       map((res: any) => {
  //         if (_.isEmpty(res)) {
  //           throw new Error(`No shipment charge records have been found for shipment with Uuid: ${orderId}`);
  //         }
  //         const stripeReceiptUrl = _.get(_.find(res, (shipmentCharge) => shipmentCharge.adjustment === adjustment && shipmentCharge.stripeReceiptUrl), 'stripeReceiptUrl');
  //         if (!_.isString(stripeReceiptUrl)) {
  //           throw new Error('ReceiptURL is not a valid string');
  //         }
  //         return stripeReceiptUrl as string;
  //       }),
  //       catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
  //     );
  // }

  public getCompanyInvoices(params): Observable<CompanyInvoice[]> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/invoices`, { params })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getInvoicePdf(params: any): any {
    return this.http
      .get(
        `${environment.UTILITY_SERVICES_URL}/admin/statements/invoices/pdf`,
        { params }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getStatementsDetails(params: any): any {
    return this.http
      .get(`${environment.UTILITY_SERVICES_URL}/admin/statements/details`, {
        params,
      })
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getZippedInvoices(query: any): any {
    const headers = this.helpersService.getAuthHeaders();
    return this.http
      .post(
        `${environment.UTILITY_SERVICES_URL}/admin/statements/invoices/all`,
        query,
        { headers }
      )
      .pipe(
        timeoutWith(this.INVOICE_TIMEOUT, throwError(new TimeoutError())),
        map((res: any) => res),
        catchError((error: any) =>
          throwError(
            error.name === 'TimeoutError' ? ErrorMessages.TIMEOUT : error
          )
        )
      );
  }

  public createInvoicePdf(query: any): any {
    const headers = this.helpersService.getAuthHeaders();
    return this.http
      .post(
        `${environment.UTILITY_SERVICES_URL}/admin/statements/invoices/pdf`,
        query,
        { headers }
      )
      .pipe(
        timeoutWith(this.INVOICE_TIMEOUT, throwError(new TimeoutError())),
        map((res: any) => res),
        catchError((error: any) =>
          throwError(
            error.name === 'TimeoutError' ? ErrorMessages.TIMEOUT : error
          )
        )
      );
  }

  public createBulkInvoicePayments(query: any): any {
    const headers = this.helpersService.getAuthHeaders();
    return this.http
      .post(
        `${environment.UTILITY_SERVICES_URL}/admin/invoices/payments`,
        query,
        { headers }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) =>
          throwError(error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public createInvoicePayment(invoiceId: number, query: any): any {
    return this.http
      .post(
        `${environment.APP_SERVICES_URL}/admin/invoices/${invoiceId}/payments`,
        query
      )
      .pipe(
        map((res: any) => res),
        catchError((error: HttpErrorResponse) =>
          throwError(error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public deleteInvoicePayment(invoicePaymentId: number): Observable<any[]> {
    return this.http
      .delete(
        `${environment.APP_SERVICES_URL}/admin/invoices/payment/${invoicePaymentId}`
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public deleteInvoice(invoiceId: number): Observable<CompanyInvoice[]> {
    return this.http
      .delete(`${environment.APP_SERVICES_URL}/admin/invoices/${invoiceId}`)
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  // Public endpoint to validate and get invoice document (PDF or CSV) inside email body
  public validateTokenAndGetDocument(params: any): any {
    return this.http
      .get(
        `${environment.UTILITY_SERVICES_URL}/statements/invoices/download`,
        { params }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public convertStatusToClass(invoice: CompanyInvoice): string {
    const status: InvoiceStatuses = invoice.status;
    const invoiceUrl = invoice.invoiceUrl;
    if ([InvoiceStatuses.OPEN].includes(status) && !invoiceUrl) {
      return 'processing';
    } else if (
      [
        InvoiceStatuses.PARTIALLY_PAID,
        InvoiceStatuses.PARTIALLY_OVERDUE,
      ].includes(status)
    ) {
      return 'warn';
    } else {
      return status.toLowerCase();
    }
  }

  private timeoutWhen<T>(
    condition: boolean,
    timeoutDue: number
  ): OperatorFunction<T, T> {
    return function (source: Observable<T>): Observable<T> {
      return condition
        ? source.pipe(timeoutWith(timeoutDue, throwError(new TimeoutError())))
        : source;
    };
  }
}
