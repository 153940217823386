<h3>Add payment <mat-chip *ngIf='!loading && invoice' class="ml--md chip-invoice status-{{ invoice.className }}">{{ invoice.status }}</mat-chip></h3>
<ng-container *ngIf='!loading && company && invoice, else isLoading'>
  <app-invoice-meta [company]='company' [invoice]='invoice'></app-invoice-meta>
  <form [formGroup]="paymentForm" class='mt--lg'>
    <div class='row'>
      <div class='column--8'>
        <mat-form-field class="full-width">
          <mat-label>Payment Date</mat-label>
          <input matInput formControlName="paymentDate" [matDatepicker]="paymentDatePicker">
          <mat-datepicker-toggle matIconSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #paymentDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class='row'>
        <div class='column--8'>
          <mat-form-field class="full-width">
            <mat-label>Amount</mat-label>
            <input matInput type="text" formControlName="amount">
          </mat-form-field>
        </div>
      </div>
      <div class='row'>
        <div class='column--8'>
          <mat-form-field class="full-width">
            <mat-label>Description</mat-label>
            <input matInput type="text" formControlName="description">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='create()' [disabled]='paymentForm.invalid'>Save</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
