import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ReconciliationComponent } from '@app/pages/authenticated/pages/reconciliation/reconciliation.component';
import { EnsurePageQueryGuard } from '@app/shared/guards/ensure-page-query.guard';
import { ShipmentDetailsComponent } from '@app/pages/authenticated/components/shipment-details/shipment-details.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard, EnsurePageQueryGuard],
    data: { scopes: [AdminScopes.VIEW_RECONCILIATION] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: ReconciliationComponent,
        data: { scopes: [AdminScopes.VIEW_RECONCILIATION], authGuardPipe: redirectUnauthorizedToLogin },
      },
    ],
  },
];

@NgModule({
  imports: [ComponentsModule, CommonModule, RouterModule.forChild(routes), SharedModule, ShipmentDetailsComponent],
  exports: [RouterModule],
  declarations: [ReconciliationComponent],
})
export class ReconciliationModule {}
