import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { MatDialogRef } from '@angular/material/dialog';

export enum ReasonType {
  ADDRESS_CORRECTION = 'A',
  RETURN_SHIPMENT = 'R',
  SECOND_DELIVERY = 'S',
  UNDELIVERABLE = 'U',
  PICKUP_ATTEMPTED = 'P',
  SHIPMENT_DELIVERED = 'D',
  INSURANCE_MISMATCH = 'I',
  OTHER = 'O',
}

@Component({
  selector: 'modals-assign-unknown-tracking',
  templateUrl: './modals-assign-unknown-tracking.component.html',
})

export class ModalsAssignUnknownTrackingComponent implements OnInit {

  public createdAt: Date;
  public trackingNumber: string;
  public provider: string;
  public providerDisplayName: string;
  public providerImageSrc: string;
  public reference: string;
  public totalAmount: number;
  public isInsuranceProvider: boolean;
  public getBadgeClass: string;
  public getStatusText: string;

  public assignToTrackingNumber: string;
  public form: FormGroup;
  public result: any;
  public displayedColumns: string[] = ['date', 'unknownType', 'provider', 'tracking', 'reference', 'cost'];
  public dataSource: any[];

  constructor(
    private dialogRef: MatDialogRef<ModalsAssignUnknownTrackingComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      createdAt: Date;
      trackingNumber: string;
      provider: string;
      providerDisplayName: string;
      providerImageSrc: string;
      reference: string;
      totalAmount: number;
      isInsuranceProvider: boolean;
      getBadgeClass: string;
      getStatusText: string;
    },
    private formBuilder: FormBuilder,
  ) {
    this.createdAt = this.data.createdAt;
    this.trackingNumber = this.data.trackingNumber;
    this.provider = this.data.provider;
    this.providerDisplayName = this.data.providerDisplayName;
    this.providerImageSrc = this.data.providerImageSrc;
    this.reference = this.data.reference;
    this.totalAmount = this.data.totalAmount;
    this.isInsuranceProvider = this.data.isInsuranceProvider;
    this.getBadgeClass = this.data.getBadgeClass;
    this.getStatusText = this.data.getStatusText;

    this.dataSource = [{
      createdAt: this.createdAt,
      trackingNumber: this.trackingNumber,
      provider: this.provider,
      providerDisplayName: this.providerDisplayName,
      providerImageSrc: this.providerImageSrc,
      reference: this.reference,
      totalAmount: this.totalAmount,
      isInsuranceProvider: this.isInsuranceProvider,
      getBadgeClass: this.getBadgeClass,
      getStatusText: this.getStatusText
    }];
  }

  ngOnInit(): void {
    this.createForm();
  }

  public readonly reasonTypesDropdown = [
    { id: 'A', text: 'Address correction' },
    { id: 'R', text: 'Return shipment' },
    { id: 'S', text: 'Second delivery' },
    { id: 'U', text: 'Undeliverable' },
    { id: 'P', text: 'Pickup attempted' },
    { id: 'D', text: 'Shipment delivered' },
    { id: 'I', text: 'Insurance mismatch' },
    { id: 'H', text: 'Additional handling' },
    { id: 'W', text: 'Wrong weight / dimensions' },
    { id: 'O', text: 'Other' },
  ];

  public onSubmit() {
    this.result = this.form.value;
    this.dialogRef.close(this.result);
  }



  private createForm() {
    this.form = this.formBuilder.group({
      reasonType: ['', [Validators.required]],
      assignToTrackingNumber: [this.assignToTrackingNumber, [Validators.required]],
    });
  }
}
