import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

export interface Dashboard {
  id: number;
  province: string;
  cardLeft?: DashboardCard;
  cardCenter?: DashboardCard;
  cardRight?: DashboardCard;
  defaultCardLeft: DashboardCard;
  defaultCardCenter: DashboardCard;
  defaultCardRight: DashboardCard;
}

export interface DashboardCard {
  id: number;
  name: string;
  imageUrl: string;
  title: string;
  body: string;
  buttonUrl?: string;
  buttonLabel?: string;
  isExternalLink?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getDashboards(): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/dashboards`)
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public createDashboard(createDashboardDto: any): Observable<Dashboard> {
    return this.http
      .post(
        `${environment.APP_SERVICES_URL}/admin/dashboards`,
        createDashboardDto,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public updateDashboard(
    dashboardId,
    updateDashboardDto: any
  ): Observable<Dashboard> {
    return this.http
      .put(
        `${environment.APP_SERVICES_URL}/admin/dashboards/${dashboardId}`,
        updateDashboardDto,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getDashboardCards(): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/dashboards_cards`)
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public createCard(createCardDto: any): Observable<DashboardCard> {
    return this.http
      .post(
        `${environment.APP_SERVICES_URL}/admin/dashboards_cards`,
        createCardDto,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public updateCard(cardId, updateCardDto: any): Observable<DashboardCard> {
    return this.http
      .put(
        `${environment.APP_SERVICES_URL}/admin/dashboards_cards/${cardId}`,
        updateCardDto,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public deleteCard(cardId: number): Observable<any[]> {
    return this.http
      .delete(
        `${environment.APP_SERVICES_URL}/admin/dashboards_cards/${cardId}`,
        {
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res),
        catchError((error: any) =>
          throwError(error.error || ErrorMessages.UNEXPECTED)
        )
      );
  }
}
