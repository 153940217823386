import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class InvoicesService {
  constructor(
    private helperService: HelpersService,
    private http: HttpClient
  ) {}

  public getAllCustomerBalancesData(params): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/companies/open_balances`, { params })
      .pipe(
        map((res: any) => {
          return res
        }),
        catchError((error: HttpErrorResponse) =>
          throwError(error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public getInvoicesByStatus(params): any {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/invoices/by_status`, { params })
      .pipe(
        map((res: any) => {
          return res
        }),
        catchError((error: HttpErrorResponse) =>
          throwError(error || ErrorMessages.UNEXPECTED)
        )
      );
  }

  public isInvoiceSummaryPath(url: string): boolean {
    return url ? url.includes('/pdf-summaries') : false
  }
}
