import { Component, OnInit } from '@angular/core';
import { HelpersService } from '../../../../shared/services/helpers.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { AdminScopes, AdminUser, UserService } from '../../../../shared/services/user.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { UnknownShipmentsService } from '../../../../shared/services/unknown-shipments.service';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { EdiInvoiceComponent } from '../../components/edi-invoice/edi-invoice.component';
import {
  EDI_INVOICE_MODAL_TYPE,
  InsuranceProviders,
  PanelWidths,
  ShipmentStatuses,
  ShippingProviders,
  ThirdPartyInsuranceProviderStatus,
} from '../../../../shared/constants';
import { ProvidersService } from '../../../../shared/services/providers.service';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../../../environments/environment';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';

@Component({
  selector: 'app-unbillable-shipments',
  templateUrl: './unbillable-shipments.component.html',
})
export class UnbillableShipmentsComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;
  public CDN_URL: string = environment.CDN_URL;

  public unbillableShipments = [];
  public unbillableShipmentsCount = 0;
  public paginatedUbillableShipments = [];

  public currentPage: number = 0;
  public itemsPerPage: number = 25;
  public itemsPerPageOptions: number[] = [25, 50, 100];
  public displayedColumns: string[] = ['date', 'unknownType', 'provider', 'trackingNumber', 'reference', 'cost'];

  constructor(
    private helpersService: HelpersService,
    private logger: LoggerService,
    private userService: UserService,
    private router: Router,
    private drawer: MtxDrawer,
    private bannerNotificationsService: BannerNotificationsService,
    private unknownShipmentsService: UnknownShipmentsService,
    private providersService: ProvidersService
  ) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_UNBILLABLE_SHIPMENTS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
    this.getUnbillableShipments();
  }

  private getUnbillableShipments() {
    this.loading = true;
    const query = {
      type: 'D',
    };

    this.unbillableShipments = [];
    this.unbillableShipmentsCount = 0;
    this.paginatedUbillableShipments = [];

    this.unknownShipmentsService.findAllUnknownShipments(query).subscribe(
      (res: any) => {
        this.logger.log('Unbillable Shipments, GET All Unbillable Shipments', res);
        this.unbillableShipments = res.unknownShipments;
        this.unbillableShipmentsCount = res.unknownShipmentsCount;
        const end = this.itemsPerPage * (this.currentPage + 1);
        const start = end - this.itemsPerPage;
        this.paginatedUbillableShipments = this.unbillableShipments.slice(start, end);
        this.loading = false;
      },
      (err: Error) => {
        this.logger.error('Unbillable Shipments, GET All Unbillable Shipments error', err);
        this.loading = false;
        this.unbillableShipments = [];
        this.unbillableShipmentsCount = 0;
      }
    );
  }

  public getProviderImage(provider: string): string {
    const mappedProvider = provider === ShippingProviders.RIVO ? 'ac' : provider;
    return this.providersService.getProviderSmallLogo(mappedProvider);
  }

  public getProviderName(provider: ShippingProviders, service?: any): string {
    return this.helpersService.convertShipmentProviderToString(provider, service);
  }

  public openEdiInvoiceDrawer(shipment) {
    this.drawer.open(EdiInvoiceComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { shipment, type: EDI_INVOICE_MODAL_TYPE.UNKNOWN_TRACKING },
    });
  }

  public getTrackingReference(trackingNumber: any, defaultValue: 'More Info' | 'DO NOT BILL RECON ADJ') {
    if (!trackingNumber.data) return defaultValue;
    if (!trackingNumber.data.providerInvoiceItem) return defaultValue;
    if (!trackingNumber.data.providerInvoiceItem.extras) return defaultValue;
    if (trackingNumber.data.shipmentStatus) {
      if (trackingNumber.data.shipmentStatus === ThirdPartyInsuranceProviderStatus.CANCELLED) return 'CANCELLED';
      if (trackingNumber.data.shipmentStatus === ShipmentStatuses.CANCELLED) return 'CANCELLED';
      if (trackingNumber.data.shipmentStatus === ShipmentStatuses.PENDING_CANCEL) return 'PENDING CANCEL';
    }

    const { extras } = trackingNumber.data.providerInvoiceItem;

    return (
      extras.customerRef1 ||
      extras.customerRef2 ||
      extras.customerRef3 ||
      extras.customerRef4 ||
      extras.customerRef5 ||
      extras.customerNamePU ||
      extras.customerNameDel ||
      extras.extraInfo ||
      extras.reference ||
      defaultValue
    );
  }

  public getBadgeClass(data: any): string {
    return this.isInsuranceProvider(data?.provider || '') ? 'unconfirmed' : 'created';
  }

  public getStatusText(data: any): string {
    return this.isInsuranceProvider(data?.provider || '')
    ? `${this.getInsuranceProviderName(data?.provider)}`
    : 'Shipping Provider';
  }

  public getInsuranceProviderName(insuranceProvider: InsuranceProviders): string {
    return this.helpersService.convertInsuranceProviderToString(insuranceProvider);
  }

  public isInsuranceProvider(provider: InsuranceProviders | string): boolean {
    return [InsuranceProviders.UPIC, InsuranceProviders.MARSH].includes(provider as InsuranceProviders);
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedUbillableShipments = this.unbillableShipments.slice(start, end);
  }

  public copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.bannerNotificationsService.success('Tracking number copied to clipboard');
  }
}
