import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { DashboardCard } from '@app/shared/services/dashboard.service';

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent implements OnInit {
  @Input() defaultCard: DashboardCard;
  @Input() card?: DashboardCard;

  @Output() editCardRequested: EventEmitter<DashboardCard> = new EventEmitter<DashboardCard>();
  @Output() addCardRequested: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
