<div class='row' *ngIf='notification'>
  <div class='column--12 mt--md'>
    <div class='alert-box {{ notification.class }}'>
      <div class='alert-icon'>
        <mat-icon class='{{ notification.icon }}'></mat-icon>
      </div>
      <div class='alert-message'>
        <span>{{notification.message}}</span>
      </div>
    </div>
  </div>
</div>
