<ng-container *ngIf='!loading, else isLoading'>
  <div class='container mx--none'>
    <form [formGroup]="filterForm">
      <div class='row'>
        <div class='column--12 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4'>
          <mat-form-field class='w-100' *ngIf="supportedAirports.length > 1">
            <mat-label>Origin airport</mat-label>
            <mat-select formControlName="originAirports" name='originAirports' placeholder='All origin airports' multiple>
              <mat-option *ngFor='let airport of supportedAirports' value="{{ airport.code }}">{{ airport.code }} ({{airport.name}})</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4'>
          <mat-form-field class='w-100' *ngIf="supportedAirports.length > 1">
            <mat-label>Destination airport</mat-label>
            <mat-select formControlName="destinationAirports" name='destinationAirports' placeholder='All destination airports' multiple>
              <mat-option *ngFor='let airport of supportedAirports' value="{{ airport.code }}">{{ airport.code }} ({{airport.name}})</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class='row'>
        <div class='column--12 column-md--4'>

          <mat-form-field class="w-100">
            <mat-label>Customer name</mat-label>
            <input matInput type="text" formControlName="companyName" name='companyName' (keyup.enter)="getAllInformation()">
          </mat-form-field>
        </div>
        <div class='column--12 column-md--8 pt--xs align-right'>
          <button mat-flat-button color='primary' type='submit' (click)='getAllInformation()' [disabled]='isAnyCardLoading()'>Search</button>
        </div>
      </div>
    </form>

    <div class='row mt--md'>
      <div class='column--12'>
        <h3>Shipments</h3>
      </div>
    </div>
    <div class='row mb--lg'>
      <div class='column--6 column-xl--4 mt--sm'>
        <app-report-card
          [canDownloadData]='true'
          [loadingData]='downloadingReportData'
          [loadingCard]="loadingTotalShipmentsCard"
          [loadingCardFailed]="loadingTotalShipmentsCardFailed"
          iconClass="icon-box bg-report-aqua"
          titleClass="text-report-aqua"
          cardTitle="TOTAL SHIPMENTS"
          [totalStat]="totalShipments"
          (refresh)="refreshTotalShipmentsCard()"
          (downloadData)='downloadCardData(ReportCardGroupTypes.ACC_SHIPMENTS)'
        ></app-report-card>
      </div>

      <div class='column--6 column-xl--8 mt--sm'></div>
    </div>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
