<ng-container *ngIf='!loading && company, else isLoading'>
  <div class='row row--valign-center mt--md'>
    <div class='column--6'>
      <p>
        Balance <br />
        <strong class='semi-bold text--lg'>{{ currentBalance | currency }}</strong>
      </p>
    </div>
    <div class='column--6 align-right'>

      <button mat-flat-button color='primary' (click)='addFunds()' [disabled]='!canEdit'>
        <mat-icon class='icon-charge'></mat-icon>
        Add funds
      </button>
    </div>
  </div>

  <div class='row mt--md'>
    <div class='column--8'>
      <mat-checkbox class="text--sm no-bold" [checked]="showOnlyStripeTransaction" (change)="toggleStripeTransactions($event.checked)" [disabled]="loading">Show Stripe related transactions only</mat-checkbox>
    </div>
    <div class='column--4'>
      <mat-form-field class="full-width">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input #dateRangeStart matStartDate [value]="dateRange.start" placeholder="Start date">
          <input #dateRangeEnd matEndDate [value]="dateRange.end" placeholder="End date" (dateChange)="selectDate(dateRangeStart, dateRangeEnd)">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>

  <div class='row mt--md' *ngIf="transactions?.length > 0, else noTransactions">
    <div class='column--12'>
      <mat-card class='mat-elevation-z0'>
        <mat-card-content>
          <table mat-table [dataSource]="transactions" class="mat-elevation-z0" matSort [matSortActive]="orderByValue" [matSortDirection]="sortDescending" (matSortChange)="setOrder($event)">
            <!-- Date Column -->
            <ng-container matColumnDef="created_at">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date" style='width: 150px'>Date</th>
              <td mat-cell *matCellDef="let element">
                {{ element.createdAt | timeToString : DATE_YEAR }}
              </td>
            </ng-container>
            <!-- Description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef>Description</th>
              <td mat-cell *matCellDef="let element">
                {{ element.description }}
                <span *ngIf="element.stripeReceiptUrl">
                  <a class='text--primary cursor-pointer' (click)="getReceipt(element.stripeReceiptUrl)">Receipt
                    <mat-icon class="mat-icon-sm text-primary icon-ex-link"></mat-icon>
                  </a>
                </span>
              </td>
            </ng-container>
            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef style='width: 100px' class='align-right'>Amount</th>
              <td mat-cell *matCellDef="let element" class='align-right'>
                {{ element.amount | currency }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator
            (page)="pageChanged($event)"
            [length]='totalItems'
            [pageSize]="itemsPerPage"
            [pageIndex]='currentPage'
            [disabled]='totalItems <= itemsPerPage'
            [hidePageSize]='true'>
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <ng-template #noTransactions>
    <div class='row mt--md'>
      <div class='column--12 align-center'>
        <p><img src='{{ CDN_URL }}/admin/images/empty-transactions.svg' height='147px' /></p>
        <p>No company wallet transactions found.</p>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
