<ng-container *ngIf='!loading && company, else isLoading'>
  <form [formGroup]="salesRepForm" novalidate>
    <div class='row mt--md'>
      <div class='column--12 mt--md'>
        <mat-form-field *ngIf="salesRepsSelect.length > 1">
          <mat-label>Sales</mat-label>
          <mat-select formControlName="salesRep" name='salesRep' [(value)]='selectedSalesRep' (selectionChange)='changeRepDescription()'>
            <mat-option *ngFor='let salesRep of salesRepsSelect' value="{{ salesRep.id }}">{{salesRep.text}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='column--12'>
        <p class="my--none" [innerHTML]='salesRepDescription'></p>
      </div>
      <div class='column--12 mt--md'>
        <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || salesRepForm.invalid" (click)='updateCompany()'>Update</button>
      </div>
    </div>
  </form>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
