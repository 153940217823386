<div class='row mt--md' *ngIf='!loading && company, else isLoading'>
  <div class='column--12'>
    Couriers Component | {{ company.name}}
  </div>
</div>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
