<form *ngIf='!loading && company && billingInfoForm, else isLoading' [formGroup]="billingInfoForm">
  <div class='row pt--lg'>
    <div class='column--6 column-md--6'>
      <mat-form-field class='full-width'>
        <mat-label>Company / Name </mat-label>
        <input matInput formControlName='companyName' name='companyName'>
      </mat-form-field>
    </div>
    <div class='column--6 column-md--6'>
      <mat-form-field class='full-width'>
        <mat-label>Attention to</mat-label>
        <input matInput formControlName='attentionTo' name='attentionTo'>
      </mat-form-field>
    </div>
  </div>
  <ng-container formGroupName="address">
      <div class='row'>
        <div class='column--6 column-md--6'>
          <mat-form-field class=''>
            <mat-label>Country </mat-label>
            <input matInput formControlName='country' name='country'>
          </mat-form-field>
        </div>
      </div>

      <div class='row'>
        <div class='column--6 column-md--6'>
          <mat-form-field class='full-width'>
            <mat-label>Street address</mat-label>
            <input matInput formControlName='line1' name='line1'>
          </mat-form-field>
        </div>
        <div class='column--3 column-md--3'>
          <mat-form-field class='full-width'>
            <mat-label>Unit / Floor</mat-label>
            <input matInput formControlName='line2' name='line2'>
          </mat-form-field>
        </div>
        <div class='column--3 column-md--3'>
          <mat-form-field class='full-width'>
            <mat-label>Buzzer code</mat-label>
            <input matInput formControlName='additionalInfo' name='additionalInfo'>
          </mat-form-field>
        </div>
      </div>

      <div class='row'>
        <div class='column--4 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>City</mat-label>
            <input matInput formControlName='city' name='city'>
          </mat-form-field>
        </div>
        <div class='column--4 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>Province</mat-label>
            <input matInput formControlName='province' name='province'>
          </mat-form-field>
        </div>
        <div class='column--4 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>Postal code</mat-label>
            <input matInput formControlName='postalCode' name='postalCode'>
          </mat-form-field>
        </div>
      </div>
  </ng-container>
  <div class='row mt--md'>
    <div class='column--12'>
      <p class='mt--none pt--none'>Billing email address we send invoices. Company owner’s email address by default.</p>
    </div>
  </div>
  <ng-container formArrayName='billingContactsArray'>
    <ng-container *ngFor='let _ of billingContactsArray.controls; index as i'>
      <div class='row' [formGroupName]='i'>
        <div class='column--6 column-md--6'>
          <mat-form-field class='full-width'>
            <mat-label>Email address</mat-label>
            <input matInput formControlName='billingEmail' name='billingEmail'>
          </mat-form-field>
        </div>
        <div class='column--5 column-md--5'>
          <mat-form-field class='full-width'>
            <mat-label>First name</mat-label>
            <input matInput formControlName='billingFirstName' name='billingFirstName'>
          </mat-form-field>
        </div>
        <div class='column--1 column-md--1 pl--none align-right'>
          <button mat-icon-button color='primary' class='mat-small-icon' (click)='addRecipientGroup()' title='Add Recipient' [disabled]="!canEdit || billingInfoForm.invalid">
            <mat-icon class='icon-plus'></mat-icon>
          </button>
          <button mat-icon-button class='mat-small-icon' [disabled]="!canEdit || i === 0" (click)='deleteRecipient(i)' title='Remove Recipient'>
            <mat-icon class='icon-bin'></mat-icon>
          </button>

        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class='row mt--sm'>
    <div class='column--8'>
      <mat-checkbox class="text--sm" color='primary'  formControlName='noBillingStatement'><span class='no-bold text--sm' >Do not generate a full PDF Statement</span></mat-checkbox>
    </div>
  </div>

  <div class='row mt--xs mb--md'>
    <div class='column--8'>
      <mat-checkbox class="text--sm" color='primary'  formControlName='applyCreditCardFee'><span class='no-bold text--sm' >Apply 3% credit card fee</span></mat-checkbox>
    </div>
  </div>

  <div class='row mt--md'>
    <div class='column--12'>
      <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || billingInfoForm.invalid || loading" (click)='updateBillingInfo()'>Save</button>
    </div>
  </div>
</form>
<ng-template #isLoading>
  <div class='align-center'>
    <app-loading></app-loading>
  </div>
</ng-template>
