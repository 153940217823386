import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { CustomerBalanceInvoicesComponent } from '@app/pages/authenticated/pages/invoices/components/customer-balance-invoices/customer-balance-invoices.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
})
export class InvoicesComponent implements OnInit {
  @ViewChild('customerBalanceInvoiceComponent', { static: false })
  customerBalanceInvoicesComponent: CustomerBalanceInvoicesComponent
  public invoicesGenerated: boolean = false;
  public loading: boolean = true;
  public user: AdminUser;
  public canViewCustomerBalances: boolean = false;
  public canGenerateInvoicesForCustomers: boolean = false;
  public canViewCustomerInvoices: boolean = false;
  public canEditAndEmailInvoices: boolean = false;

  public selectedCompanyName: string;
  public selectedTab: number = 0;
  public tabs = [
    {
      label: 'Customers',
      id: 'app-customer-balance-invoices',
      scope: [AdminScopes.VIEW_MACHOOL_REPORTS],
    },
    {
      label: 'Invoices',
      id: 'app-invoices-list-invoices',
      scope: [AdminScopes.VIEW_ACC_REPORTS],
    },
  ];

 public invoicesByStatus: any;
 public invoicesByStatusTotalItems: number = 0;
 public numPages: number = 0;
 public itemsPerPage = 50;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_INVOICES])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.canViewCustomerBalances = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.VIEW_INVOICES_CUSTOMERS]
            );
            this.canGenerateInvoicesForCustomers = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.MODIFY_INVOICES_CUSTOMERS]
            );
            this.canEditAndEmailInvoices = this.userService.hasAuthScope(
              this.user,
              [AdminScopes.MODIFY_INVOICES_LIST]
            );
            this.canViewCustomerInvoices = this.userService.hasAuthScope(this.user, [
              AdminScopes.VIEW_INVOICES_LIST,
            ]);
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['dashboard']);
        }
      );
  }

  public goToSecondTab(selectedCompany: any) {
    this.selectedTab = 1
    this.selectedCompanyName = selectedCompany.name;
  }

  public tabChanged() {
    this.selectedCompanyName = '';
  }
}
