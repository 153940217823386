<h3>Update courier note</h3>
<ng-container *ngIf='!loading && provider, else isLoading'>
  <form [formGroup]="providerNoteForm" class='mt--lg'>
    <div class='column--12'>
      <mat-form-field appearance="outline" class='w-100'>
        <mat-label>Note</mat-label>
        <textarea matInput formControlName='note' rows='4'></textarea>
      </mat-form-field>
    </div>
    <div class='row mt--md'>
      <div class='column--6'>
        <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
      </div>
      <div class='column--6 align-right'>
        <button mat-flat-button color='primary' (click)='updateNote()' [disabled]='providerNoteForm.invalid'>Save</button>
      </div>
    </div>
  </form>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
