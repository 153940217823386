import { Component, OnInit } from '@angular/core';
import {
  AdminScopes,
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';

@Component({
  selector: 'app-cancellations',
  templateUrl: './cancellations.component.html',
})
export class CancellationsComponent implements OnInit {
  public loading: boolean = true;
  public user: AdminUser;

  constructor(private userService: UserService, private router: Router) {}
  ngOnInit() {
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_CANCELLATIONS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.loading = false;
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }
}
