<h1 mat-dialog-title>Create return Label</h1>
<div mat-dialog-content>
  <div class='mat-dialog-body'>
    <ng-container *ngIf='processCompleted, else isLoading'>
      <ng-container *ngIf='!hasError, else errorGenerating'>
        <div class='row'>
          <div class='column--12'>
            You will be charged only if the return label is used.
          </div>
        </div>
      </ng-container>
      <ng-template #errorGenerating>
        <p class='semi-bold mt--md'>{{retryError}}</p>
      </ng-template>
    </ng-container>

    <ng-template #isLoading>
      <div class='align-center mt--lg'>
        <app-loading></app-loading>
        <p class='semi-bold mt--md'>Generating a return label…</p>
      </div>
    </ng-template>
  </div>
</div>

<div class='row' mat-dialog-actions>
  <div class='column--3'>
    <button
      mat-flat-button
      mat-dialog-close
      color='accent'
      [disabled]='!processCompleted'
    >Close</button>
  </div>
  <div class='column--9 align-right'>
    <button
      mat-flat-button
      color="primary"
      (click)="downloadFile()"
      [disabled]='!processCompleted && !hasError'
    >Download label</button>
  </div>
</div>
