import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '@app/shared/services/user.service';
import { SessionStorageService } from 'ngx-webstorage';

/**
 * Guard that only allows access by admin or above
 */
@Injectable({
  providedIn: 'root',
})
export class ScopeGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router, public userService: UserService) {}

  // Guard top level routes based on scope allowed for the current user
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentAdminUser = this.userService.getCurrentUser();
    if (!currentAdminUser) return false;
    const expectedScopes = route.data['scopes'] || 0;
    if (this.userService.hasAuthScope(currentAdminUser, expectedScopes)) {
      return true;
    } else {
      this.router.navigate(['/404'], { skipLocationChange: true });
      return false;
    }
  }

  // Guard children routes based on scope allowed for the current user
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const currentAdminUser = this.userService.getCurrentUser();
    if (!currentAdminUser) return false;
    const expectedScopes = route.data['scopes'] || 0;
    return this.userService.hasAuthScope(currentAdminUser, expectedScopes);
  }
}
