import { Injectable } from '@angular/core';
import { HelpersService } from '@app/shared/services/helpers.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { ErrorMessages } from '@app/shared/constants';

@Injectable({ providedIn: 'root' })
export class AdjustmentsService {
  constructor(
    private http: HttpClient,
    private helpersService: HelpersService
  ) {}

  public findAllAdjustments(params) {
    return this.http.get(`${environment.APP_SERVICES_URL}/admin/shipments/adjustments`, { params }).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public getApproveAllJobStatus() {
    return this.http.get(`${environment.UTILITY_SERVICES_URL}/admin/reconciliation/approve-all`).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public triggerApproveAllRecon() {
    const headers = this.helpersService.getAuthHeaders();
    return this.http.post(`${environment.UTILITY_SERVICES_URL}/admin/reconciliation/approve-all`, { headers }).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public approveAdjustment(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/adjustment/approve`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public ignoreAdjustment(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/adjustment/ignore`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public deleteOtherAndRecalculateAdjustment(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/adjustment/remove-other`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public assignOtherInAdjustment(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/adjustment/assign-other`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public assignUnknownTracking(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/unknown-tracking/assign`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }

  public ignoreUnknownTracking(params) {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/shipments/unknown-tracking/ignore`, params).pipe(
      map((res: any) => res),
      catchError((error: any): any =>
        throwError(error || ErrorMessages.UNEXPECTED)
      )
    );
  }
}
