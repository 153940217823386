import { Component, Input, OnInit } from '@angular/core';
import {
  Company,
  CompanyInvoice,
} from '@app/pages/authenticated/pages/users/users.model';

@Component({
  selector: 'app-invoice-meta',
  templateUrl: './invoice-meta.component.html',
})
export class InvoiceMetaComponent implements OnInit {
  @Input() company: Company;
  @Input() invoice: CompanyInvoice;

  public loading: boolean = true;

  constructor() {}

  ngOnInit() {
    this.loading = false;
  }
}
