<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Admin Charge Details</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>

<div class='row mt--md'>
  <div class='column--12'>Shipment <span notranslate>{{ shipment.orderId }}</span></div>
</div>

<div class='row mt--md'>
  <div class='column--12'>
    <pre class='text--sm' notranslate>{{ adminChargeDetailsStringified }}</pre>
  </div>
</div>

<div class='row mt--md'>
  <div class='column--12 align-center'>
    <button mat-flat-button (click)='dismissDrawer()'>Close</button>
  </div>
</div>
