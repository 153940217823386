<ng-container *ngIf='!loading && provider, else isLoading'>
  <h3>{{ provider.provider }}</h3>
  <div class='row'>
    <div class='column--12'>
      <app-courier-settings
        [providerSettingsForm]="providerSettingsForm"
        [provider]="provider"
        [canEditStatus]="canEditStatus"
      ></app-courier-settings>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--6'>
      <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
    </div>
    <div class='column--6 align-right'>
      <button mat-flat-button color='primary' (click)='updateSettings()' [disabled]='!isValid()'>Save</button>
    </div>
  </div>
</ng-container>

<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
