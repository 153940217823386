import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdminUser,
  UserService,
} from '@app/shared/services/user.service';
import { first } from 'rxjs';
import { NavItems } from '@app/shared/constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  public title = 'admin-portal';
  public user: any;
  public loading: boolean = true;
  constructor(private userService: UserService, public router: Router) {}

  ngOnInit() {
    this.loading = true;

    // Re-auth the currently logged-in user in each page
    this.userService
      .getAuthenticatedUser()
      .pipe(first())
      .subscribe((adminUser: AdminUser) => {
        if (adminUser) {
          this.user = adminUser;

          const navItems = NavItems.filter((item) => {
            return this.userService.hasAuthScope(this.user, item.scope);
          });
          if (!navItems[0].routerLink.includes('home')) {
            this.router.navigate([navItems[0].routerLink]);
          } else {
            this.loading = false;
          }
        } else {
          this.router.navigate(['login']);
        }
      });
  }
}
